import * as React from "react";
import { Job } from "../../generated/nest-graphql";
import { List, Paper, CircularProgress } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { BackButton } from "../Buttons/BackButton";
import { JobHistoryListItem } from "../ListItems/JobHistoryListItem";

export const JobList: React.FC<{ jobs: Job[]; loading: boolean }> = ({ jobs, loading }) => {
  return (
    <Paper className={"p-6"}>
      <Typography variant="h5">Jobs</Typography>
      <Paper className="mt-2 mb-3" elevation={3} square>
        <List dense>
          {loading ? (
            <div className="flex content-center justify-center p-4">
              <CircularProgress />
            </div>
          ) : jobs.length === 0 ? (
            <EmptyJobList />
          ) : (
            jobs.map((job, index) => <JobHistoryListItem key={index} job={job} divider={index < jobs.length - 1} />)
          )}
        </List>
      </Paper>
      <BackButton />
    </Paper>
  );
};

const EmptyJobList = () => (
  <div className="flex content-center justify-center p-4">
    <Typography variant="body2">No Previous Jobs</Typography>
  </div>
);
