import * as React from "react";
import { useState } from "react";
import { Typography } from "@material-ui/core";
import { useField } from "formik";
import { handleNoDecimal, mapIndexed } from "../../../lib/functions";
import { Item } from "../../../generated/nest-graphql";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import { ItemFieldListItem } from "./ItemFieldListItem";
import { UpdateItemDetailDialog } from "../../Items/UpdateItemDetailDialog";
import { AddNewItemDialog } from "../../Items/AddNewItemDialog";
import { AreYouSureDialog } from "../../Items/AreYouSureDialog";
import { useToggle } from "../../../hooks/useToggle";
import { append, prop, remove } from "ramda";
import { ItemFormValues } from "../ItemForm";

export const ItemsField: React.FC<{
  name: string;
  parentSubmit: () => Promise<void>;
  setFieldValue: any;
  editable: boolean;
  additionalPartsInfoRenderer?: (item: Item) => React.ReactNode;
}> = ({ name, setFieldValue, editable, parentSubmit, additionalPartsInfoRenderer }) => {
  const [itemForDialog, setItemForDialog] = useState(null);
  const [open, , toggleDialog] = useToggle();
  const [addDialogOpen, , toggleAddDialog] = useToggle();
  const [deleteDialogOpen, , toggleDeleteDialog] = useToggle();
  const [field] = useField(name);
  const items = field.value;
  const selectItem = (idx) => {
    setItemForDialog(idx);
    toggleDialog();
  };
  const itemToEdit = items[itemForDialog];
  const removeItem = async () => {
    const newResult = remove(itemForDialog, 1, items);
    setFieldValue("items", newResult);
    await parentSubmit();
  };
  const addItemSubmit = async (itemFormValues) => {
    const oldItems = items;
    const { product, name, description, isInEstimate, partNumber, partsCost, laborCost, amount } = itemFormValues;
    const cleanedItemFormValues: ItemFormValues = {
      amount: handleNoDecimal(amount),
      laborCost: handleNoDecimal(laborCost),
      partsCost: handleNoDecimal(partsCost),
      partNumber,
      isInEstimate,
      description,
      name,
      product,
    };
    const result = append(cleanedItemFormValues, oldItems);
    setFieldValue("items", result, false);
    await parentSubmit();
    toggleAddDialog();
  };
  return (
    <>
      <Typography>Items</Typography>
      <List>
        {mapIndexed((item: Item, index) => {
          return (
            <ItemFieldListItem
              additionalPartsInfoRenderer={additionalPartsInfoRenderer}
              editable={editable}
              removeItem={() => {
                setItemForDialog(index);
                toggleDeleteDialog();
              }}
              onSelectItem={() => selectItem(index)}
              item={item}
              key={index}
            />
          );
        }, items)}
        {editable && (
          <ListItem>
            <Button variant={"contained"} color={"primary"} fullWidth={true} onClick={toggleAddDialog}>
              + Add Item
            </Button>
          </ListItem>
        )}
      </List>
      {editable && (
        <>
          <UpdateItemDetailDialog
            parentSubmit={parentSubmit}
            open={open}
            onClose={toggleDialog}
            itemIndex={itemForDialog}
            items={items}
            setFieldValue={setFieldValue}
          />
          <AddNewItemDialog open={addDialogOpen} onClose={toggleAddDialog} onSubmit={addItemSubmit} />
          <AreYouSureDialog
            title={`Are you sure you want to remove the ${prop("name", itemToEdit)}`}
            onNo={toggleDeleteDialog}
            onYes={async () => {
              await toggleDeleteDialog();
              await removeItem();
            }}
            open={deleteDialogOpen}
            onClose={toggleDeleteDialog}
          />
        </>
      )}
    </>
  );
};
