import * as React from "react";
import { ListItem, ListItemText } from "@material-ui/core";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

export const LinkListItem: React.FC<{
  to: string;
  title: string;
  secondary?: string;
  divider?: boolean;
}> = ({ to, title, secondary, divider, children }) => {
  return (
    <Link to={to}>
      <ListItem divider={divider} button>
        <ListItemText
          primary={
            <Typography className={"block"} component={"div"}>
              {title}
            </Typography>
          }
          secondary={secondary}
        />
        {children}
      </ListItem>
    </Link>
  );
};
