import * as React from "react";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { path } from "ramda";
import ListItem from "@material-ui/core/ListItem";
import { Appointment } from "../../generated/nest-graphql";
import { format } from "date-fns";
import { DATE_FORMAT, TIME_FORMAT } from "../../lib/constants";

export const getTimeFromDate = (date) => {
  return format(date, TIME_FORMAT);
};

export const AppointmentListItem: React.FC<{ appointment: Appointment }> = ({ appointment }) => {
  const { startDate, endDate } = appointment;
  const startDateDate = new Date(startDate);
  const endDateDate = new Date(endDate);
  return (
    <ListItem divider>
      <ListItemText
        primary={`${format(startDateDate, DATE_FORMAT)}`}
        secondary={
          <>
            {getTimeFromDate(startDateDate)} - {getTimeFromDate(endDateDate)}
            <Typography className="block" component={"span"} color={"primary"}>
              Assignee: {path(["technician", "firstName"], appointment)} {path(["technician", "lastName"], appointment)}
            </Typography>
          </>
        }
      />
    </ListItem>
  );
};
