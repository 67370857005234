import React from "react";
import { path } from "ramda";
import { useField } from "formik";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { default as CoreSelect } from "@material-ui/core/Select";

export const Select: React.FC<{
  name: string;
  value?: string;
  required?: boolean;
  options: string[];
  label: string;
  fullWidth?: boolean;
  defaultValue?: string;
  postOnChange?: (val: any) => void;
}> = ({ label, options, required, name, fullWidth, postOnChange, ...props }) => {
  const [field, , helper] = useField(name);

  return (
    <FormControl variant="outlined" fullWidth={fullWidth}>
      <InputLabel>{label}</InputLabel>
      <CoreSelect
        label={label}
        required={required}
        {...field}
        {...props}
        onChange={(event) => {
          // @ts-ignore
          const value = path<string>(["target", "value"], event);
          helper.setValue(value);
          postOnChange && postOnChange(value);
        }}
      >
        {options.map((val, i) => (
          <MenuItem key={i} value={val}>
            {val}
          </MenuItem>
        ))}
      </CoreSelect>
    </FormControl>
  );
};
