import * as React from "react";
import { useState } from "react";
import { Field, useField } from "formik";
import { Accordion, AccordionSummary, AccordionDetails, IconButton } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { green, red, yellow, grey } from "@material-ui/core/colors";
import { TextField } from "../Forms/fields/TextField";
import { CheckboxField } from "../Forms/fields/CheckboxField";
import { DateInput } from "../Forms/fields/DateInput";
import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";
import NumberFormat from "react-number-format";
import { always, cond, equals, isNil, T } from "ramda";
import { Select } from "../Forms/fields/Select";
import { NumberFormatCustomProps } from "../Forms/fields/CurrencyInput";

function NumberFormatMileage(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;
  const cleanValRef = React.useRef<any>();
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        cleanValRef.current = values;
      }}
      onChange={(e) => onChange({ target: { name: props.name, value: cleanValRef.current?.value } })}
      decimalScale={0}
      thousandSeparator
      allowNegative={false}
      isNumericString
    />
  );
}

const useStyles = makeStyles({
  accordionSummary: {
    border: "2px solid rgb(212, 212, 212)",
    borderRadius: 20,
    "& .MuiAccordionSummary-content": {
      margin: "0px",
    },
    "& .MuiListItemText-root": {
      display: "flex",
      alignItems: "center",
    },
    display: "flex",
    justifyContent: "center",
  },
  accordion: {
    borderRadius: 20,
    "& .MuiAccordionDetails-root": {
      padding: "0px",
    },
    marginBottom: "15px",
    "&.MuiAccordion-root:before": {
      display: "none",
    },
  },
  accordionDetails: {
    "& .MuiAccordionDetails-root": {
      padding: "0px",
    },
  },
  dateInput: {
    "& .MuiFormControl-root": {
      flexGrow: 1,
    },
  },
  checkboxLabelSize: {
    fontSize: 12,
  },
  checkboxLabelMargin: {
    marginRight: 0,
    "& span": {
      paddingBottom: "2px",
    },
  },
  goodCondition: {
    backgroundColor: green[500],
    "&:focus": {
      backgroundColor: green[500],
    },
  },
  okayCondition: {
    backgroundColor: yellow[500],
    "&:focus": {
      backgroundColor: yellow[500],
    },
  },
  badCondition: {
    backgroundColor: red[500],
    "&:focus": {
      backgroundColor: red[500],
    },
  },
  na: {
    backgroundColor: grey[500],
    "&:focus": {
      backgroundColor: grey[500],
    },
  },
});

const getColor = (classes, estimatedNextOilChangeTimeline: string) => {
  return cond([
    [equals("Now / Past Due"), always(classes.badCondition)],
    [equals("Unknown"), always(classes.na)],
    [equals("1 month"), always(classes.okayCondition)],
    [equals("2 months"), always(classes.okayCondition)],
    [T, always(classes.goodCondition)],
  ])(estimatedNextOilChangeTimeline);
};

export const OilLifeDropdownListItem: React.FC<{
  name: string;
  label: string;
}> = ({ name, label }) => {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();
  const [field, meta, helper] = useField(name);
  const { estimatedNextOilChangeTimeline, nextOilChangeMileage, nextOilChangeDate } = field.value;
  return (
    <Accordion className={`${classes.accordion}`} expanded={expanded}>
      <AccordionSummary
        className={`${classes.accordionSummary}  ${!meta.error && getColor(classes, estimatedNextOilChangeTimeline)}`}
        onClick={() => setExpanded(!expanded)}
      >
        <ListItemText primary={label} />
        <div>
          <IconButton>{expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="flex flex-col flex-grow">
          <div className="m-2 mt-4">
            <Field name={`${name}.estimatedNextOilChangeTimeline`}>
              {({ field }) => (
                <Select
                  {...field}
                  fullWidth={true}
                  options={[
                    "Now / Past Due",
                    "1 month",
                    "2 months",
                    "3 months",
                    "4 months",
                    "5 months",
                    "6 months",
                    "Unknown",
                  ]}
                  label={"Est. Next Oil Change Timeline"}
                  errorMessage={isNil(estimatedNextOilChangeTimeline) ? "No timeline selected." : ""}
                />
              )}
            </Field>
          </div>
          <div className="flex justify-between items-center m-2">
            <TextField
              name={`${name}.nextOilChangeMileage.mileage`}
              label="Oil Sticker Mileage"
              disabled={nextOilChangeMileage.unavailable}
              errorText=""
              inputMode="numeric"
              InputProps={{
                inputComponent: NumberFormatMileage as any,
              }}
            />
            <CheckboxField
              name={`${name}.nextOilChangeMileage.unavailable`}
              label="Unavailable"
              labelPlacement="bottom"
              classNames="pb-2"
              labelClasses={{
                label: classes.checkboxLabelSize,
                labelPlacementBottom: classes.checkboxLabelMargin,
              }}
              postOnChange={(val) => {
                if (val) {
                  const resetField = { ...field.value };
                  resetField.nextOilChangeMileage.mileage = null;
                  resetField.nextOilChangeMileage.unavailable = val;
                  helper.setValue(resetField);
                }
              }}
            />
          </div>
          <div className={`flex justify-between items-center m-2 ${classes.dateInput}`}>
            <DateInput
              name={`${name}.nextOilChangeDate.date`}
              label="Oil Sticker Date"
              disabled={nextOilChangeDate.unavailable}
            />
            <CheckboxField
              name={`${name}.nextOilChangeDate.unavailable`}
              label="Unavailable"
              labelPlacement="bottom"
              classNames="pb-0.5"
              labelClasses={{
                label: classes.checkboxLabelSize,
                labelPlacementBottom: classes.checkboxLabelMargin,
              }}
              postOnChange={(val) => {
                if (val) {
                  const resetField = { ...field.value };
                  resetField.nextOilChangeDate.date = null;
                  resetField.nextOilChangeDate.unavailable = val;
                  helper.setValue(resetField);
                }
              }}
            />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
