import { Button, createStyles, makeStyles } from "@material-ui/core";
import React from "react";
import { BottomDrawer } from "../BottomDrawer";
import { Chat, CreditCard } from "@material-ui/icons";
import { useToggle } from "../../hooks/useToggle";
import { AddCreditCardForm } from "../Forms/AddCreditCardForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import * as Sentry from "@sentry/react";
import { Contact } from "../../generated/nest-graphql";
import { prop } from "ramda";
import { TextCustomerCCLinkDialog } from "./TextCustomerCCLinkDialog";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      justifyContent: "flex-start",
    },
  })
);

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`).catch((reason) => {
  Sentry.captureException(reason);
  return reason;
});

export const AddPaymentOptionsDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  contact: Contact;
  setPaymentMethod: (value: string) => void;
}> = ({ open, onClose, contact, setPaymentMethod }) => {
  const contactId = prop("id", contact);
  const [addCreditCardStatus, , toggleAddCreditCard] = useToggle();
  const [textCustomerCCLinkStatus, , textCustomerCCLinkToggle] = useToggle();
  const classes = useStyles();
  return (
    <BottomDrawer open={open} onClose={onClose}>
      <div className="grid gap-2">
        <Button
          onClick={textCustomerCCLinkToggle}
          className={classes.root}
          size={"large"}
          fullWidth
          color={"primary"}
          variant={"contained"}
        >
          <Chat className={"mr-2"} /> Text CC Link To Customer
        </Button>
        <Button
          onClick={toggleAddCreditCard}
          className={classes.root}
          size={"large"}
          fullWidth
          color={"primary"}
          variant={"contained"}
        >
          <CreditCard className={"mr-2"} /> Add Card Manually
        </Button>
      </div>
      <Elements stripe={stripePromise}>
        <AddCreditCardForm
          open={addCreditCardStatus}
          toggle={() => {
            toggleAddCreditCard();
            onClose();
          }}
          contactId={contactId}
          setPaymentMethod={setPaymentMethod}
        />
      </Elements>
      <TextCustomerCCLinkDialog
        contactId={contactId}
        firstName={prop("firstName", contact)}
        lastName={prop("lastName", contact)}
        phoneNumber={prop("phoneNumber", contact)}
        open={textCustomerCCLinkStatus}
        onClose={() => {
          textCustomerCCLinkToggle();
          onClose();
        }}
      />
    </BottomDrawer>
  );
};
