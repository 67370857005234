import React from "react";
import { default as CoreTextField } from "@material-ui/core/TextField";
import { useField } from "formik";
import { defaultTo } from "ramda";

export const TextField: React.FC<{
  name: string;
  label?: string;
  required?: boolean;
  multiline?: boolean;
  rows?: number;
  readOnly?: boolean;
  InputProps?: any;
  variant?: "outlined" | "filled" | "standard";
  inputMode?: "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search";
  disabled?: boolean;
  errorText?: string;
  textAlign?: "start" | "end" | "left" | "right" | "center" | "justify" | "match-parent";
}> = ({
  name,
  label,
  required,
  multiline,
  rows,
  inputMode = "text",
  variant = "outlined",
  readOnly = false,
  InputProps,
  disabled = false,
  errorText = "This is a required field.",
  textAlign = "left",
  ...props
}) => {
  const [field, meta] = useField(name);
  const { error } = meta;
  const { value, ...rest } = field;
  return (
    <CoreTextField
      fullWidth
      variant={variant}
      multiline={multiline}
      rows={rows}
      label={label}
      value={value ? value : ""}
      error={!!error}
      helperText={defaultTo("", !!error && errorText)}
      inputProps={{ inputMode: inputMode, readOnly: readOnly, style: { textAlign: textAlign } }}
      disabled={disabled}
      InputProps={InputProps}
      required={required}
      {...rest}
      {...props}
    />
  );
};
