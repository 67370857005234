import gql from "graphql-tag";
import { jobDetailsFields } from "../queries/getJob";
import { invoiceDetailsFields } from "../queries/getInvoice";

export const CLOSE_ZERO_DOLLAR_INVOICE = gql`mutation closeZeroDollarInvoice($closeZeroDollarInvoiceInput: CloseZeroDollarInvoiceInput!) {
    closeZeroDollarInvoice(closeZeroDollarInvoiceInput: $closeZeroDollarInvoiceInput) {
        job {
            ${jobDetailsFields}
        }
        invoice {
            ${invoiceDetailsFields}
        }
    }
}`;
