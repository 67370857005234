import { Field } from "formik";
import React from "react";
import classNames from "classnames";
import { useDispatch } from "../../contexts/snackbar-context";
import { EjiDiscount } from "../../generated/nest-graphql";
import { useToggle } from "../../hooks/useToggle";
import { EJI_DISCOUNT_TYPE_PERCENT, JOB, POTENTIAL_INVOICE } from "../../lib/constants";
import DiscountModal from "./DiscountModal";
import { showSuccessAlert } from "../../actions";
import { NuModal } from "../NuModal";
import {
  formValuesToEJIDiscountInputs,
  formValuesToPossibleEJIServiceInputs,
  formValueToEJIPricingConfigInput,
  ejiPromoCodesToFormValuesSpec,
} from "../../specs/ejiServicesSpec";

export const ExistingDiscount: React.FC<{
  discount: EjiDiscount;
  discountIdx: number;
  calculatePossibleEJIPriceInfo: any;
  stage: string;
}> = ({ discount, discountIdx, calculatePossibleEJIPriceInfo, stage }) => {
  const [discountModalOpen, , toggleDiscountModal] = useToggle();
  const dispatch = useDispatch();
  const spacingClass = classNames("text-xs", "flex", "flex-row", "justify-between", "my-1");
  const textClass = classNames("flex", "flex-row");
  return (
    <Field name={`discounts[${discountIdx}]`}>
      {({ field: { value }, form: { values } }) => {
        const onSubmit = (discount) => {
          const discountEdited = [...(values.discounts ?? [])];
          discountEdited.splice(discountIdx, 1, discount);
          calculatePossibleEJIPriceInfo({
            variables: {
              calculatePossibleEJIPriceInfoInput: {
                services: formValuesToPossibleEJIServiceInputs(
                  stage === POTENTIAL_INVOICE
                    ? values.services.filter((service) => service?.inInvoice)
                    : values.services
                ),
                discounts: formValuesToEJIDiscountInputs(discountEdited),
                promoCodes: ejiPromoCodesToFormValuesSpec(values.promoCodes),
                pricingConfig: formValueToEJIPricingConfigInput(values.priceInfo?.pricingConfig),
                marketName: values.market,
                taxable: values.taxable,
                calculateAllServices: stage !== JOB,
              },
            },
          });
          toggleDiscountModal();
          showSuccessAlert(dispatch, "Discount edited successfully");
        };
        return (
          <>
            <div className={spacingClass}>
              <div className={textClass}>
                <>
                  Discount {discount?.type === EJI_DISCOUNT_TYPE_PERCENT && `(${discount.amount}%)`}
                  <div onClick={toggleDiscountModal} className="underline text-blue-600 cursor-pointer mx-2">
                    Edit
                  </div>
                </>

                <div
                  onClick={() => {
                    let discountRemoved = [...(values.discounts ?? [])];
                    discountRemoved.splice(discountIdx, 1);
                    calculatePossibleEJIPriceInfo({
                      variables: {
                        calculatePossibleEJIPriceInfoInput: {
                          services: formValuesToPossibleEJIServiceInputs(
                            stage === POTENTIAL_INVOICE
                              ? values.services.filter((service) => service?.inInvoice)
                              : values.services
                          ),
                          promoCodes: ejiPromoCodesToFormValuesSpec(values.promoCodes),
                          discounts: formValuesToEJIDiscountInputs(discountRemoved),
                          pricingConfig: formValueToEJIPricingConfigInput(values.priceInfo?.pricingConfig),
                          marketName: values.market,
                          taxable: values.taxable,
                          calculateAllServices: stage !== JOB,
                        },
                      },
                    });
                  }}
                  className="underline text-blue-600 cursor-pointer"
                >
                  Remove
                </div>
              </div>
              <div className="flex flex-row flex-end">
                <div>- ${discount?.total ?? "0"}</div>
              </div>
            </div>
            <div className="text-xs flex ml-8">{discount?.reason ?? ""}</div>
            <NuModal isOpen={discountModalOpen} title="Edit Discount">
              <DiscountModal
                existingDiscount={discount}
                discountIdx={discountIdx}
                closeModal={toggleDiscountModal}
                onSubmit={onSubmit}
              />
            </NuModal>
          </>
        );
      }}
    </Field>
  );
};

export const ExistingPromo: React.FC<{
  discount: EjiDiscount;
  discountIdx: number;
  calculatePossibleEJIPriceInfo: any;
  stage: string;
  promoCode: any;
}> = ({ discount, discountIdx, calculatePossibleEJIPriceInfo, stage, promoCode }) => {
  console.log(discount);
  const spacingClass = classNames("text-xs", "flex", "flex-row", "justify-between", "my-1");
  const textClass = classNames("flex", "flex-row");
  return (
    <Field name={`promoCodes[${discountIdx}]`}>
      {({ field: { value }, form: { values } }) => {
        return (
          <>
            <div className={spacingClass}>
              <div className={textClass}>
                <>
                  {promoCode.code.toUpperCase()}{" "}
                  {discount?.type === EJI_DISCOUNT_TYPE_PERCENT && `(-${discount.amount}%)`}
                </>

                <div
                  onClick={() => {
                    let promoRemoved = [...(values.promoCodes ?? [])];
                    promoRemoved.splice(discountIdx, 1);
                    calculatePossibleEJIPriceInfo({
                      variables: {
                        calculatePossibleEJIPriceInfoInput: {
                          services: formValuesToPossibleEJIServiceInputs(
                            stage === POTENTIAL_INVOICE
                              ? values.services.filter((service) => service?.inInvoice)
                              : values.services
                          ),
                          promoCodes: ejiPromoCodesToFormValuesSpec(promoRemoved),
                          discounts: formValuesToEJIDiscountInputs(values.discounts),
                          pricingConfig: formValueToEJIPricingConfigInput(values.priceInfo?.pricingConfig),
                          marketName: values.market,
                          taxable: values.taxable,
                          calculateAllServices: stage !== JOB,
                        },
                      },
                    });
                  }}
                  className="underline text-blue-600 cursor-pointer"
                >
                  Remove
                </div>
              </div>
              <div className="flex flex-row flex-end">
                <div>- ${discount?.total ?? "0"}</div>
              </div>
            </div>
            <div className="text-xs flex ml-8">{discount?.reason ?? ""}</div>
          </>
        );
      }}
    </Field>
  );
};

export const AddDiscount: React.FC<{ calculatePossibleEJIPriceInfo: any; stage: string }> = ({
  calculatePossibleEJIPriceInfo,
  stage,
}) => {
  const [discountModalOpen, , toggleDiscountModal] = useToggle();
  const spacingClass = classNames("flex", "flex-row", "justify-between", "my-1");
  const textClass = classNames("text-xs", "flex", "flex-row");
  const dispatch = useDispatch();
  return (
    <div className={spacingClass}>
      <div className={textClass}>
        Discount (
        <div onClick={toggleDiscountModal} className="underline text-blue-600 cursor-pointer">
          Add
        </div>
        )
      </div>
      <Field name="discounts">
        {({ form: { values } }) => {
          const onSubmit = (discount) => {
            const discountAdded = [...(values.discounts ?? [])];
            discountAdded.push(discount);
            calculatePossibleEJIPriceInfo({
              variables: {
                calculatePossibleEJIPriceInfoInput: {
                  services: formValuesToPossibleEJIServiceInputs(
                    stage === POTENTIAL_INVOICE
                      ? values.services.filter((service) => service?.inInvoice)
                      : values.services
                  ),
                  discounts: formValuesToEJIDiscountInputs(discountAdded),
                  pricingConfig: formValueToEJIPricingConfigInput(values.priceInfo?.pricingConfig),
                  promoCodes: ejiPromoCodesToFormValuesSpec(values.promoCodes),
                  marketName: values.market,
                  taxable: values.taxable,
                  calculateAllServices: stage !== JOB,
                },
              },
            });
            toggleDiscountModal();
            showSuccessAlert(dispatch, "Discount added successfully");
          };
          return (
            <NuModal isOpen={discountModalOpen} title="Add Discount">
              <DiscountModal closeModal={toggleDiscountModal} onSubmit={onSubmit} />
            </NuModal>
          );
        }}
      </Field>
    </div>
  );
};
