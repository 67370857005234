import * as React from "react";
import Button from "@material-ui/core/Button";
import { CircularProgress, createStyles, makeStyles, Theme } from "@material-ui/core";
import { ReactNode } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    disabledButton: {
      opacity: 0.7,
    },
  })
);

export const SubmitButton: React.FC<{
  isSubmitting: boolean;
  isValid: boolean;
  handleSubmit?: any;
  label?: ReactNode;
}> = ({ isSubmitting, isValid, handleSubmit, label = "Submit", ...props }) => {
  const classes = useStyles();
  return (
    <Button
      size={"large"}
      type={"submit"}
      variant={"contained"}
      color={"primary"}
      disabled={isSubmitting || !isValid}
      fullWidth={true}
      onClick={handleSubmit}
      classes={{ disabled: classes.disabledButton }}
      {...props}
    >
      {isSubmitting ? <CircularProgress size={24} /> : label}
    </Button>
  );
};
