import React, { useRef } from "react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import NumberFormat from "react-number-format";
import { TextField } from "./TextField";

export interface NumberFormatCustomProps {
  // @ts-ignore
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;
  const cleanValRef = useRef<any>();
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        cleanValRef.current = values;
      }}
      onChange={(e) => onChange({ target: { name: props.name, value: cleanValRef.current?.value } })}
      decimalScale={2}
      fixedDecimalScale
      isNumericString
      allowNegative={false}
      isAllowed={({ value }) => value === "" || Number(value) !== 0}
    />
  );
}

const CurrencyInput: React.FC<{
  name: string;
  label: string;
  required?: boolean;
  readOnly?: boolean;
  variant?: "outlined" | "filled" | "standard";
}> = ({ name, label, required = false, readOnly = false, variant = "outlined" }) => {
  return (
    <TextField
      variant={variant}
      name={name}
      label={label}
      required={required}
      inputMode="decimal"
      InputProps={{
        inputComponent: NumberFormatCustom as any,
      }}
      readOnly={readOnly}
    />
  );
};

export default CurrencyInput;

const defaultMaskOptions = {
  suffix: "",
  allowDecimal: true,
  prefix: "",
  decimalSymbol: ".",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  includeThousandsSeparator: false,
  allowNegative: true,
  allowLeadingZeroes: false,
  inputMode: "numeric",
};

export const MaterialMaskedInput = (props) => {
  const { inputRef, ...rest } = props;
  const currencyMask = createNumberMask(defaultMaskOptions);
  return (
    <MaskedInput
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={currencyMask}
      {...rest}
    />
  );
};
