import * as React from "react";
import { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { getMakesByYear, getModelsByYearAndMake, getYears } from "../../lib/quoteFormUtil";
import { Select } from "./fields/Select";
import { TextField } from "./fields/TextField";
import { AutoCompleteSelectField } from "./fields/AutoCompleteSelectField";

const padLifeOptions = [
  "1mm",
  "1.5mm",
  "2mm",
  "2.5mm",
  "3mm",
  "3.5mm",
  "4mm",
  "4.5mm",
  "5mm",
  "5.5mm",
  "6mm",
  "6.5mm",
  "7mm",
  "7.5mm",
  "8mm",
];

export const VehicleInfoFormSection: React.FC<{
  parentSetFieldValue: any;
  year: any;
  make: any;
}> = ({ parentSetFieldValue, year, make }) => {
  const years = getYears();
  const [modelOptions, setModelOptions] = useState([""]);
  const [makeOptions, setMakeOptions] = useState([""]);

  useEffect(() => {
    if (year) {
      setMakeOptions(getMakesByYear(year));
    }
    if (year && make) {
      setModelOptions(getModelsByYearAndMake(year, make));
    }
  }, [make, year]);

  return (
    <Grid container item spacing={2}>
      <Grid item xs={6}>
        <AutoCompleteSelectField
          name={"year"}
          options={years}
          label={"Year"}
          postOnChange={(year) => {
            parentSetFieldValue("make", "");
            parentSetFieldValue("model", "");
            if (year) {
              setMakeOptions(getMakesByYear(year));
            }
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <AutoCompleteSelectField
          name={"make"}
          options={makeOptions}
          label={"Make"}
          postOnChange={(vehicleMake) => {
            parentSetFieldValue("model", "");
            if (vehicleMake && year) {
              setModelOptions(getModelsByYearAndMake(year, vehicleMake));
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <AutoCompleteSelectField name={"model"} options={modelOptions} label={"Model"} />
      </Grid>
      <Grid item xs={6}>
        <TextField name={"licensePlate"} label="License Plate" />
      </Grid>
      <Grid item xs={6}>
        <TextField name={"vin"} label="Vin" />
      </Grid>
      <Grid item xs={12}>
        <TextField name={"odometer"} label="Odometer" inputMode={"numeric"} />
      </Grid>
      <Grid item xs={12}>
        <TextField name={"extraInfo"} label="Extra Info" multiline={true} rows={3} />
      </Grid>
      <Grid item xs={12}>
        <TextField name={"symptoms"} label="Symptoms" multiline={true} rows={3} />
      </Grid>
      <Grid item xs={6}>
        <Select name={"frontPadLife"} options={padLifeOptions} fullWidth={true} label="Front Pad Life" />
      </Grid>
      <Grid item xs={6}>
        <Select name={"rearPadLife"} options={padLifeOptions} fullWidth={true} label="Rear Pad Life" />
      </Grid>
    </Grid>
  );
};
