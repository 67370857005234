import * as React from "react";
import { FileForm, FileFormValues } from "../Forms/FileForm";

export const UploadFileSection: React.FC<{
  contactId: string;
  initialValues: FileFormValues;
  onSubmit: any;
}> = ({ contactId, initialValues, onSubmit }) => {
  return (
    <div>
      <FileForm contactId={contactId} initialValues={initialValues} onSubmit={onSubmit} />
    </div>
  );
};
