import gql from "graphql-tag";

export const CREATE_CONFIRMED_PAYMENT_INTENT = gql`
  mutation createConfirmedPaymentIntent($createPaymentInput: CreatePaymentInput!, $idempotencyKey: String) {
    createConfirmedPaymentIntent(createPaymentInput: $createPaymentInput, idempotencyKey: $idempotencyKey) {
      id
      client_secret
      status
    }
  }
`;
