import { map, prop, pathOr, applySpec } from "ramda";
import {
  Invoice,
  Item,
  ItemInput,
  Job,
  PartsOrderingIssuesSurveyInput,
  UpdateJobInput,
} from "../../generated/nest-graphql";
import {
  formValuesToEJIDiscountInputs,
  formValuesToEJIPromoCodeInputs,
  formValuesToEJIServiceInputsSpec,
} from "../../specs/ejiServicesSpec";
import { JobDetailsFormValues } from "../Forms/JobDetailsForm";
import { PreJobCheckListFormValues } from "./PreJobCheckListDialog";
import { emptyCheckList, oilLifeSpec, padThicknessSpec, tireTreadSpec } from "../Invoices/invoiceDetailsSpec";
import { checkListItemDefault } from "../../contexts/inspection-context";
import { PartsOrderingSurveyFormValues } from "../Forms/Surveys/PartsOrderingSurveyForm";

export const itemsSpec = (items: readonly Item[]) =>
  map((item: Item): ItemInput => {
    const { product, partsCost, partsStore, amount, description, laborCost, name, isInEstimate, partNumber } = item;
    return {
      product: product,
      partsCost,
      partNumber,
      partsStore: partsStore?.id,
      isInEstimate,
      name,
      laborCost,
      description,
      amount,
    };
  }, items);

export const preJobCheckListItemSpec = (checkListItem: any) => {
  const { partCondition, additionalInfo } = checkListItem;
  return {
    partCondition,
    additionalInfo,
  };
};

export const partsOrderingIssuesSurveysSpec = (partsOrderingIssuesSurveys: PartsOrderingSurveyFormValues[]) =>
  partsOrderingIssuesSurveys?.map(
    applySpec<PartsOrderingIssuesSurveyInput>({
      partsCorrect: prop("partsCorrect"),
      partsStores: prop("partsStores"),
      partsIssues: prop("partsIssues"),
      additionalDetails: prop("additionalDetails"),
      completed: prop("completed"),
      submittedBy: prop("submittedBy"),
    })
  );

export const updateJobDetailsSpec = (formData: JobDetailsFormValues): UpdateJobInput => {
  const {
    contact,
    partsLocation,
    partsOrdered,
    estimate,
    partsOrderNumber,
    extraInfo,
    symptoms,
    frontPadLife,
    licensePlate,
    make,
    model,
    odometer,
    rearPadLife,
    vin,
    market,
    partsNotes,
    year,
    appointmentId,
    description,
    items,
    jobName,
    privateNotes,
    serviceLocation,
    serviceLocationNotes,
    type,
    serviceCallReason,
    serviceCallReasonExtraInfo,
    status,
    frontBrakeSymptoms,
    rearBrakeSymptoms,
    additionalNotes,
    rating,
    doneBetter,
    additionalComments,
    partsOrderingIssuesSurveys,
    partsOrderingIssuesSurvey,
    preJobCheckList,
    services,
    discounts,
    promoCodes,
  } = formData;
  const {
    frontPads,
    frontPadsV2,
    frontRotors,
    rearPads,
    rearPadsV2,
    rearRotors,
    brakeFluid,
    brakeShoes,
    brakeDrums,
    calipers,
    shocksStruts,
    controlArms,
    wheelHubs,
    studs,
    lugnuts,
    sparkPlugs,
    carBattery,
    alternator,
    engineAirFilter,
    cabinAirFilter,
    otherBrakeParts,
    dashboardLights,
    oilLife,
    tireTread,
  } = preJobCheckList ?? emptyCheckList;
  return {
    contact: prop("id", contact),
    feedback: rating
      ? {
          // @ts-ignore
          rating: parseInt(rating),
          doneBetter,
          additionalComments,
        }
      : null,
    partsOrderingIssuesSurvey,
    partsOrderingIssuesSurveys: partsOrderingIssuesSurveysSpec(partsOrderingIssuesSurveys),
    description,
    items: items && itemsSpec(items),
    jobName,
    estimate,
    market,
    appointment: appointmentId,
    preJobCheckList: {
      frontPads: frontPads && preJobCheckListItemSpec(frontPads),
      frontPadsV2: frontPadsV2 && padThicknessSpec(frontPadsV2),
      frontRotors: frontRotors && preJobCheckListItemSpec(frontRotors),
      rearPads: rearPads && preJobCheckListItemSpec(rearPads),
      rearPadsV2: rearPadsV2 && padThicknessSpec(rearPadsV2),
      rearRotors: rearRotors && preJobCheckListItemSpec(rearRotors),
      brakeFluid: brakeFluid && preJobCheckListItemSpec(brakeFluid),
      brakeShoes: brakeShoes && preJobCheckListItemSpec(brakeShoes),
      brakeDrums: brakeDrums && preJobCheckListItemSpec(brakeDrums),
      calipers: calipers && preJobCheckListItemSpec(calipers),
      shocksStruts: shocksStruts && preJobCheckListItemSpec(shocksStruts),
      controlArms: controlArms && preJobCheckListItemSpec(controlArms),
      wheelHubs: wheelHubs && preJobCheckListItemSpec(wheelHubs),
      studs: studs && preJobCheckListItemSpec(studs),
      lugnuts: lugnuts && preJobCheckListItemSpec(lugnuts),
      sparkPlugs: sparkPlugs && preJobCheckListItemSpec(sparkPlugs),
      carBattery: carBattery && preJobCheckListItemSpec(carBattery),
      alternator: alternator && preJobCheckListItemSpec(alternator),
      engineAirFilter: engineAirFilter && preJobCheckListItemSpec(engineAirFilter),
      cabinAirFilter: cabinAirFilter && preJobCheckListItemSpec(cabinAirFilter),
      otherBrakeParts: otherBrakeParts && preJobCheckListItemSpec(otherBrakeParts),
      dashboardLights: dashboardLights && preJobCheckListItemSpec(dashboardLights),
      oilLife: oilLife && oilLifeSpec(oilLife),
      tireTread: tireTread && tireTreadSpec(tireTread),
    },
    partsInfo: {
      partsLocation,
      partsOrdered,
      partsOrderNumber,
      partsNotes,
    },
    privateNotes,
    serviceLocation,
    serviceLocationNotes,
    status,
    type,
    serviceCallReason,
    serviceCallReasonExtraInfo,
    vehicleInfo: {
      frontPadLife,
      licensePlate,
      make,
      model,
      odometer,
      rearPadLife,
      extraInfo,
      symptoms,
      vin,
      year,
      frontBrakeSymptoms,
      rearBrakeSymptoms,
      additionalNotes,
    },
    services: formValuesToEJIServiceInputsSpec(services),
    discounts: formValuesToEJIDiscountInputs(discounts),
    promoCodes: formValuesToEJIPromoCodeInputs(promoCodes),
  };
};

export const preJobCheckListUpdateJobSpec = (preJobCheckListValues: PreJobCheckListFormValues) => {
  const {
    frontPads,
    frontPadsV2,
    frontRotors,
    rearPads,
    rearPadsV2,
    rearRotors,
    brakeFluid,
    brakeShoes,
    brakeDrums,
    calipers,
    shocksStruts,
    controlArms,
    wheelHubs,
    studs,
    lugnuts,
    sparkPlugs,
    carBattery,
    alternator,
    engineAirFilter,
    cabinAirFilter,
    otherBrakeParts,
    dashboardLights,
    odometer,
    oilLife,
    tireTread,
  } = preJobCheckListValues ?? {};
  return {
    vehicleInfo: {
      odometer,
    },
    preJobCheckList: {
      frontPads: frontPads && preJobCheckListItemSpec(frontPads),
      frontPadsV2: frontPadsV2 && padThicknessSpec(frontPadsV2),
      frontRotors: frontRotors && preJobCheckListItemSpec(frontRotors),
      rearPads: rearPads && preJobCheckListItemSpec(rearPads),
      rearPadsV2: rearPadsV2 && padThicknessSpec(rearPadsV2),
      rearRotors: rearRotors && preJobCheckListItemSpec(rearRotors),
      brakeFluid: brakeFluid && preJobCheckListItemSpec(brakeFluid),
      brakeShoes: brakeShoes && preJobCheckListItemSpec(brakeShoes),
      brakeDrums: brakeDrums && preJobCheckListItemSpec(brakeDrums),
      calipers: calipers && preJobCheckListItemSpec(calipers),
      shocksStruts: shocksStruts && preJobCheckListItemSpec(shocksStruts),
      controlArms: controlArms && preJobCheckListItemSpec(controlArms),
      wheelHubs: wheelHubs && preJobCheckListItemSpec(wheelHubs),
      studs: studs && preJobCheckListItemSpec(studs),
      lugnuts: lugnuts && preJobCheckListItemSpec(lugnuts),
      sparkPlugs: sparkPlugs && preJobCheckListItemSpec(sparkPlugs),
      carBattery: carBattery && preJobCheckListItemSpec(carBattery),
      alternator: alternator && preJobCheckListItemSpec(alternator),
      engineAirFilter: engineAirFilter && preJobCheckListItemSpec(engineAirFilter),
      cabinAirFilter: cabinAirFilter && preJobCheckListItemSpec(cabinAirFilter),
      otherBrakeParts: otherBrakeParts && preJobCheckListItemSpec(otherBrakeParts),
      dashboardLights: dashboardLights && preJobCheckListItemSpec(dashboardLights),
      oilLife: oilLife && oilLifeSpec(oilLife),
      tireTread: tireTread && tireTreadSpec(tireTread),
    },
  };
};

export const preJobCheckListInitialValues = (source: Job | Invoice) => {
  return {
    frontPads: pathOr(checkListItemDefault, ["preJobCheckList", "frontPads"], source),
    frontPadsV2: pathOr(
      {
        padThickness: {
          thickness: null,
          unavailable: null,
        },
        condition: null,
      },
      ["preJobCheckList", "frontPadsV2"],
      source
    ),
    frontRotors: pathOr(checkListItemDefault, ["preJobCheckList", "frontRotors"], source),
    rearPads: pathOr(checkListItemDefault, ["preJobCheckList", "rearPads"], source),
    rearPadsV2: pathOr(
      {
        padThickness: {
          thickness: null,
          unavailable: null,
        },
        condition: null,
      },
      ["preJobCheckList", "rearPadsV2"],
      source
    ),
    rearRotors: pathOr(checkListItemDefault, ["preJobCheckList", "rearRotors"], source),
    brakeFluid: pathOr(checkListItemDefault, ["preJobCheckList", "brakeFluid"], source),
    brakeShoes: pathOr(checkListItemDefault, ["preJobCheckList", "brakeShoes"], source),
    brakeDrums: pathOr(checkListItemDefault, ["preJobCheckList", "brakeDrums"], source),
    calipers: pathOr(checkListItemDefault, ["preJobCheckList", "calipers"], source),
    shocksStruts: pathOr(checkListItemDefault, ["preJobCheckList", "shocksStruts"], source),
    controlArms: pathOr(checkListItemDefault, ["preJobCheckList", "controlArms"], source),
    wheelHubs: pathOr(checkListItemDefault, ["preJobCheckList", "wheelHubs"], source),
    studs: pathOr(checkListItemDefault, ["preJobCheckList", "studs"], source),
    lugnuts: pathOr(checkListItemDefault, ["preJobCheckList", "lugnuts"], source),
    sparkPlugs: pathOr(checkListItemDefault, ["preJobCheckList", "sparkPlugs"], source),
    carBattery: pathOr(checkListItemDefault, ["preJobCheckList", "carBattery"], source),
    alternator: pathOr(checkListItemDefault, ["preJobCheckList", "alternator"], source),
    engineAirFilter: pathOr(checkListItemDefault, ["preJobCheckList", "engineAirFilter"], source),
    cabinAirFilter: pathOr(checkListItemDefault, ["preJobCheckList", "cabinAirFilter"], source),
    otherBrakeParts: pathOr(checkListItemDefault, ["preJobCheckList", "otherBrakeParts"], source),
    dashboardLights: pathOr(checkListItemDefault, ["preJobCheckList", "dashboardLights"], source),
    odometer: pathOr("", ["vehicleInfo", "odometer"], source),
    oilLife: pathOr(
      {
        estimatedNextOilChangeTimeline: null,
        nextOilChangeMileage: {
          mileage: null,
          unavailable: null,
        },
        nextOilChangeDate: {
          date: null,
          unavailable: null,
        },
      },
      ["preJobCheckList", "oilLife"],
      source
    ),
    tireTread: pathOr(
      {
        frontLeft: {
          depth: null,
          unavailable: null,
        },
        frontRight: {
          depth: null,
          unavailable: null,
        },
        rearLeft: {
          depth: null,
          unavailable: null,
        },
        rearRight: {
          depth: null,
          unavailable: null,
        },
      },
      ["preJobCheckList", "tireTread"],
      source
    ),
  };
};
