import * as React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { ExistingDiscount, ExistingPromo, AddDiscount } from "../Invoices/DiscountReceiptItems";
import { ReceiptVewSummaryItem } from "../Invoices/ReceiptVewSummaryItem";
import { ReceiptViewLineItem } from "../Invoices/ReceiptViewLineItem";
import { EjiDiscount } from "../../generated/nest-graphql";

export const ServicesReceiptView: React.FC<{
  stage;
  receiptValues;
  calculatePossibleEjiPriceInfo;
}> = ({ receiptValues, calculatePossibleEjiPriceInfo, stage }) => {
  const {
    subTotal = "--",
    partsTotal = "--",
    laborTotal = "--",
    feesTotal = "--",
    totalTax = "--",
    discounts = [],
    amountDue = "--",
    promoCodes = [],
  } = receiptValues;

  return (
    <Card raised={true}>
      <CardContent>
        <ReceiptViewLineItem label={stage === "JOB" ? "Quoted Subtotal" : "Subtotal"} value={`$${subTotal}`} />
        <ReceiptViewLineItem subItem label={"Parts"} value={`$${partsTotal}`} />
        <ReceiptViewLineItem subItem label={"Labor"} value={`$${laborTotal}`} />
        {feesTotal && feesTotal !== "0.00" && <ReceiptViewLineItem subItem label={"Fees"} value={`$${feesTotal}`} />}
        <>
          {promoCodes.map((promo, promoIdx) => {
            return (
              <ExistingPromo
                promoCode={promo}
                key={promoIdx}
                discount={promo.coupon?.discount as EjiDiscount}
                discountIdx={promoIdx}
                calculatePossibleEJIPriceInfo={calculatePossibleEjiPriceInfo}
                stage={stage}
              />
            );
          })}
        </>
        <>
          {discounts.map((discount, discountIdx) => {
            return (
              <ExistingDiscount
                key={discountIdx}
                discount={discount}
                discountIdx={discountIdx}
                calculatePossibleEJIPriceInfo={calculatePossibleEjiPriceInfo}
                stage={stage}
              />
            );
          })}

          <AddDiscount calculatePossibleEJIPriceInfo={calculatePossibleEjiPriceInfo} stage={stage} />
        </>
        <ReceiptViewLineItem label={"Tax"} value={`$${totalTax}`} />
        <ReceiptVewSummaryItem label={"Total"} value={`$${amountDue}`} />
      </CardContent>
    </Card>
  );
};
