import { CalendarViewLayout } from "../components/Appointments/CalendarViewLayout";
import React, { useMemo } from "react";
import { AppointmentCalendar } from "../components/Appointments/AppointmentCalendar";
import { useQuery } from "@apollo/client";
import { Appointment, Query, QueryGetMyAppointmentsArgs } from "../generated/nest-graphql";
import { complement, contains, filter, indexBy, map, pathOr, pipe, pluck, prop, sortBy, uniq } from "ramda";
import { mapIndexed } from "../lib/functions";
import palette from "google-palette";
import { add, set, sub } from "date-fns";
import { GET_MY_APPOINTMENTS } from "../graphql/queries/getMyAppointments";
import { flow } from "fp-ts/lib/function";

const Home: React.FC = () => {
  const { futureDate, pastDate } = useMemo(() => {
    const defaultDate = set(new Date(), {
      milliseconds: 0,
      seconds: 0,
      minutes: 0,
      hours: 0,
    });
    const futureDate = add(defaultDate, { weeks: 2 });
    const pastDate = sub(defaultDate, { weeks: 1 });
    return { futureDate, pastDate };
  }, []);

  const { data, refetch } = useQuery<Query, QueryGetMyAppointmentsArgs>(GET_MY_APPOINTMENTS, {
    variables: {
      startRange: pastDate,
      endRange: futureDate,
    },
  });
  const { events, technicianColors } = useMemo(() => {
    if (!prop("getMyAppointments", data)) {
      return { events: null, technicianColors: null };
    }
    const events = flow(
      map((appointment: Appointment) => {
        const { technician, allDay, subject, endDate, startDate, id, job } = appointment;
        return {
          id,
          appointment,
          technician,
          technicianId: prop("id", technician),
          resourceTitle: `${prop("firstName", technician)} ${prop("lastName", technician)}`,
          start: new Date(startDate),
          end: new Date(endDate),
          job,
          allDay,
          title: `${subject}`,
          subject,
        };
      }),
      filter(flow(pathOr("", ["job", "status"]), complement(contains("Withdraw"))))
    )(data.getMyAppointments);
    const technicians = pipe(pluck("technician"), uniq, sortBy(prop("id")))(events);
    const colors = palette("mpn65", technicians.length);
    const technicianColors = pipe(
      mapIndexed((technician: any, index: any) => {
        const color = colors[index];
        return { ...technician, color };
      }),
      indexBy(prop("id") as any) as any
    )(technicians);
    return { events, technicianColors };
  }, [data]);
  if (!data) return null;
  return (
    <CalendarViewLayout>
      <AppointmentCalendar events={events} technicianColors={technicianColors} refetch={refetch} />
    </CalendarViewLayout>
  );
};

export default Home;
