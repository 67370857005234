import * as React from "react";
import { Button } from "@material-ui/core";
import { useToggle } from "../../hooks/useToggle";
import { AddCircleOutline } from "@material-ui/icons";
import { UpdateServiceDetailsDialog } from "../Jobs/UpdateServiceDetailsDialog";
import { useEffect, useRef } from "react";
import { PossibleEjiService } from "../../generated/nest-graphql";

export const AddServiceButton: React.FC<{
  parentSetFieldValue?: any;
  parentSubmitForm?: any;
  parentSubmitting?: boolean;
  servicesList: PossibleEjiService[];
}> = ({ parentSetFieldValue, parentSubmitForm, parentSubmitting, servicesList }) => {
  const [open, , toggle] = useToggle();
  const openId = useRef<number>(1);
  useEffect(() => {
    if (!open) {
      openId.current = openId.current + 1;
    }
  }, [open]);

  return (
    <>
      <Button variant="outlined" startIcon={<AddCircleOutline />} onClick={toggle}>
        Service
      </Button>
      <UpdateServiceDetailsDialog
        key={openId.current}
        service={null}
        serviceIdx={null}
        parentSetFieldValue={parentSetFieldValue}
        parentSubmitForm={parentSubmitForm}
        parentSubmitting={parentSubmitting}
        open={open}
        toggleDialog={toggle}
        servicesList={servicesList}
      ></UpdateServiceDetailsDialog>
    </>
  );
};
