import * as React from "react";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import { defaultTo, findLastIndex, path } from "ramda";
import { DeleteItemButton } from "./DeleteItemButton";
import { StyledHR } from "./StyledHR";
import { BasicTextField } from "./BasicTextField";
import { EJIItemFormValue, EJIServiceFormValue } from "../JobDetailsForm";
import { Autocomplete } from "@material-ui/lab";
import { PartsStore } from "../../../generated/nest-graphql";
import { Box, Checkbox, TextField } from "@material-ui/core";
import cloneDeep from "lodash.clonedeep";

export const PartItemSection: React.FC<{
  editedService: EJIServiceFormValue;
  setEditedServiceAndRecalculatePrice: any;
  item: EJIItemFormValue;
  itemIdx: number;
  filteredParts: EJIItemFormValue[];
  filteredPartsStores: PartsStore[];
}> = ({ editedService, setEditedServiceAndRecalculatePrice, item, itemIdx, filteredParts, filteredPartsStores }) => {
  return (
    <>
      <div className={"flex flex-row justify-between"}>
        <div className="text-lg font-bold">
          Part {findLastIndex((targetItem) => targetItem === item, filteredParts) + 1}
        </div>
        <DeleteItemButton
          editedService={editedService}
          setEditedServiceAndRecalculatePrice={setEditedServiceAndRecalculatePrice}
          itemToDelete={item}
        ></DeleteItemButton>
      </div>
      {editedService?.items[itemIdx]?.type !== null && (
        <div>
          <InputLabel shrink className="mb-1">
            Part Type
          </InputLabel>
          <Typography>{editedService?.items[itemIdx]?.type}</Typography>
        </div>
      )}
      {editedService?.items[itemIdx]?.orderItem?.partsStore?.name !== null && (
        <div>
          <Autocomplete
            value={item.orderItem?.partsStore}
            onChange={(_, selectedOption: PartsStore) => {
              const tempItems = [...editedService.items];
              tempItems[itemIdx].orderItem.partsStore = selectedOption;
              setEditedServiceAndRecalculatePrice({
                ...editedService,
                items: [...tempItems],
              });
            }}
            options={filteredPartsStores}
            getOptionLabel={(option) => `${option.vendor} - ${option.name}`}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Part Store"
                variant="outlined"
                required
                error={!item.orderItem?.partsStore}
                helperText={defaultTo("", !item.orderItem?.partsStore && "This is a required field.")}
              />
            )}
          />
        </div>
      )}
      <BasicTextField
        value={editedService.items?.[itemIdx].productSelection.name}
        label="Part Selection"
        onChange={(e) => {
          // I believe the name property is causing issues for some strange reason
          // https://stackoverflow.com/questions/44288164/cannot-assign-to-read-only-property-name-of-object-object-object
          // cloneDeep solves a bug we wrere having running into this error when attempting to type into product selection
          // TypeError: Cannot assign to read only property 'name' of object '#<Object>'
          const tempItems = cloneDeep(editedService.items);
          tempItems[itemIdx].productSelection.name = e.target.value;
          setEditedServiceAndRecalculatePrice({
            ...editedService,
            items: [...tempItems],
          });
        }}
      />
      <BasicTextField
        value={editedService.items?.[itemIdx].orderItem.partNumber}
        label="Part Number"
        isRequired={false}
        onChange={(e) => {
          const tempItems = [...editedService.items];
          tempItems[itemIdx].orderItem.partNumber = e.target.value;
          setEditedServiceAndRecalculatePrice({
            ...editedService,
            items: [...tempItems],
          });
        }}
      />
      <div className="grid grid-cols-2 gap-4">
        <BasicTextField
          value={editedService.items?.[itemIdx].vendorUnitCost}
          label={"Unit Cost"}
          inputMode="decimal"
          inputType="currency"
          onChange={(e) => {
            const tempItems = [...editedService.items];
            tempItems[itemIdx].vendorUnitCost = e.target.value;
            setEditedServiceAndRecalculatePrice({
              ...editedService,
              items: [...tempItems],
            });
          }}
        />
        <BasicTextField
          value={editedService?.items[itemIdx]?.units}
          label={"Quantity"}
          inputMode="numeric"
          inputType="quantity"
          onChange={(e) => {
            const tempItems = [...editedService.items];
            tempItems[itemIdx].units = defaultTo(0)(Number(e.target.value));
            setEditedServiceAndRecalculatePrice({
              ...editedService,
              items: [...tempItems],
            });
          }}
        />
      </div>
      {editedService?.items[itemIdx]?.notes !== null && editedService?.items[itemIdx]?.notes?.length > 0 && (
        <div>
          <InputLabel shrink className="mb-1">
            Private Notes
          </InputLabel>
          <Typography>{editedService?.items[itemIdx]?.notes}</Typography>
        </div>
      )}
      {!!editedService?.id && (
        <div className={"flex flex-row items-center"}>
          <Checkbox
            checked={!editedService?.items[itemIdx]?.overriddenCustomerPrice}
            color="primary"
            inputProps={{ "aria-label": "secondary checkbox" }}
            onChange={(event) => {
              const value = path<boolean>(["target", "checked"], event);
              const tempItems = [...editedService.items];
              tempItems[itemIdx].overriddenCustomerPrice = !value;
              setEditedServiceAndRecalculatePrice({
                ...editedService,
                items: [...tempItems],
              });
            }}
          />
          <Typography>
            <Box>Update Customer Price</Box>
          </Typography>
        </div>
      )}
      <StyledHR />
    </>
  );
};
