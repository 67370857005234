import gql from "graphql-tag";
import { partDetailFields } from "./getPartCatalogue";

export const ejiPriceInfoFields = `
    pricingConfig {
        laborRate
        vendorUnitCostMultipliers {
            startRangePrice
            endRangePrice
            multiplier
        }
    }
    partsTotal
    laborTotal
    feesTotal
    subTotal
    discounts {
        type
        amount
        reason
        total
    }
    promoCodes {
        code
        name
        coupon {
            name
            valid
            discount {
                type
                amount
                reason
                total
            }
        }
    }
    discountTotal
    promoCodeDiscountTotal
    combinedDiscountTotal
    finalPartsTotal
    finalLaborTotal
    finalFeesTotal
    finalSubTotal
    partsTax
    laborTax
    totalTax
    amountDue
`;

export const possibleEJIItemFields = `
    serviceProductId
    productSelection {
        name
        part {
            ${partDetailFields}
        }
    }
    type
    units
    overriddenCustomerPrice
    customerPrice
    vendorUnitCost
    behavior
    category
    notes
    orderItem {
        partsStore {
            id
            name
            vendor
        }
        isOrdered
        partNumber
    }
    marketVendorUnitCostMultiplierUsed
    marketLaborRateUsed
`;

export const possibleEJIServiceFields = `
    service
    name
    inEstimate
    customerPrice
    technicianPay
    items {
        ${possibleEJIItemFields}
    }
`;

const calculationReturnFields = `
    ejiPriceInfo {
        ${ejiPriceInfoFields}
    }
    ejiServices {
        ${possibleEJIServiceFields}
    }
`;

export const CALCULATE_POSSIBLE_EJI_PRICE_INFO = gql`query calculatePossibleEJIPriceInfo($calculatePossibleEJIPriceInfoInput: CalculatePossibleEJIPriceInfoInput!) {
    calculatePossibleEJIPriceInfo(calculatePossibleEJIPriceInfoInput: $calculatePossibleEJIPriceInfoInput) {
        ${calculationReturnFields}
    }
}`;
