import React, { useState } from "react";
import { CREATE_CARD_SETUP_INTENT } from "../../graphql/mutations/createCardSetupIntent";
import { ADD_CONTACT_PAYMENT_METHOD } from "../../graphql/mutations/addContactPaymentMethod";
import { useMutation } from "@apollo/client";
import { Mutation } from "../../generated/nest-graphql";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { CancelButton } from "../Buttons/CancelButton";
import { path } from "ramda";
import { Typography } from "@material-ui/core";
import { TextField } from "./fields/TextField";
import { FullScreenDialog } from "../FullScreenDialog";
import { showSuccessAlert, showErrorAlert } from "../../actions";
import { useDispatch } from "../../contexts/snackbar-context";
import makeStyles from "@material-ui/core/styles/makeStyles";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { SubmitButton } from "../Buttons/SubmitButton";
import * as Sentry from "@sentry/browser";

type AddCreditCardFormValues = {
  nameOnCard: string;
};

const validationSchema = Yup.object().shape({
  nameOnCard: Yup.string().required(),
});

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#ffffff",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "18px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const useStyles = makeStyles({
  card: {
    padding: "10px",
    borderRadius: "5px",
  },
});

export const AddCreditCardForm: React.FC<{
  open: boolean;
  toggle: () => void;
  contactId: string;
  setPaymentMethod?: (value: string) => void;
}> = ({ open, toggle, contactId, setPaymentMethod }) => {
  const classes = useStyles();
  const stripe = useStripe();
  const dispatch = useDispatch();
  const elements = useElements();
  const [cardErrorMessage, setCardErrorMessage] = useState("");
  const [cardComplete, setCardComplete] = useState(false);
  const initialValues: AddCreditCardFormValues = {
    nameOnCard: "",
  };

  const [createCardSetupIntent] = useMutation<Mutation>(CREATE_CARD_SETUP_INTENT);
  const [addContactPaymentMethod] = useMutation<Mutation>(ADD_CONTACT_PAYMENT_METHOD);

  const onSubmit = async (values: AddCreditCardFormValues) => {
    setCardErrorMessage("");
    const { nameOnCard } = values;

    try {
      const { data } = await createCardSetupIntent({ variables: { contactId, nameOnCard } });
      const client_secret: string = path(["createCardSetupIntent", "client_secret"], data);

      const { setupIntent, error } = await stripe.confirmCardSetup(client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      if (error) {
        setCardErrorMessage(error.message);
      } else {
        const { payment_method } = setupIntent;
        await addContactPaymentMethod({
          variables: {
            contactId,
            paymentMethodId: payment_method,
          },
        });
        showSuccessAlert(dispatch, "Success Added Credit Card");
        setPaymentMethod && setPaymentMethod(payment_method);
        toggle();
      }
    } catch (e) {
      showErrorAlert(dispatch, "Failed Adding Credit Card");
      toggle();
      Sentry.captureException(e, {
        extra: { values, card: elements.getElement(CardElement) },
      });
    }
  };
  return (
    <FullScreenDialog open={open} title={"Add new credit card"}>
      <Formik<AddCreditCardFormValues>
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ isValid, isSubmitting }) => {
          return (
            <Form>
              <div className="grid gap-4 mb-10 mt-2 px-6">
                <>
                  <Typography variant="h6" gutterBottom>
                    Add new credit card
                  </Typography>
                  <TextField label={"Name on card"} name={"nameOnCard"} required={true} />
                  <div className={"mb-4"}>
                    <CardElement
                      onChange={({ complete }) => setCardComplete(complete)}
                      className={classes.card}
                      options={CARD_ELEMENT_OPTIONS}
                    />
                  </div>
                  {cardErrorMessage && <div style={{ color: "red" }}>{cardErrorMessage}</div>}
                  <SubmitButton isSubmitting={isSubmitting} isValid={isValid && cardComplete} />
                </>
                <CancelButton onClick={toggle} variant="outlined" color="default">
                  Cancel
                </CancelButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </FullScreenDialog>
  );
};
