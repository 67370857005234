import * as React from "react";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import Typography from "@material-ui/core/Typography";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useMutation } from "@apollo/client";
import { Mutation, MutationPerformActionArgs } from "../../generated/nest-graphql";
import { PERFORM_ACTION } from "../../graphql/mutations/performAction";
import { green } from "@material-ui/core/colors";
import { showSuccessAlert } from "../../actions";
import { useDispatch } from "../../contexts/snackbar-context";

const theme = createTheme({
  palette: {
    primary: green,
  },
});

export const InvoiceActionButton: React.FC<{
  invoiceId: string;
  invoiceStatus: string;
}> = ({ invoiceId, invoiceStatus }) => {
  const [performAction, { loading }] = useMutation<Mutation, MutationPerformActionArgs>(PERFORM_ACTION);
  const dispatch = useDispatch();
  const onClick = async () => {
    if (invoiceStatus === "Paid") {
      await performAction({
        variables: {
          performActionInput: {
            actionName: "sendReceipt",
            entityId: invoiceId,
            entityName: "Invoice",
          },
        },
      });
      showSuccessAlert(dispatch, "Sent Receipt");
    } else {
      await performAction({
        variables: {
          performActionInput: {
            actionName: "sendInEmail",
            entityId: invoiceId,
            entityName: "Invoice",
          },
        },
      });
      showSuccessAlert(dispatch, "Sent In Email");
    }
  };
  const getActionText = (invoiceStatus) => {
    if (invoiceStatus === "Paid") {
      return "Send Receipt";
    }
    return null;
  };
  const buttonText = getActionText(invoiceStatus);
  if (!buttonText) return null;
  return (
    <ThemeProvider theme={theme}>
      <Button className="w-1/3" variant={"contained"} color={"primary"} onClick={onClick} disabled={loading}>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <div>
            <EmailIcon />
            <Typography display={"block"} variant={"caption"}>
              {buttonText}
            </Typography>
          </div>
        )}
      </Button>
    </ThemeProvider>
  );
};
