import * as React from "react";
import { ReactNode } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ListItem } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PhoneIcon from "@material-ui/icons/Phone";
import MessageIcon from "@material-ui/icons/Message";
import ListItemText from "@material-ui/core/ListItemText";
import {
  Contact,
  Query,
  QueryGetContactArgs,
} from "../../generated/nest-graphql";
import { GET_CONTACT_DETAILS } from "../../graphql/queries/getContactDetails";
import { useQuery } from "@apollo/client";

const IconListItem: React.FC<{ icon: ReactNode; text; href: string }> = ({
  icon,
  href,
  text,
}) => (
  <a href={href}>
    <ListItem button>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  </a>
);

export const ContactTheContactDialog: React.FC<{
  open: boolean;
  closeDialog: () => void;
  contact: Contact;
}> = ({ open, closeDialog, contact }) => {
  const { id } = contact;
  const { data } = useQuery<Query, QueryGetContactArgs>(GET_CONTACT_DETAILS, {
    variables: {
      id,
    },
  });
  if (!data) return null;
  const contactResult = data.getContact;
  const { fullName, phoneNumber } = contactResult;
  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>How would you like to contact {fullName}?</DialogTitle>
      <IconListItem
        icon={<PhoneIcon />}
        href={`tel:${phoneNumber}`}
        text={"Call"}
      />
      <IconListItem
        icon={<MessageIcon />}
        href={`sms://${phoneNumber}`}
        text={"Text"}
      />
    </Dialog>
  );
};
