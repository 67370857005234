import * as React from "react";
import { AppBar, createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SubmitButton } from "./SubmitButton";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      top: "auto",
      bottom: "76px",
    },
  })
);

export const BottomSubmitButtonRow: React.FC<{
  pinToBottom?: boolean;
  isSubmitting: boolean;
  isValid: boolean;
  handleSubmit?: any;
  label?: string;
}> = ({
  isSubmitting,
  isValid,
  handleSubmit,
  pinToBottom = false,
  label = "Submit",
}) => {
  const classes = useStyles();
  const styles = pinToBottom ? { bottom: 0 } : {};
  return (
    <AppBar position={"fixed"} className={classes.appBar} style={styles}>
      <Box style={{ backgroundColor: "black" }}>
        <SubmitButton
          isSubmitting={isSubmitting}
          isValid={isValid}
          handleSubmit={handleSubmit}
          label={label}
        />
      </Box>
    </AppBar>
  );
};
