import gql from "graphql-tag";

export const defaultServiceReturnFields = `
            service
            name
            technicianPay
            customerPrice
            inEstimate
            items {
                serviceProductId
                productSelection {
                    name
                }
                type
                units
                overriddenCustomerPrice
                customerPrice
                vendorUnitCost
                behavior
                category
                notes
                orderItem {
                    partsStore {
                        id
                        name
                    }
                    isOrdered
                    partNumber
                }
                marketVendorUnitCostMultiplierUsed
                marketLaborRateUsed
            }
        `;

export const GET_POSSIBLE_SERVICE = gql`
query getPossibleService ($getPossibleServiceInput: GetPossibleServiceInput!) {
    getPossibleService (getPossibleServiceInput: $getPossibleServiceInput) {
        ${defaultServiceReturnFields}
    }
}`;
