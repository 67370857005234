import { Typography } from "@material-ui/core";
import { prop } from "ramda";
import * as React from "react";
import { ButtonListItem } from "./ButtonListItem";

export const PartsStoreListItem: React.FC<{
  partsStore: any;
  isHomePartsStore?: boolean;
}> = ({ partsStore, isHomePartsStore = false }) => {
  const address = prop("address", partsStore);
  const name = `${prop("vendor", partsStore)} - ${prop("name", partsStore)}`;
  const mapUrl = `//maps.google.com/maps?daddr=${encodeURI(address)}`;
  const nameTag = isHomePartsStore ? (
    <Typography>{name}</Typography>
  ) : (
    <Typography style={{ color: "#f04e23" }}>{name}</Typography>
  );
  const addressTag = isHomePartsStore ? (
    <Typography>{address}</Typography>
  ) : (
    <Typography style={{ color: "#f04e23" }}>{address}</Typography>
  );
  return <ButtonListItem primary={nameTag} secondary={addressTag} href={mapUrl} buttonText={"Map"} />;
};
