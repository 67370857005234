import * as React from "react";
import { Button } from "@material-ui/core";
import { AreYouSureDialog } from "../Items/AreYouSureDialog";
import { useToggle } from "../../hooks/useToggle";

export const DeleteButton: React.FC<{ onDelete: any }> = ({ onDelete }) => {
  const [open, , toggleDialog] = useToggle();
  return (
    <>
      <Button onClick={toggleDialog} fullWidth={true} color={"primary"} variant={"outlined"}>
        Delete Invoice
      </Button>
      <AreYouSureDialog
        onNo={toggleDialog}
        onYes={async () => {
          toggleDialog();
          await onDelete();
        }}
        title={`Are you sure you want to delete this invoice?`}
        open={open}
        onClose={toggleDialog}
      />
    </>
  );
};
