import React, { createContext, Dispatch, Reducer, useContext, useReducer } from "react";
import { GlobalSnackBar } from "../components/GlobalSnackBar";
import { AppVersionUpdateNotifier } from "../components/AppVersionUpdateNotifier";
type SnackBarState = {
  showMessage: boolean;
  type: string;
  message: string;
};

const initialState: SnackBarState = {
  showMessage: false,
  type: "",
  message: "",
};

const SnackbarStateContext = createContext<{
  state: SnackBarState;
  dispatch: Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

export type DispatchAction = {
  type: string;
  payload: any;
};

const reducer: Reducer<SnackBarState, DispatchAction> = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SHOW_SUCCESS":
      return {
        showMessage: true,
        type: "success",
        message: payload.message,
      };
    case "SHOW_ERROR":
      return {
        showMessage: true,
        type: "error",
        message: payload.message,
      };
    case "SHOW_WARNING":
      return {
        showMessage: true,
        type: "warning",
        message: payload.message,
      };
    case "HIDE":
      return {
        ...state,
        showMessage: false,
      };
    default:
      throw new Error(`Unhandled Action: ${action}`);
  }
};

export const SnackBarProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SnackbarStateContext.Provider value={{ state, dispatch }}>
      {children}
      <GlobalSnackBar />
      <AppVersionUpdateNotifier />
    </SnackbarStateContext.Provider>
  );
};

export const useSnackBar = () => useContext(SnackbarStateContext);
export const useDispatch = () => {
  const { dispatch } = useContext(SnackbarStateContext);
  return dispatch;
};
