import gql from "graphql-tag";

export const CREATE_PAYMENT = gql`
  mutation createPayment($createPaymentInput: CreatePaymentInput!) {
    createPayment(createPaymentInput: $createPaymentInput) {
      amount
      createdAt
      id
      payer
      paymentMethod
      source
      type
      updatedAt
    }
  }
`;
