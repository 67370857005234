import * as React from "react";
import { Contact } from "../../generated/nest-graphql";
import { AppBar } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Typography from "@material-ui/core/Typography";
import { ContactSection } from "./ContactSection";
import { useHistory } from "react-router-dom";

export const ContactDetailsAppBar: React.FC<{ contact: Contact }> = ({ contact }) => {
  const history = useHistory();
  const { fullName } = contact;
  return (
    <AppBar position={"sticky"}>
      <Toolbar>
        <IconButton onClick={history.goBack}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant="h6">{`Contact: ${fullName}`}</Typography>
      </Toolbar>
      <ContactSection contact={contact} />
    </AppBar>
  );
};
