import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import * as serviceWorker from "./serviceWorker";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useToggle } from "./hooks/useToggle";

type ServiceWorkerCheckerState = {
  newVersionAvailable: boolean;
  waitingWorker: any;
};
export const ServiceWorkerChecker = ({ children }) => {
  const [state, setState] = useState<ServiceWorkerCheckerState>({
    newVersionAvailable: false,
    waitingWorker: {},
  });
  const [open, , toggle] = useToggle();

  const onServiceWorkerUpdate = (registration: ServiceWorkerRegistration) => {
    console.log("service updated", registration);
    console.log("registration waiting", registration.waiting);
    setState({
      waitingWorker: registration && registration.waiting,
      newVersionAvailable: true,
    });
  };

  useEffect(() => {
    serviceWorker.register({
      onUpdate: onServiceWorkerUpdate,
    });
    if (state.newVersionAvailable)
      //show snackbar with refresh button
      toggle();
  }, [state.newVersionAvailable, toggle]);

  const updateServiceWorker = () => {
    const { waitingWorker } = state;
    console.log({ waitingWorker });
    waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
    setState((prev) => ({
      newVersionAvailable: false,
      ...prev,
    }));
    window.location.reload();
  };
  return (
    <>
      {children}
      <Snackbar open={open}>
        <Alert
          severity={"info"}
          action={
            <Button className="snackbar-button" size="small" onClick={updateServiceWorker}>
              {"refresh"}
            </Button>
          }
        >
          There is a new version of the app available
        </Alert>
      </Snackbar>
    </>
  );
};
