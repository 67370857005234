import gql from "graphql-tag";

export const GET_PRODUCT_FRAGMENT = gql`
  fragment myProduct on Product {
    id
    amount
    laborCost
    partsCost
    name
    description
  }
`;
