import * as React from "react";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { ListItem } from "@material-ui/core";
import { TextField } from "./fields/TextField";
import { AutoCompleteCheckboxes } from "./fields/AutoCompleteCheckboxes";

const vehicleBrakeSymptomOptions = [
  "Squeaking",
  "Grinding",
  "Shaking/Vibrating",
  "Mushy/Soft Brake Pedal",
  "Stiff Brake Pedal",
  "Fluid Leak in Front or Rear",
  "Other",
];

export const VehicleInfoSymptomsFormSection: React.FC<{
  divider?: boolean;
}> = ({ divider = false }) => {
  return (
    <>
      <ListItem divider={false}>
        <ListItemText
          primary={
            <Typography className={"block"} component={"div"}>
              Symptoms
            </Typography>
          }
        />
      </ListItem>
      <ListItem divider={false}>
        <AutoCompleteCheckboxes
          name={"frontBrakeSymptoms"}
          label={"Front Brake Symptoms"}
          options={vehicleBrakeSymptomOptions}
        />
      </ListItem>
      <ListItem divider={false}>
        <AutoCompleteCheckboxes
          name={"rearBrakeSymptoms"}
          label={"Rear Brake Symptoms"}
          options={vehicleBrakeSymptomOptions}
        />
      </ListItem>
      <ListItem divider={divider}>
        <TextField name={"additionalNotes"} label="Additional Notes" multiline={true} rows={2} />
      </ListItem>
    </>
  );
};
