import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { default as CoreSelect } from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import { isEmpty } from "ramda";

export const SelectOptions: React.FC<{
  required?: boolean;
  options: { value: any; label: string }[];
  label: string;
  fullWidth?: boolean;
  onChange: (val: any) => void;
  errorMessage?: string;
}> = ({ label, options, required, fullWidth, onChange, errorMessage = "", ...props }) => {
  return (
    <FormControl variant="outlined" fullWidth={fullWidth} error={!isEmpty(errorMessage)}>
      <InputLabel>{label}</InputLabel>
      <CoreSelect {...props} label={label} required={required} onChange={onChange}>
        {options.map((val, i) => (
          <MenuItem key={i} value={val.value}>
            {val.label}
          </MenuItem>
        ))}
      </CoreSelect>
      {!isEmpty(errorMessage) && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};
