import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import createApolloClient from "../lib/apolloClient";
import { ApolloProvider } from "@apollo/client/react";

export const CustomApolloProvider: React.FC<{}> = ({ children }) => {
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  if (isAuthenticated) {
    const client = createApolloClient(() =>
      getAccessTokenSilently().then((val) => val)
    );
    console.log({ user });
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
  }
  return null;
};
