import cloneDeep from "lodash.clonedeep";
import React, { createContext, Dispatch, Reducer, useContext, useReducer } from "react";
import { PartsOrderingSurveyFormValues } from "../components/Forms/Surveys/PartsOrderingSurveyForm";

export const partsOrderingIssuesSurveyInitialState: PartsOrderingSurveyFormValues = {
  partsCorrect: null,
  partsStores: [],
  partsIssues: [],
  additionalDetails: "",
  completed: false,
};

const PartsOrderingIssuesSurveyStateContext = createContext<{
  state: PartsOrderingSurveyFormValues;
  dispatch: Dispatch<any>;
}>({
  state: cloneDeep(partsOrderingIssuesSurveyInitialState),
  dispatch: () => null,
});

export type DispatchAction = {
  type: string;
  payload: any;
};

const reducer: Reducer<PartsOrderingSurveyFormValues, DispatchAction> = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "UPDATE_PARTS_SURVEY":
      return {
        ...state,
        ...payload,
      };
    default:
      throw new Error(`Unhandled Action: ${action}`);
  }
};

export const PartsOrderingIssuesSurveyProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, cloneDeep(partsOrderingIssuesSurveyInitialState));

  return (
    <PartsOrderingIssuesSurveyStateContext.Provider value={{ state, dispatch }}>
      {children}
    </PartsOrderingIssuesSurveyStateContext.Provider>
  );
};

export const usePartsOrderingIssuesSurvey = () => useContext(PartsOrderingIssuesSurveyStateContext);
export const usePartsOrderingDispatch = () => {
  const { dispatch } = useContext(PartsOrderingIssuesSurveyStateContext);
  return dispatch;
};
