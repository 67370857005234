import gql from "graphql-tag";

export const CREATE_FILE = gql`
  mutation createFile($createFileInput: CreateFileInput!) {
    createFile(createFileInput: $createFileInput) {
      caption
      fileName
      url
      share
      signedUrl
      createdAt
      updatedAt
    }
  }
`;
