import * as React from "react";
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

type AreYouSureDialogProps = {
  onNo: any;
  onYes: any;
  title: string;
  open: boolean;
  onClose: () => void;
};
export const AreYouSureDialog: React.FC<AreYouSureDialogProps> = ({ open, onNo, onYes, onClose, title }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      {open && (
        <>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogActions>
              <Button onClick={onNo} color="primary">
                No
              </Button>
              <Button onClick={onYes} color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};
