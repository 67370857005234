import { Form, Formik } from "formik";
import React from "react";
import { CheckboxField } from "./fields/CheckboxField";
import { Box } from "@material-ui/core";
import { SubmitButton } from "../Buttons/SubmitButton";

export type PreferencesFormValues = {
  receiveSMSNotifications?: boolean;
};
type PreferencesFormProps = {
  initialValues: PreferencesFormValues;
  onSubmit: any;
};
export const PreferencesForm = ({
  initialValues,
  onSubmit,
}: PreferencesFormProps) => {
  return (
    <Formik<PreferencesFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid }) => {
        return (
          <Form>
            <Box paddingX={3} className={"grid gap-4"}>
              <CheckboxField
                label={"Receive SMS notifications"}
                name={"receiveSMSNotifications"}
              />
            </Box>
            <Box marginTop={5} />
            <SubmitButton isSubmitting={isSubmitting} isValid={isValid} />
          </Form>
        );
      }}
    </Formik>
  );
};
