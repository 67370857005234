import * as React from "react";
import { ErrorMessage, useField } from "formik";
import { TextField } from "@material-ui/core";

export const FormikTextField: React.FC<{
  name: string;
  label?: string;
  required?: boolean;
}> = ({ name, required, label }) => {
  const [field] = useField(name);
  const { value, ...fieldRest } = field;
  return (
    <div>
      <TextField value={value} {...fieldRest} label={label} required={required} fullWidth={true} variant={"outlined"} />
      <ErrorMessage name={field.name}>
        {(errorMessage) => <div style={{ color: "red" }}>{errorMessage}</div>}
      </ErrorMessage>
    </div>
  );
};
