import gql from "graphql-tag";
import { ejiPriceInfoFields } from "./calculatePossibleEjiPriceInfo";
import { partDetailFields } from "./getPartCatalogue";

export const preJobCheckListItemField = `{
            partCondition
            additionalInfo
        } `;

export const brakePadsListItemField = `{
    padThickness {
        thickness
        unavailable
    }
    condition
}`;

export const oilLifeListItemField = `{
    estimatedNextOilChangeTimeline
    nextOilChangeMileage {
        mileage
        unavailable
    }
    nextOilChangeDate {
        date
        unavailable
    }
    lastOilChangeDate {
        date
        unavailable
    }
}`;

export const tireTreadListItemField = `{
    frontRight {
        depth
        unavailable
    }
    frontLeft {
        depth
        unavailable
    }
    rearRight {
        depth
        unavailable
    }
    rearLeft {
        depth
        unavailable
    }
}`;

export const preJobCheckListField = `
    preJobCheckList {
        frontPads ${preJobCheckListItemField}
        frontPadsV2 ${brakePadsListItemField}
        frontRotors ${preJobCheckListItemField}
        rearPads ${preJobCheckListItemField}
        rearPadsV2 ${brakePadsListItemField}
        rearRotors ${preJobCheckListItemField}
        brakeFluid ${preJobCheckListItemField}
        brakeShoes ${preJobCheckListItemField}
        brakeDrums ${preJobCheckListItemField}
        calipers ${preJobCheckListItemField}
        shocksStruts ${preJobCheckListItemField}
        controlArms ${preJobCheckListItemField}
        wheelHubs ${preJobCheckListItemField}
        studs ${preJobCheckListItemField}
        lugnuts ${preJobCheckListItemField}
        sparkPlugs ${preJobCheckListItemField}
        carBattery ${preJobCheckListItemField}
        alternator ${preJobCheckListItemField}
        engineAirFilter ${preJobCheckListItemField}
        cabinAirFilter ${preJobCheckListItemField}
        otherBrakeParts ${preJobCheckListItemField}
        dashboardLights ${preJobCheckListItemField}
        oilLife ${oilLifeListItemField}
        tireTread ${tireTreadListItemField}
    }
`;

export const ejiItemFields = `
    id
    serviceProductId
    productSelection {
        name
        part {
            ${partDetailFields}
        }
    }
    type
    units
    overriddenCustomerPrice
    customerPrice
    vendorUnitCost
    behavior
    category
    notes
    orderItem {
        partsStore {
            id
            name
            vendor
        }
        isOrdered
        partNumber
    }
    marketVendorUnitCostMultiplierUsed
    marketLaborRateUsed
`;

export const ejiServiceFields = `
    id
    service
    name
    inEstimate
    customerPrice
    technicianPay
    items {
        ${ejiItemFields}
    }
`;

export const jobDetailsFields = `
        invoice {
            id
        }
        vehicleInfo {
            frontPadLife
            licensePlate
            make
            model
            odometer
            rearPadLife
            vin
            year
            extraInfo
            symptoms
            frontBrakeSymptoms
            rearBrakeSymptoms
            customerExpectation
            additionalNotes
        }
        appointment {
            id
            allDay
            endDate
            startDate
            subject
            technician {
                id
                firstName
                lastName
            }
        }
        contact {
            id
            phoneNumber
            firstName
            lastName
            email
            stripeCustomer
            stripePaymentMethods
        }
        feedback {
            rating
            doneBetter
            additionalComments
        }
        partsOrderingIssuesSurveys {
            partsCorrect
            partsStores
            partsIssues
            additionalDetails 
            completed
            submittedBy
        }
        description
        id
        jobNumber
        serviceCatalogueUsed
        market
        items {
            product
            partsStore {
                id
                name
                vendor
                address
            }
            name
            amount
            description
            isInEstimate
            partNumber
            laborCost
            partsCost
            createdAt
            updatedAt
        }
        partsStore {
            id
            name
            vendor
        }
        jobName
        type
        serviceCallReason
        serviceCallReasonExtraInfo
        contact {
            id
            firstName
            lastName
            fullName
            files {
                fileName
            }
            stripeCustomer
            stripePaymentMethods
            fleet {
                name
            }
        }
        serviceLocation
        partsInfo {
            partsLocation
            partsOrderNumber
            partsOrdered
            partsNotes
        }
        ${preJobCheckListField}
        estimate {
            id
        }
        privateNotes
        serviceLocationNotes
        taxable
        createdAt
        updatedAt
        status
        serviceCatalogueUsed
        services {
            ${ejiServiceFields}
        }
        priceInfo {
            ${ejiPriceInfoFields}
        }`;

export const GET_JOB = gql`query getJob($id: ID!) {
    getJob(id: $id) {
        ${jobDetailsFields}
    }
}`;
