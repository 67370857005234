import Grid from "@material-ui/core/Grid";
import { flow } from "fp-ts/lib/function";
import { allPass, pathOr, startsWith } from "ramda";
import React from "react";
import { Select } from "./fields/Select";
import { TextField } from "./fields/TextField";
import { JobDetailsFormValues } from "./JobDetailsForm";

const types = ["Brake Repair Service", "Service Call"];
const serviceCallReasons = [
  "Error with initial booking diagnosis",
  "No repair necessary",
  "Unavailable parts",
  "Cannot perform the necessary repair",
  "Customer refused service after on-site diagnosis",
  "Could not access vehicle",
  "Customer no show or no response",
  "Other",
];

export const JobTypeFormSection: React.FC<{ values: JobDetailsFormValues }> = ({ values }) => {
  return (
    <Grid container item spacing={2}>
      <Grid item xs={12}>
        <Select label="Type" name="type" options={types} fullWidth={true} />
      </Grid>
      <Grid item xs={12}>
        {flow(pathOr("", ["type"]), startsWith("Service"))(values) && (
          <Select label="Service Call Reason" name="serviceCallReason" options={serviceCallReasons} fullWidth={true} />
        )}
      </Grid>
      <Grid item xs={12}>
        {allPass([
          flow(pathOr("", ["serviceCallReason"]), startsWith("Other")),
          flow(pathOr("", ["type"]), startsWith("Service")),
        ])(values) && (
          <TextField
            label="Additional Service Call Reason Information"
            name={"serviceCallReasonExtraInfo"}
            multiline={true}
            rows={3}
          />
        )}
      </Grid>
    </Grid>
  );
};
