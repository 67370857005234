import { all, any, defaultTo, head, isEmpty, propEq } from "ramda";

import _ from "lodash";
import { EJIItemFormValue, EJIServiceFormValue } from "../JobDetailsForm";
import { PossibleEjiService } from "../../../generated/nest-graphql";

const isTruthy = (val) => !!val;

export const isEjiServiceFormValueValid = (service: EJIServiceFormValue, servicesList: PossibleEjiService[]) => {
  return all(isTruthy)([
    !isEmpty(service),
    service?.items?.length > 0,
    doesContainPartIfCanContainPart(servicesList, service),
    ...defaultTo([false])(
      service?.items?.map((product, productIdx) => {
        switch (product.category) {
          case "Part":
            return isPartValid(product);
          case "Fee":
            return isFeeValid(product);
          case "Labor":
            return isLaborValid(product);
          default:
            return false;
        }
      })
    ),
  ]);
};

const doesContainPartIfCanContainPart = (possibleServicesData: PossibleEjiService[], service: EJIServiceFormValue) => {
  let correspondingService = possibleServicesData.find((x) => x.name === service.name);
  let canContainPart = any(propEq("category", "Part"))(correspondingService?.items ?? []);
  let doesContainPart = any(propEq("category", "Part"))(service?.items ?? []);
  return canContainPart ? doesContainPart : true;
};

const isPartValid = (product: EJIItemFormValue) => {
  return all(isTruthy)([
    product?.productSelection?.name,
    product?.vendorUnitCost,
    product?.productSelection?.name?.length > 0,
    product?.vendorUnitCost !== null,
    product?.units !== null,
    product?.units >= 0,
  ]);
};

const isFeeValid = (product: EJIItemFormValue) => {
  return all(isTruthy)([product?.customerPrice && product?.customerPrice !== null]);
};

const isLaborValid = (product: EJIItemFormValue) => {
  return all(isTruthy)([product?.units > 0.0]);
};
