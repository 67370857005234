import * as React from "react";
import { useField } from "formik";
import { Autocomplete } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { TextField, Checkbox } from "@material-ui/core";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const AutoCompleteCheckboxes: React.FC<{
  name: string;
  options: string[];
  label: string;
}> = ({ name, options, label }) => {
  const [field, { error }, helper] = useField(name);
  const { onChange, ...rest } = field;
  return (
    <>
      <div style={{ width: "100%" }}>
        <Autocomplete
          {...rest}
          fullWidth
          multiple
          options={options}
          disableCloseOnSelect
          getOptionLabel={(option: string) => option}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
              {option}
            </React.Fragment>
          )}
          renderInput={(params) => <TextField {...params} variant="outlined" label={label} />}
          onChange={(_, newValue: string[]) => helper.setValue(newValue)}
        />
        {error && <div style={{ color: "red" }}>{error}</div>}
      </div>
    </>
  );
};
