import * as React from "react";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

export const BackButton: React.FC<{}> = () => {
  const { goBack } = useHistory();
  return (
    <Button fullWidth={true} color={"primary"} onClick={goBack} variant={"contained"}>
      Go Back
    </Button>
  );
};
