import * as Yup from "yup";
import {
  Mutation,
  MutationGenerateGenericPresignedUrlArgs,
} from "../../generated/nest-graphql";
import { Form, Formik } from "formik";
import { useS3Upload } from "../../hooks/useS3Upload";
import { TextField } from "./fields/TextField";
import { SubmitButton } from "../Buttons/SubmitButton";
import Typography from "@material-ui/core/Typography";
import React from "react";
import Box from "@material-ui/core/Box";
import { GENERATE_GENERIC_PRESIGNED_URL } from "../../graphql/mutations/generateGenericPresignedUrl";
import { useMutation } from "@apollo/client";

export type FileFormValues = {
  caption: string;
  fileName: string;
  url: string;
  files: any[];
};

const fileFormValidationSchema = Yup.object().shape({
  caption: Yup.string().notRequired(),
  url: Yup.string().required("Required"),
  fileName: Yup.string().required("Required"),
});
export const FileForm: React.FC<{
  contactId: string;
  onSubmit: (values, helper) => void;
  initialValues: FileFormValues;
}> = ({ contactId, initialValues, onSubmit }) => {
  return (
    <Formik<FileFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={fileFormValidationSchema}
      validateOnMount={true}
    >
      {({ isValid, isSubmitting, values, setFieldValue, resetForm }) => {
        return (
          <FileFormInternals
            contactId={contactId}
            values={values}
            setFieldValue={setFieldValue}
            isSubmitting={isSubmitting}
            isValid={isValid}
            resetForm={resetForm}
          />
        );
      }}
    </Formik>
  );
};

const FileFormInternals: React.FC<{
  resetForm: any;
  values: FileFormValues;
  setFieldValue: any;
  contactId: string;
  isSubmitting: boolean;
  isValid: boolean;
}> = ({
  isSubmitting,
  isValid,
  values,
  setFieldValue,
  contactId,
  resetForm,
}) => {
  const [generatePresignedUrl] = useMutation<
    Mutation,
    MutationGenerateGenericPresignedUrlArgs
  >(GENERATE_GENERIC_PRESIGNED_URL);
  const { files: myFiles } = values;
  const setMyFiles = (acceptedFiles) => setFieldValue("files", acceptedFiles);
  const { getRootProps, getInputProps } = useS3Upload({
    myFiles,
    setMyFiles,
    contactId,
    onError: console.error,
    onUploadDone: async ({ url, fileName }) => {
      setFieldValue("url", url);
      setFieldValue("fileName", fileName);
    },
    onUploadStart: async (img) => {
      resetForm({
        caption: "",
        url: "",
        fileName: "",
      });
      const result = await generatePresignedUrl({
        variables: {
          path: `contacts/${contactId}/${img}`,
        },
      });
      return result.data.generateGenericPresignedUrl;
    },
  });
  const files = values.files.map((file: any) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  return (
    <Form>
      <Box>
        <Typography>Add File</Typography>
        <Box
          {...getRootProps({ className: "dropzone bg-black-900 p-4 rounded" })}
        >
          <input {...getInputProps()} />
          <Typography variant="body1">Tap here to upload a file</Typography>
        </Box>
        <Box className="py-4">
          <TextField name={"caption"} label={"Caption"} />
        </Box>
        <Box>
          <h4>Files</h4>
          <ul>{files}</ul>
        </Box>
      </Box>
      <div className="flex flex-row justify-between">
        <div>
          <SubmitButton
            label={"Upload"}
            isSubmitting={isSubmitting}
            isValid={isValid}
          />
        </div>
      </div>
    </Form>
  );
};
