import * as React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

export const ReceiptVewSummaryItem: React.FC<{
  label: string;
  value: string;
}> = ({ label, value }) => {
  return (
    <Box className="flex flex-row justify-between pt-2">
      <Typography variant={"h6"}>{label}</Typography>
      <Typography variant={"h6"}>{value}</Typography>
    </Box>
  );
};
