// date-fns format
export const DATE_FORMAT: string = "MM/dd/yyyy";

export const TIME_FORMAT: string = "hh:mm a";

export const EJI_DISCOUNT_TYPE_PERCENT = "Percent";
export const EJI_DISCOUNT_TYPE_FLAT = "Flat";
export const DISCOUNT_LIMIT_PERCENT = 20;
export const DISCOUNT_LIMIT_FLAT = 200;

export const DISCOUNT_REASONS = ["Added service discount", "Onsite issue", "Military discount", "Promotional discount"];

export const SERVICE_CATALOGUE_USED_ITEMS = "Items";

export const SERVICE_CATALOGUE_USED_SERVICES = "Services";

export const DEFAULT_SERVICE_CATALOGUE_USED = SERVICE_CATALOGUE_USED_SERVICES;

export const JOB = "JOB";
export const POTENTIAL_INVOICE = "POTENTIAL_INVOICE";
export const CREATED_INVOICE = "CREATED_INVOICE";
