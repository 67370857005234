import * as React from "react";
import { useField } from "formik";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { applySpec, identity, map, propOr } from "ramda";

type AutoCompleteSelectFieldProps = {
  name: string;
  label: string;
  required?: boolean;
  options: string[];
  postOnChange?: (val: any) => void;
};
type OptionType = {
  label: string;
  value: string;
  inputValue?: string;
};
const filter = createFilterOptions<OptionType>();

const toOptions = (arr: string[]) =>
  map<string, OptionType>(
    applySpec<OptionType>({
      label: identity,
      value: identity,
    }),
    arr
  );
export const AutoCompleteSelectField = ({
  name,
  required,
  label,
  options,
  postOnChange,
}: AutoCompleteSelectFieldProps) => {
  const [field, , helper] = useField(name);
  const { onChange, value, ...rest } = field;
  const mappedOptions = toOptions(options);
  return (
    <Autocomplete<OptionType, boolean, false, true>
      {...rest}
      value={{ label: value, value }}
      handleHomeEndKeys
      freeSolo
      selectOnFocus
      clearOnBlur
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") return option;
        if (typeof option.value === "string") {
          return propOr("", "label", option);
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return propOr("", "inputValue", option);
        }
        // Regular option
        return propOr("", "label", option);
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== "") {
          filtered.push({
            inputValue: params.inputValue,
            label: `Add "${params.inputValue}"`,
            value: params.inputValue,
          });
        }
        return filtered;
      }}
      onChange={(event, newValue: OptionType) => {
        if (typeof newValue === "string") {
          helper.setValue(newValue);
          postOnChange && postOnChange(newValue);
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          helper.setValue(newValue.inputValue);
          postOnChange && postOnChange(newValue.inputValue);
        } else {
          helper.setValue(newValue.value);
          postOnChange && postOnChange(newValue.value);
        }
      }}
      renderInput={(params) => <TextField {...params} required={required} label={label} variant="outlined" />}
      options={mappedOptions}
    />
  );
};
