import * as React from "react";
import { useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { PossibleEjiItem, Query, QueryGetAllPossibleServicesArgs } from "../../generated/nest-graphql";
import { Autocomplete } from "@material-ui/lab";
import { Button, TextField } from "@material-ui/core";
import { TECHNICIAN_GET_ME } from "../../graphql/queries/technicianGetMe";
import { EJIServiceFormValue } from "./JobDetailsForm";
import { AddOutlined } from "@material-ui/icons";
import { none, propEq } from "ramda";
import { GET_ALL_POSSIBLE_SERVICES } from "../../graphql/queries/getAllPossibleServices";

export const AddPartSection: React.FC<{
  editedService: EJIServiceFormValue;
  setEditedServiceAndRecalculatePrice: any;
}> = ({ editedService, setEditedServiceAndRecalculatePrice }) => {
  const [selectedProduct, setSelectedProduct] = useState<ProductOption | null>(null);
  const { data: possibleServicesData } = useQuery<Query, QueryGetAllPossibleServicesArgs>(GET_ALL_POSSIBLE_SERVICES, {
    variables: {
      getAllPossibleServicesInput: {
        inEstimate: false,
      },
    },
  });
  const { data: technicianGetMeData } = useQuery<Query>(TECHNICIAN_GET_ME);
  const homePartsStore = technicianGetMeData?.technicianGetMe?.homePartsStore;
  const productList: PossibleEjiItem[] = useMemo(() => {
    if (!possibleServicesData || !technicianGetMeData) {
      return [];
    }
    return possibleServicesData.getAllPossibleServices
      .find(({ name }) => name === editedService.name)
      ?.items.map((item) => ({
        ...item,
        orderItem: {
          ...item.orderItem,
          partsStore: homePartsStore ?? null,
        },
      }));
  }, [editedService.name, homePartsStore, possibleServicesData, technicianGetMeData]);
  if (!possibleServicesData || !technicianGetMeData) return null;
  if (none(propEq("category", "Part"))(productList ?? [])) return null;
  const productsToOptions = (productList = []) => {
    return productList
      .filter((product) => product?.category === "Part")
      .map((product) => {
        return {
          label: product.type,
          value: product,
        };
      });
  };

  return (
    <div className={"pt-4"}>
      <Autocomplete
        value={selectedProduct}
        onChange={(_, selectedOption: ProductOption) => {
          setSelectedProduct(selectedOption);
        }}
        options={productsToOptions(productList)}
        getOptionLabel={(option) => option.label}
        style={{ width: "100%" }}
        renderInput={(params) => <TextField {...params} label="Select Part" variant="outlined" />}
      />
      <div className={"pt-4"}>
        <Button
          variant="outlined"
          startIcon={<AddOutlined />}
          style={{ minWidth: "100%" }}
          disabled={!selectedProduct}
          onClick={() => {
            setEditedServiceAndRecalculatePrice({
              ...editedService,
              items: [...editedService.items, selectedProduct.value],
            });
            setSelectedProduct(null);
          }}
          type={"button"}
        >
          Add Part
        </Button>
      </div>
    </div>
  );
};

type ProductOption = {
  label: string;
  value: PossibleEjiItem;
};
