import React, { useState, useEffect } from "react";
import { path, prop } from "ramda";
import { FullScreenDialog } from "../components/FullScreenDialog";
import { GET_CURRENT_APP_VERSION } from "../graphql/queries/getCurrentAppVersion";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_TECHNICIAN } from "../graphql/mutations/updateTechnician";
import { TECHNICIAN_GET_ME } from "../graphql/queries/technicianGetMe";
import { Mutation, MutationUpdateTechnicianArgs, Query } from "../generated/nest-graphql";
import { keys } from "ramda";
import { cleanObject, objectDiff } from "../lib/functions";

const { REACT_APP_SENTRY_RELEASE } = process.env;

export const AppVersionUpdateNotifier: React.FC<{}> = () => {
  const [outdatedVersionnotifierShown, showOutdatedVersionnotifierShown] = useState(false);
  const [currentVersion, setCurrentVersion] = useState(null);
  const lastVersion: string = REACT_APP_SENTRY_RELEASE;
  const { startPolling, stopPolling } = useQuery(GET_CURRENT_APP_VERSION, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const result = prop("getTechnicianCurrentAppVersion", data);
      const version: string = path(["version"], result);
      setCurrentVersion(version);
    },
  });

  const [updateTechnician] = useMutation<Mutation, MutationUpdateTechnicianArgs>(UPDATE_TECHNICIAN);

  useQuery<Query>(TECHNICIAN_GET_ME, {
    onCompleted: async (data) => {
      const technician = prop("technicianGetMe", data);
      const values = { ...technician, version: lastVersion };
      const versionSpec = objectDiff(values, technician);
      const updates = cleanObject(versionSpec);

      if (keys(updates).length) {
        await updateTechnician({
          variables: {
            id: technician.id,
            updateTechnicianInput: updates,
          },
        });
      }
    },
  });

  useEffect(() => {
    startPolling(1800000);
    return () => {
      stopPolling();
    };
  }, [stopPolling, startPolling]);

  useEffect(() => {
    if (currentVersion && process.env.NODE_ENV !== "development") {
      console.log(currentVersion, lastVersion);
      if (currentVersion !== lastVersion) {
        showOutdatedVersionnotifierShown(true);
      }
    }
  }, [currentVersion, lastVersion]);

  return (
    <FullScreenDialog
      title="Version Outdated"
      open={outdatedVersionnotifierShown}
      onClose={() => {
        showOutdatedVersionnotifierShown(false);
      }}
    >
      <span style={{ margin: 38 }}>Please exit out of all NuBrakes tabs and reopen the application</span>
    </FullScreenDialog>
  );
};
