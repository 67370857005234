import { useMutation } from "@apollo/client";
import {
  Mutation,
  MutationUpdateInvoiceArgs,
  MutationUpdateJobArgs,
  UpdateJobInput,
} from "../../generated/nest-graphql";
import { PreJobCheckListForm } from "../Forms/PreJobCheckListForm";
import { UPDATE_JOB } from "../../graphql/mutations/updateJob";
import { cleanObject } from "../../lib/functions";
import React from "react";
import { PreJobCheckList } from "../../types/PreJobCheckList";
import { flow } from "fp-ts/lib/function";
import { preJobCheckListUpdateJobSpec } from "./jobDetailsSpec";
import { showSuccessAlert } from "../../actions";
import { useDispatch } from "../../contexts/snackbar-context";
import { useInspectionReport } from "../../contexts/inspection-context";
import { UPDATE_INVOICE } from "../../graphql/mutations/updateInvoice";
import { keys } from "ramda";

export type PreJobCheckListFormValues = PreJobCheckList & {
  odometer: string;
};

export const PreJobCheckListDialog = ({
  jobId,
  invoiceId,
  open,
  onClose,
  initialValues,
}: {
  jobId: string;
  invoiceId?: string;
  open: boolean;
  onClose: () => void;
  initialValues: PreJobCheckListFormValues;
}) => {
  const dispatch = useDispatch();
  const { dispatch: inspectionDispatch } = useInspectionReport();
  const [updateJob] = useMutation<Mutation, MutationUpdateJobArgs>(UPDATE_JOB);
  const [updateInvoice] = useMutation<Mutation, MutationUpdateInvoiceArgs>(UPDATE_INVOICE);
  const updateOnClose = (values: PreJobCheckListFormValues) => {
    inspectionDispatch({ type: "UPDATE_FULL_INSPECTION", payload: values });
    onClose();
  };
  const onSubmit = async (values: PreJobCheckListFormValues) => {
    inspectionDispatch({ type: "UPDATE_FULL_INSPECTION", payload: values });
    const input: UpdateJobInput = flow(preJobCheckListUpdateJobSpec)(values as PreJobCheckListFormValues);
    await updateJob({
      variables: {
        id: jobId,
        updateJobInput: input,
      },
    });
    if (invoiceId && keys(values).length && input) {
      updateInvoice({
        variables: {
          id: invoiceId,
          updateInvoiceInput: input,
        },
      });
    }
    onClose();
    showSuccessAlert(dispatch, "Success");
  };
  return <PreJobCheckListForm initialValues={initialValues} onSubmit={onSubmit} open={open} onClose={updateOnClose} />;
};
