import * as React from "react";
import { Button, ListItem } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { ReactNode } from "react";

export const ButtonListItem: React.FC<{
  secondary: ReactNode;
  primary: ReactNode;
  href: string;
  buttonText: string;
}> = ({ primary, href, buttonText, secondary }) => {
  return (
    <ListItem divider style={{ paddingRight: 90 }}>
      <ListItemText
        primary={primary}
        secondary={secondary}
        primaryTypographyProps={{
          style: {
            overflowWrap: "break-word",
          },
        }}
      />
      <ListItemSecondaryAction>
        <a href={href}>
          <Button color={"primary"} variant={"outlined"}>
            {buttonText}
          </Button>
        </a>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
