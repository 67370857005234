import * as React from "react";
import { Drawer } from "@material-ui/core";

export type BottomDrawerProps = {
  open: boolean;
  onClose?: () => void;
};

export const BottomDrawer: React.FC<BottomDrawerProps> = ({ children, open, onClose }) => {
  return (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      {children}
    </Drawer>
  );
};
