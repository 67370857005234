import React from "react";
import { ErrorMessage, useField } from "formik";
import { filter, head, keys, pipe, propEq } from "ramda";
import { useApolloClient } from "@apollo/client";
import { GET_PRODUCT_FRAGMENT } from "../../../graphql/fragments";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import Box from "@material-ui/core/Box";

export const ProductSelectTypeAhead: React.FC<{
  required: boolean;
  setFieldValue: (fieldName: string, value: any) => void;
  name: string;
  label: string;
  options: any;
}> = ({ name, options, setFieldValue, required }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helper] = useField(name);
  const client = useApolloClient();
  // @ts-ignore
  const result = pipe(filter(propEq("value", field.value)), head)(options);
  return (
    <Box>
      <Autocomplete
        value={result}
        onChange={async (event, optionValue: any) => {
          const { value } = optionValue;
          const result = await client.readFragment({
            id: `Product:${value}`,
            fragment: GET_PRODUCT_FRAGMENT,
          });
          helper.setValue(value);
          const { id, __typename, ...rest } = result;
          keys(rest).forEach((key) => {
            const val = rest[key];
            setFieldValue(String(key), val ? val : "");
          });
        }}
        options={options}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Product"
            variant="outlined"
            required={required}
          />
        )}
      />
      <ErrorMessage name={field.name}>
        {(errorMessage) => <div style={{ color: "red" }}>{errorMessage}</div>}
      </ErrorMessage>
    </Box>
  );
};
