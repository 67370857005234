import * as React from "react";
import { Button } from "@material-ui/core";

export const RemoveButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Button fullWidth={true} color={"secondary"} onClick={onClick} variant={"contained"}>
      Remove
    </Button>
  );
};
