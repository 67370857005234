import * as React from "react";
import { Button, ListItem } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { ContactTheContactDialog } from "./ContactTheContactDialog";
import { Contact } from "../../generated/nest-graphql";
import { ContactDialog } from "../Contacts/ContactDialog";
import { useHistory } from "react-router-dom";
import { useToggle } from "../../hooks/useToggle";

const ContactListItemInternals: React.FC<{
  primary: string;
  contactId: string;
  secondary: string;
  onClick: () => void;
}> = ({ onClick, primary, secondary, contactId }) => {
  const { push } = useHistory();
  return (
    <ListItem divider button onClick={() => push(`/contacts/${contactId}`)}>
      <ListItemText primary={primary} secondary={secondary} />
      <ListItemSecondaryAction>
        <Button color={"primary"} variant={"outlined"} onClick={onClick}>
          {"Contact"}
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export const ContactListItem: React.FC<{ contact: Contact }> = ({ contact }) => {
  const { fullName, email, id } = contact;
  const [open, , toggleDialog] = useToggle();
  return (
    <>
      <ContactListItemInternals primary={fullName} secondary={email} onClick={toggleDialog} contactId={id} />
      <ContactTheContactDialog open={open} closeDialog={toggleDialog} contact={contact} />
    </>
  );
};

export const ContactListItemDialog: React.FC<{ contact: Contact }> = ({ contact }) => {
  const { id } = contact;
  const { fullName, email } = contact;

  const [contactDialogOpen, , toggleContactDialog] = useToggle();
  const [contactOpen, , toggleDialog] = useToggle();
  return (
    <>
      <ListItem divider button onClick={toggleDialog}>
        <ListItemText primary={fullName} secondary={email} />
        <ListItemSecondaryAction>
          <Button color={"primary"} variant={"outlined"} onClick={toggleContactDialog}>
            {"Contact"}
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
      <ContactTheContactDialog open={contactDialogOpen} closeDialog={toggleContactDialog} contact={contact} />
      <ContactDialog contactId={id} open={contactOpen} closeHandler={toggleDialog} />
    </>
  );
};
