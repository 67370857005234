import * as React from "react";
import { Dialog as CoreDailog, DialogContent, DialogTitle } from "@material-ui/core";

export type DialogProps = {
  title: string;
  open: boolean;
  onClose?: () => void;
};

export const Dialog: React.FC<DialogProps> = ({ title, children, open, onClose }) => {
  return (
    <CoreDailog open={open} onClose={onClose}>
      {open && (
        <>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{children}</DialogContent>
        </>
      )}
    </CoreDailog>
  );
};
