import * as React from "react";
import { FullScreenDialog } from "../FullScreenDialog";
import { Paper } from "@material-ui/core";
import { ItemForm } from "../Forms/ItemForm";

type AddNewItemDialogProps = {
  onSubmit: any;
  open: boolean;
  onClose: () => void;
};
export const AddNewItemDialog: React.FC<AddNewItemDialogProps> = ({ open, onClose, onSubmit }) => {
  return (
    <FullScreenDialog title={"Add item"} open={open} onClose={onClose}>
      <Paper>
        <ItemForm onCancel={onClose} onSubmit={onSubmit} />
      </Paper>
    </FullScreenDialog>
  );
};
