import { createTheme } from "@material-ui/core/styles";

export const darkTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#f04e23",
    },
    background: {
      default: "#000000",
    },
  },
});
