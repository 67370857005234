import React from "react";
import { ListItemText } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import { useAuth0 } from "@auth0/auth0-react";
import List from "@material-ui/core/List";
import { CalendarViewLayout } from "../components/Appointments/CalendarViewLayout";
import { openInNewTab } from "../lib/openInNewTab";

const MorePage = () => {
  const { logout } = useAuth0();
  return (
    <CalendarViewLayout>
      <List className="w-full">
        <ListItem
          divider
          button
          onClick={() => {
            openInNewTab("https://datastudio.google.com/reporting/b9d1e9aa-5bb4-4317-afd8-d1c498b31cad");
          }}
        >
          <ListItemText primary={"Payroll Tracker"} />
        </ListItem>
        {/* Keeping this code since it works correctly but there are no useful technician preferences right now. */}
        {/* <ListItem component={Link} to={"/preferences"} divider button>
          <ListItemText primary={"Preferences"} />
        </ListItem> */}
        <ListItem
          divider
          button
          onClick={async () =>
            await logout({
              returnTo: window.location.origin,
            })
          }
        >
          <ListItemText primary={"Log Out"} />
        </ListItem>
      </List>
    </CalendarViewLayout>
  );
};

export default MorePage;
