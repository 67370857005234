import * as React from "react";
import { ButtonListItem } from "./ButtonListItem";

export const ServiceLocationListItem: React.FC<{
  address: string;
  notes: string | undefined | null;
}> = ({ address, notes }) => {
  const mapUrl = `//maps.google.com/maps?daddr=${encodeURI(address)}`;
  return <ButtonListItem primary={address} secondary={notes} href={mapUrl} buttonText={"Map"} />;
};
