import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Mutation, MutationPerformActionArgs } from "../../generated/nest-graphql";
import { PERFORM_ACTION } from "../../graphql/mutations/performAction";
import { BaseJobActionButton } from "./BaseJobActionButton";
import Play from "@material-ui/icons/PlayCircleFilled";

export const StartJobButton: React.FC<{
  jobId: string;
}> = ({ jobId }) => {
  const [performAction, { loading }] = useMutation<Mutation, MutationPerformActionArgs>(PERFORM_ACTION);
  const [disabled, toggleDisabled] = useState(true);

  const handleAction = async () => {
    await performAction({
      variables: {
        performActionInput: {
          actionName: "startJob",
          entityId: jobId,
          entityName: "Job",
        },
      },
    });
  };

  // Start button is disabled for the first 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      toggleDisabled(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [disabled, toggleDisabled]);
  return (
    <BaseJobActionButton
      disabled={disabled}
      onClick={handleAction}
      icon={<Play />}
      loading={loading}
      buttonText={`Start Job`}
    />
  );
};
