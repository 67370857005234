import cloneDeep from "lodash.clonedeep";
import React, { createContext, Dispatch, Reducer, useContext, useReducer } from "react";
import { PreJobCheckListFormValues } from "../components/Jobs/PreJobCheckListDialog";

export const checkListItemDefault = { partCondition: "" };

export const preJobCheckListInitialState: PreJobCheckListFormValues = {
  frontPads: checkListItemDefault,
  frontPadsV2: {
    padThickness: {
      thickness: null,
      unavailable: null,
    },
    condition: "Good condition",
  },
  frontRotors: checkListItemDefault,
  rearPads: checkListItemDefault,
  rearPadsV2: {
    padThickness: {
      thickness: null,
      unavailable: null,
    },
    condition: "Good condition",
  },
  rearRotors: checkListItemDefault,
  brakeFluid: checkListItemDefault,
  brakeShoes: checkListItemDefault,
  brakeDrums: checkListItemDefault,
  calipers: checkListItemDefault,
  shocksStruts: checkListItemDefault,
  controlArms: checkListItemDefault,
  wheelHubs: checkListItemDefault,
  studs: checkListItemDefault,
  lugnuts: checkListItemDefault,
  sparkPlugs: checkListItemDefault,
  carBattery: checkListItemDefault,
  alternator: checkListItemDefault,
  engineAirFilter: checkListItemDefault,
  cabinAirFilter: checkListItemDefault,
  otherBrakeParts: checkListItemDefault,
  dashboardLights: checkListItemDefault,
  odometer: "",
  oilLife: {
    estimatedNextOilChangeTimeline: null,
    nextOilChangeMileage: {
      mileage: null,
      unavailable: null,
    },
    nextOilChangeDate: {
      date: null,
      unavailable: null,
    },
  },
  tireTread: {
    frontLeft: {
      depth: null,
      unavailable: null,
    },
    frontRight: {
      depth: null,
      unavailable: null,
    },
    rearLeft: {
      depth: null,
      unavailable: null,
    },
    rearRight: {
      depth: null,
      unavailable: null,
    },
  },
};

const InspectionReportStateContext = createContext<{
  state: PreJobCheckListFormValues;
  dispatch: Dispatch<any>;
}>({
  state: cloneDeep(preJobCheckListInitialState),
  dispatch: () => null,
});

export type DispatchAction = {
  type: string;
  payload: any;
};

const reducer: Reducer<PreJobCheckListFormValues, DispatchAction> = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "UPDATE_FULL_INSPECTION":
      return {
        ...state,
        ...payload,
      };
    default:
      throw new Error(`Unhandled Action: ${action}`);
  }
};

export const InspectionProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, cloneDeep(preJobCheckListInitialState));

  return (
    <InspectionReportStateContext.Provider value={{ state, dispatch }}>
      {children}
    </InspectionReportStateContext.Provider>
  );
};

export const useInspectionReport = () => useContext(InspectionReportStateContext);
export const useInspectionDispatch = () => {
  const { dispatch } = useContext(InspectionReportStateContext);
  return dispatch;
};
