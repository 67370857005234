import React from "react";
import { Contact } from "../../generated/nest-graphql";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import PhoneIcon from "@material-ui/icons/Phone";
import { ContactTheContactDialog } from "../ListItems/ContactTheContactDialog";
import { useToggle } from "../../hooks/useToggle";

export const ContactSection: React.FC<{ contact: Contact }> = ({ contact }) => {
  const { fullName } = contact;

  const [open, , toggleDialog] = useToggle();
  return (
    <Paper>
      <Box display={"flex"}>
        <Box className="py-2 px-2 w-1/2 flex flex-col justify-center">
          <Chip color={"primary"} label={fullName} />
        </Box>
        <Box className={"flex-1 flex flex-row justify-end"}>
          <Button className="w-1/2" variant={"contained"} color={"secondary"} onClick={toggleDialog}>
            <div>
              <PhoneIcon />
              <Typography display={"block"} variant={"caption"}>
                Contact
              </Typography>
            </div>
          </Button>
        </Box>
      </Box>
      <ContactTheContactDialog open={open} closeDialog={toggleDialog} contact={contact} />
    </Paper>
  );
};
