import * as React from "react";
import { BottomNav } from "./BottomNav";

export const Layout: React.FC<{ appBar: React.ReactNode }> = ({ appBar, children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      {appBar}
      <div className={"flex-grow"}>{children}</div>
      <div className={"sticky bottom-0 z-10"}>
        <BottomNav />
      </div>
    </div>
  );
};
