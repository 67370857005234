import gql from "graphql-tag";

export const technicianDetailsFields = `
      auth0Id
      email
      firstName
      id
      preferences {
        receiveSMSNotifications
      }
      homePartsStore {
        id,
        vendor,
        name
      }
      lastName
      market
      phoneNumber
      location {
        coordinates
        type
      }
      version
`;
export const TECHNICIAN_GET_ME = gql`
    query TechnicianGetMe {
        technicianGetMe {
            ${technicianDetailsFields}
        }
    }
`;
