import * as React from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useQuery } from "@apollo/client";
import { Query } from "../../generated/nest-graphql";
import { applySpec, map, pipe, prop } from "ramda";
import { GET_PRODUCTS } from "../../graphql/queries/getProducts";
import { ProductSelectTypeAhead } from "./fields/ProductSelectTypeAhead";
import { FormikTextField } from "./fields/FormikTextField";
import Container from "@material-ui/core/Container";
import CurrencyInput from "./fields/CurrencyInput";
import { SubmitButton } from "../Buttons/SubmitButton";
import { CancelButton } from "../Buttons/CancelButton";
import { RemoveButton } from "../Buttons/RemoveButton";

export type ItemFormValues = {
  product?: string;
  name: string;
  amount: string;
  laborCost: string;
  partsCost: string;
  isInEstimate?: boolean;
  partNumber?: string;
  description?: string;
};
const ItemFormValidationScheme = Yup.object().shape({
  name: Yup.string().required("Required"),
  amount: Yup.string().required("Required"),
  laborCost: Yup.string().required("Required"),
  partsCost: Yup.string().required("Required"),
  partNumber: Yup.string().nullable(),
  description: Yup.string().nullable(),
});
export const ItemForm: React.FC<{
  initialValues?: ItemFormValues;
  onSubmit: any;
  onCancel: () => void;
  onRemove?: () => void;
  submitLabel?: string;
}> = ({
  initialValues,
  onSubmit,
  onCancel,
  onRemove,
  submitLabel = "Add Item",
}) => {
  const { data } = useQuery<Query, {}>(GET_PRODUCTS);
  if (!data) return null;
  const options = pipe(
    prop("getProducts"),
    map(
      applySpec({
        label: prop("name"),
        value: prop("id"),
      })
    )
  )(data);
  return (
    <Formik<ItemFormValues>
      validationSchema={ItemFormValidationScheme}
      initialValues={
        initialValues
          ? initialValues
          : {
              product: null,
              name: "",
              amount: "",
              isInEstimate: false,
              description: "",
              laborCost: "",
              partNumber: "",
              partsCost: "",
            }
      }
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, isSubmitting, isValid }) => {
        return (
          <Form>
            <Container>
              <div className={"grid gap-4 my-5"}>
                <ProductSelectTypeAhead
                  required={true}
                  name={"product"}
                  label={"Product"}
                  options={options}
                  setFieldValue={setFieldValue}
                />
                <FormikTextField name={"name"} label={"Name"} required={true} />
                <CurrencyInput
                  name={"amount"}
                  label={"Amount"}
                  required={true}
                />
                <CurrencyInput
                  name={"partsCost"}
                  label={"Parts Cost"}
                  required={true}
                />
                <FormikTextField name={"partNumber"} label={"Part Number"} />
                <FormikTextField name={"description"} label={"Description"} />
                <SubmitButton
                  label={submitLabel}
                  isSubmitting={isSubmitting}
                  isValid={isValid}
                />
                {onRemove && <RemoveButton onClick={onRemove} />}
                <CancelButton onClick={onCancel} />
              </div>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};
