import { Dispatch } from "react";
import { DispatchAction } from "./contexts/snackbar-context";

export const showSuccessAlert = (dispatch: Dispatch<DispatchAction>, message: string) => {
  dispatch({
    type: "SHOW_SUCCESS",
    payload: {
      message,
    },
  });
};

export const showErrorAlert = (dispatch: Dispatch<DispatchAction>, message: string) => {
  dispatch({
    type: "SHOW_ERROR",
    payload: {
      message,
    },
  });
};

export const showWarningAlert = (dispatch: Dispatch<DispatchAction>, message: string) => {
  dispatch({
    type: "SHOW_WARNING",
    payload: {
      message,
    },
  });
};
