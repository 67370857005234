import React from "react";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import { JobActionButton } from "./JobActionButton";
import { GoToInvoiceButton } from "./GoToInvoiceButton";
import { UpdateInvoiceDetailsFormValues } from "../Forms/UpdateInvoiceDetailsForm";
import { equals, isEmpty, union } from "ramda";
import { preJobCheckListInitialState, useInspectionReport } from "../../contexts/inspection-context";
import cloneDeep from "lodash.clonedeep";
import { Contact } from "../../generated/nest-graphql";

// This merge function is designed to prefer non-default checklist values.
// All of Ramda's `merge` functions traverse through objects recursively,
// which is not the desired behavior in this case.
export const mergePreJobChecklistFunc = (currentValues, initialValues) => {
  if (isEmpty(currentValues)) return initialValues;
  if (isEmpty(initialValues)) return currentValues;

  let objectKeys = union(Object.keys(currentValues), Object.keys(initialValues));
  let expandoObject = Object();
  for (let objectKey of objectKeys) {
    if (!equals(currentValues[objectKey], preJobCheckListInitialState[objectKey])) {
      expandoObject[`${objectKey}`] = cloneDeep(currentValues[objectKey]);
    } else {
      expandoObject[`${objectKey}`] = cloneDeep(initialValues[objectKey]);
    }
  }
  return expandoObject;
};

export const JobStatusSection: React.FC<{
  message: string;
  phoneNumber: string;
  status: string;
  jobId: string;
  invoiceId?: string;
  addInvoiceInitialValues: UpdateInvoiceDetailsFormValues;
  contact: Contact;
}> = ({ status, jobId, invoiceId, addInvoiceInitialValues, message, phoneNumber, contact }) => {
  const { state } = useInspectionReport();
  return (
    <Paper square={true}>
      <Box display={"flex"}>
        <Box className="py-2 px-2 w-1/2 flex flex-col justify-center">
          <Chip color={"primary"} label={status} />
        </Box>
        <Box className={"flex-1 flex flex-row justify-end"}>
          <GoToInvoiceButton
            invoiceId={invoiceId}
            initialValues={addInvoiceInitialValues}
            jobId={jobId}
            status={status}
          />
          <JobActionButton
            jobId={jobId}
            jobStatus={status}
            phoneNumber={phoneNumber}
            message={message}
            invoiceId={invoiceId}
            preJobCheckListInitialValues={mergePreJobChecklistFunc(
              state,
              addInvoiceInitialValues?.preJobCheckList ?? {}
            )}
            contact={contact}
          />
        </Box>
      </Box>
    </Paper>
  );
};
