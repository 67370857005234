import * as React from "react";
import { Item } from "../../../generated/nest-graphql";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DeleteIcon from "@material-ui/icons/Delete";
import Box from "@material-ui/core/Box";
import Badge from "@material-ui/core/Badge";
import { ReactNode } from "react";

export const ItemFieldListItem: React.FC<{
  editable: boolean;
  item: Item;
  onSelectItem: any;
  removeItem: () => void;
  additionalPartsInfoRenderer: (item: Item) => ReactNode;
}> = ({ item, onSelectItem, removeItem, editable, additionalPartsInfoRenderer }) => {
  const { name, isInEstimate, amount, description, partNumber } = item;
  return (
    <ListItem button onClick={onSelectItem}>
      <ListItemText
        primary={
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography>{name}</Typography>
            {isInEstimate && (
              <Box marginRight={4}>
                <Badge badgeContent={"estimate"} color="primary" />
              </Box>
            )}
          </Box>
        }
        secondary={
          <>
            {partNumber && <Typography>Part No: {partNumber}</Typography>}
            <Typography component={"span"} className={"block"} variant={"subtitle1"}>
              {description}
            </Typography>

            {additionalPartsInfoRenderer?.(item)}
            <Typography className="flex flex-row justify-end" component={"span"} align={"right"}>
              ${amount}
            </Typography>
          </>
        }
      />
      {editable && (
        <ListItemSecondaryAction onClick={removeItem}>
          <IconButton edge="end" aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};
