import React from "react";
import { Box, FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from "@material-ui/core";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import ThumbDownOutlinedIcon from "@material-ui/icons/ThumbDownOutlined";

export type YesNoFieldProps = {
  name: string;
  onChange: any;
  yesLabel: string;
  noLabel: string;
  value?: boolean;
  required?: boolean;
  error?: boolean;
  errorText?: string;
};

export const YesNoField = ({
  name,
  onChange,
  yesLabel,
  noLabel,
  value = null,
  required = false,
  error = false,
  errorText = "",
}: YesNoFieldProps) => {
  return (
    <FormControl required={required} component="fieldset" error={error}>
      <RadioGroup name={name}>
        <Box className="flex">
          <Box m="auto">
            <FormControlLabel
              control={
                <Radio
                  checked={value === true}
                  required={required}
                  onChange={onChange}
                  value={true}
                  checkedIcon={<ThumbUpOutlinedIcon style={{ fontSize: 100, color: "#f04e23" }} />}
                  icon={<ThumbUpOutlinedIcon style={{ fontSize: 100 }} />}
                />
              }
              label={yesLabel}
              labelPlacement="bottom"
            />
          </Box>
          <Box m="auto">
            <FormControlLabel
              control={
                <Radio
                  checked={value === false}
                  required={required}
                  onChange={onChange}
                  value={false}
                  checkedIcon={<ThumbDownOutlinedIcon style={{ fontSize: 100, color: "#f04e23" }} />}
                  icon={<ThumbDownOutlinedIcon style={{ fontSize: 100 }} />}
                />
              }
              label={noLabel}
              labelPlacement="bottom"
            />
          </Box>
        </Box>
      </RadioGroup>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};
