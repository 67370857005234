import * as React from "react";
import { PreJobCheckListDialog, PreJobCheckListFormValues } from "./PreJobCheckListDialog";
import { useToggle } from "../../hooks/useToggle";
import { Button, Typography } from "@material-ui/core";
import InspectionIcon from "@material-ui/icons/Assignment";
import { Contact } from "../../generated/nest-graphql";

type InspectionButtonType = {
  jobId: string;
  invoiceId: string;
  preJobCheckListInitialValues: PreJobCheckListFormValues;
  contact: Contact;
};

export const OpenInspectionButton = ({
  jobId,
  invoiceId,
  preJobCheckListInitialValues,
  contact,
}: InspectionButtonType) => {
  const [inspectionOpen, , toggleInspection] = useToggle();
  return (
    !contact?.fleet && (
      <>
        <Button className="w-1/2" variant={"contained"} color={"secondary"} onClick={toggleInspection}>
          <div>
            <InspectionIcon />
            <Typography display={"block"} variant={"caption"}>
              Inspect Vehicle
            </Typography>
          </div>
        </Button>
        <PreJobCheckListDialog
          onClose={toggleInspection}
          jobId={jobId}
          invoiceId={invoiceId}
          open={inspectionOpen}
          initialValues={preJobCheckListInitialValues}
        />
      </>
    )
  );
};
