import gql from "graphql-tag";

export const contactDetailsFields = `id
      id
        firstName
        lastName
        fullName
        email
        phoneNumber
        createdAt
        updatedAt
        lead {
            id
            address
            hearAboutUs
            market
            source
            vehicleInfo {
              frontPadLife
                licensePlate
                make
                model
                odometer
                rearPadLife
                vin
                year
                extraInfo
                symptoms
                frontBrakeSymptoms
                rearBrakeSymptoms
                customerExpectation
                additionalNotes
            }
            zip
        }
        activityFeed {
            id
            actor
            objectId
            objectType
            createdAt
            verb
        }
        stripeCustomer
        stripePaymentMethods
        fleet {
            name
        }
        `;

export const GET_CONTACT_DETAILS = gql`query getContactDetails($id: ID!) {
    getContact(id: $id) {
        ${contactDetailsFields}
    }
}`;
