import * as React from "react";
import { useMutation } from "@apollo/client";
import {
  Mutation,
  MutationPerformActionArgs,
} from "../../generated/nest-graphql";
import { PERFORM_ACTION } from "../../graphql/mutations/performAction";
import { BaseJobActionButton } from "./BaseJobActionButton";
import SmsIcon from "@material-ui/icons/Sms";

export const TextOnWayButton: React.FC<{
  jobId: string;
  message: string;
  phoneNumber: string;
}> = ({ jobId, message, phoneNumber }) => {
  const [performAction, { loading }] = useMutation<
    Mutation,
    MutationPerformActionArgs
  >(PERFORM_ACTION);
  const onClick = async () => {
    await performAction({
      variables: {
        performActionInput: {
          actionName: "technicianSendOnWay",
          entityId: jobId,
          entityName: "Job",
        },
      },
    });
    window.location.href = `sms://${phoneNumber}/?&body=${encodeURI(message)}`;
  };
  return (
    <BaseJobActionButton
      onClick={onClick}
      icon={<SmsIcon />}
      loading={loading}
      buttonText={`Text On Way`}
    />
  );
};
