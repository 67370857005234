import * as React from "react";
import { Typography } from "@material-ui/core";
import { Item } from "../../../generated/nest-graphql";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import { mapIndexed } from "../../../lib/functions";

export type SelectableItem = {
  item: Item;
  isSelected: boolean;
  handleToggle: () => void;
};
export const ItemsSelector: React.FC<{ selectableItems: SelectableItem[] }> = ({ selectableItems }) => {
  return (
    <>
      <Typography>Items</Typography>
      <List>
        {mapIndexed((item: SelectableItem, index) => {
          return <ItemListItemSelector key={index} selectableItem={item} />;
        }, selectableItems)}
      </List>
    </>
  );
};

export const ItemListItemSelector: React.FC<{
  selectableItem: SelectableItem;
}> = ({ selectableItem }) => {
  const {
    item: { name, partNumber, amount, description },
    handleToggle,
    isSelected,
  } = selectableItem;
  return (
    <ListItem dense button divider onClick={handleToggle}>
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={isSelected}
          tabIndex={-1}
          disableRipple
          // inputProps={{ 'aria-labelledby': labelId }}
        />
      </ListItemIcon>
      <ListItemText
        primary={name}
        secondary={
          <>
            {partNumber && <Typography>Part No: {partNumber}</Typography>}
            <Typography component={"span"} className={"block"} variant={"subtitle1"}>
              {description}
            </Typography>
            <Typography className="flex flex-row justify-end" component={"span"} align={"right"}>
              ${amount}
            </Typography>
          </>
        }
      />
    </ListItem>
  );
};
