import * as React from "react";
import { useCallback, useState } from "react";
import { FullScreenDialog } from "../FullScreenDialog";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { BottomSubmitButtonRow } from "../Buttons/BottomSubmitButtonRow";
import { EJIServiceFormValue, JobDetailsFormValues } from "../Forms/JobDetailsForm";
import cloneDeep from "lodash.clonedeep";
import { head, isEmpty } from "ramda";
import { UpdateServiceDetailsFormSection } from "../Forms/UpdateServiceDetailsFormSection";
import { SelectServiceFormSection } from "../services/SelectServiceFormSection";
import { useFormikContext } from "formik";
import { useLazyQuery } from "@apollo/client";
import { PossibleEjiService, Query, QueryCalculatePossibleEjiPriceInfoArgs } from "../../generated/nest-graphql";
import { CALCULATE_POSSIBLE_EJI_PRICE_INFO } from "../../graphql/queries/calculatePossibleEjiPriceInfo";
import {
  formValuesToEJIDiscountInputs,
  formValuesToPossibleEJIServiceInputs,
  formValueToEJIPricingConfigInput,
} from "../../specs/ejiServicesSpec";
import _ from "lodash";
import { isEjiServiceFormValueValid } from "../Forms/UpdateServiceDetailsFormSection/isEjiServiceFormValueValid";
import { objectDiff } from "../../lib/functions";
import { AreYouSureDialog } from "../Items/AreYouSureDialog";
import { useToggle } from "../../hooks/useToggle";

const isEdited = (originalService: EJIServiceFormValue, newService: EJIServiceFormValue) => {
  const diff = objectDiff(newService, originalService);
  return !isEmpty(diff);
};

export const UpdateServiceDetailsDialog: React.FC<{
  service?: EJIServiceFormValue;
  serviceIdx?: any;
  parentSetFieldValue?: any;
  parentSubmitForm?: any;
  parentSubmitting?: boolean;
  open: any;
  toggleDialog: any;
  servicesList: PossibleEjiService[];
}> = ({
  service,
  serviceIdx,
  parentSetFieldValue,
  parentSubmitForm,
  parentSubmitting,
  open,
  toggleDialog,
  servicesList,
}) => {
  let initialServiceWithPriceLinkDefaults = cloneDeep(service);
  initialServiceWithPriceLinkDefaults?.items.forEach((item) => {
    item.overriddenCustomerPrice = !!item.id;
  });
  const [editedService, setEditedService] = useState({ ...initialServiceWithPriceLinkDefaults });
  const { values } = useFormikContext<JobDetailsFormValues>();
  const [areYouSureDialogOpen, , toggleAreYouSureDialog] = useToggle();

  const [calculatePossibleEjiPriceInfo] = useLazyQuery<Query, QueryCalculatePossibleEjiPriceInfoArgs>(
    CALCULATE_POSSIBLE_EJI_PRICE_INFO,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted: (priceData) => {
        if (!isEmpty(editedService)) {
          setEditedService({
            ...editedService,
            customerPrice: head(priceData.calculatePossibleEJIPriceInfo?.ejiServices)?.customerPrice,
          });
        }
      },
    }
  );

  const debouncedCalculatePossibleEjiPriceInfo = useCallback(
    _.debounce((service) => {
      calculatePossibleEjiPriceInfo({
        variables: {
          calculatePossibleEJIPriceInfoInput: {
            services: formValuesToPossibleEJIServiceInputs([service]),
            discounts: formValuesToEJIDiscountInputs(values.discounts),
            pricingConfig: formValueToEJIPricingConfigInput(values.priceInfo?.pricingConfig),
            marketName: values.market,
            taxable: values.taxable,
            calculateAllServices: false,
          },
        },
      });
    }, 250),
    []
  );

  const setEditedServiceAndRecalculatePrice = (service) => {
    setEditedService(service);
    debouncedCalculatePossibleEjiPriceInfo(service);
  };

  const checkVendorUnitCost = () => {
    // Special case to handle the price link checkbox's discrepancy with the item's `needsRecalculation` value.
    if (isEmpty(objectDiff(editedService, service))) {
      toggleDialog();
      return;
    }

    if (service === null) {
      editedService.needsRecalculation = true;
      values.services.push(editedService);
    } else {
      parentSetFieldValue(`services[${serviceIdx}]`, editedService);
      parentSetFieldValue(`services[${serviceIdx}].needsRecalculation`, true);
    }
    parentSubmitForm();
    toggleDialog();
  };

  return (
    <>
      <FullScreenDialog
        title={service === null ? "Add Service Details" : "Update Service Details"}
        open={open}
        onClose={() => {
          isEdited(initialServiceWithPriceLinkDefaults, editedService) ? toggleAreYouSureDialog() : toggleDialog();
        }}
      >
        <Paper className={"px-3"}>
          <Box marginBottom={2} paddingTop={4}>
            {isEmpty(editedService) ? (
              <SelectServiceFormSection
                setEditedServiceAndRecalculatePrice={setEditedServiceAndRecalculatePrice}
                servicesList={servicesList}
              />
            ) : (
              <UpdateServiceDetailsFormSection
                editedService={editedService}
                setEditedServiceAndRecalculatePrice={setEditedServiceAndRecalculatePrice}
                servicesList={servicesList}
              />
            )}
          </Box>
          <Box marginTop={2} paddingBottom={4}>
            <BottomSubmitButtonRow
              label={service === null ? "Add Service" : "Update Service"}
              isValid={
                isEjiServiceFormValueValid(editedService, servicesList) &&
                isEdited(initialServiceWithPriceLinkDefaults, editedService) &&
                !parentSubmitting
              }
              isSubmitting={parentSubmitting}
              handleSubmit={checkVendorUnitCost}
              pinToBottom={true}
            />
          </Box>
        </Paper>
      </FullScreenDialog>
      <AreYouSureDialog
        onNo={toggleAreYouSureDialog}
        onYes={() => {
          toggleAreYouSureDialog();
          toggleDialog();
        }}
        title={"Are you sure you want to discard your changes?"}
        open={areYouSureDialogOpen}
        onClose={toggleAreYouSureDialog}
      />
    </>
  );
};
