import * as React from "react";
import { Button } from "@material-ui/core";

export const CancelButton: React.FC<{
  onClick: () => void;
  variant?: "contained" | "text" | "outlined";
  color?: any;
}> = ({ onClick, variant = "contained", color = "secondary" }) => {
  return (
    <Button fullWidth={true} color={color} onClick={onClick} variant={variant}>
      Cancel
    </Button>
  );
};
