import * as React from "react";
import { Button, ThemeProvider } from "@material-ui/core";
import Description from "@material-ui/icons/Description";
import Typography from "@material-ui/core/Typography";
import { createTheme } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import { useHistory } from "react-router-dom";
import { AddInvoiceDialog } from "../Invoices/AddInvoiceDialog";
import { UpdateInvoiceDetailsFormValues } from "../Forms/UpdateInvoiceDetailsForm";
import { useToggle } from "../../hooks/useToggle";
import { includes } from "ramda";

const theme = createTheme({
  palette: {
    primary: green,
  },
});
export const GoToInvoiceButton: React.FC<{
  invoiceId?: string;
  jobId: string;
  initialValues: UpdateInvoiceDetailsFormValues;
  status: string;
}> = ({ invoiceId, initialValues, jobId, status }) => {
  const history = useHistory();
  const [open, , toggle] = useToggle();

  const handleInvoice = () => {
    if (invoiceId) {
      history.push(`/invoices/${invoiceId}`);
    } else {
      toggle();
    }
  };
  const buttonText = invoiceId ? "View Invoice" : "Create Invoice";
  return (
    <>
      <ThemeProvider theme={theme}>
        <Button
          className="w-1/2"
          variant={"contained"}
          color={"primary"}
          onClick={handleInvoice}
          disabled={includes(status, ["Open: En Route", "Draft", "New"])}
        >
          <div>
            <Description />
            <Typography display={"block"} variant={"caption"}>
              {buttonText}
            </Typography>
          </div>
        </Button>
      </ThemeProvider>
      <AddInvoiceDialog initialValues={initialValues} open={open} onClose={toggle} jobId={jobId} />
    </>
  );
};
