import * as Sentry from "@sentry/browser";
import { ApolloClient, ApolloLink, createHttpLink, defaultDataIdFromObject, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { setContext } from "@apollo/client/link/context";

export default function createApolloClient(getToken: any) {
  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_BACKEND_GRAPHQL_ENDPOINT}`, // Server URL (must be absolute)
  });
  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      Sentry.captureException(new Error("GraphQL Request Error"), {
        extra: {
          errorAsString: graphQLErrors.map(
            ({ message, locations, path }) =>
              `Operation Name: ${
                operation?.operationName
              } -- [GraphQL Error]: Message: ${message}, Locations: ${JSON.stringify(
                locations
              )}, Path: ${path} -- Operation Query: ${JSON.stringify(
                operation?.query
              )} -- Operation Variables ${JSON.stringify(operation?.variables)}`
          ),
        },
      });
    }
    if (networkError) {
      Sentry.captureException(new Error("GraphQL Network Error"), {
        extra: {
          errorAsString: `Operation Name: ${
            operation?.operationName
          } -- [Network error]: ${networkError} -- Operation Query: ${JSON.stringify(
            operation?.query
          )} -- Operation Variables ${JSON.stringify(operation?.variables)}`,
        },
      });
    }
  });
  const retryLink = new RetryLink();
  const authLink = setContext(async (_, { headers }) => {
    const token = await getToken();
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
        "req-sent-from": "techWebApp",
      },
    };
  });
  return new ApolloClient({
    link: ApolloLink.from([errorLink, retryLink, authLink, httpLink]),
    connectToDevTools: true,
    cache: new InMemoryCache({
      possibleTypes: {
        CustomerGetInvoiceResult: ["InvoicePaidResult", "CustomerInvoice"],
        PerformActionResult: ["Contact", "Estimate", "Job", "Invoice"],
      },
      dataIdFromObject: (object) => defaultDataIdFromObject(object),
    }),
  });
}
