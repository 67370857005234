import * as React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { ReceiptViewLineItem, DiscountLineItem, PromoLineItem } from "./ReceiptViewLineItem";
import { ReceiptVewSummaryItem } from "./ReceiptVewSummaryItem";
import { PromoCode } from "../../generated/nest-graphql";

export type Discount = {
  createdAt?: any;
  editedAt?: any;
  creator?: any;
  type: string;
  reason: string;
  amount: string;
};

export const ReceiptView: React.FC<{
  subtotal: string;
  total: string;
  tax: string;
  parts: string;
  labor: string;
  fees?: string;
  discounts?: Discount[];
  promoCodes?: PromoCode[];
}> = ({ subtotal, tax, total, parts, labor, fees, discounts, promoCodes }) => {
  return (
    <Card raised={true}>
      <CardContent>
        <ReceiptViewLineItem label={"Subtotal"} value={`$${subtotal}`} />
        <ReceiptViewLineItem subItem label={"Parts"} value={`$${parts}`} />
        <ReceiptViewLineItem subItem label={"Labor"} value={`$${labor}`} />
        {fees && fees !== "0.00" && <ReceiptViewLineItem subItem label={"Fees"} value={`$${fees}`} />}
        {promoCodes &&
          promoCodes.map((promoCode, idx) => {
            const {
              code,
              coupon: { discount },
            } = promoCode;

            let label = discount.type === "%" ? `${code.toUpperCase()}(${discount.amount}%)` : `${code.toUpperCase()}`;

            return <PromoLineItem subItem label={label} value={`$${discount.amount}`} reasonCode={discount.reason} />;
          })}
        {discounts &&
          discounts.map(({ amount, type, reason }) => {
            let label = type === "%" ? `Discount (${amount}%)` : "Discount";

            return <DiscountLineItem subItem label={label} value={`${amount}`} reasonCode={reason} />;
          })}
        <ReceiptViewLineItem label={"Tax"} value={`$${tax}`} />
        <ReceiptVewSummaryItem label={"Total"} value={`$${total}`} />
      </CardContent>
    </Card>
  );
};
