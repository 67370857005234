import { applySpec, prop } from "ramda";

export const contactDetailsSpec = (formData) =>
  applySpec({
    email: prop("email"),
    lead: {
      zip: prop("zip"),
      address: prop("address"),
      vehicleInfo: {
        frontPadLife: prop("frontPadLife"),
        licensePlate: prop("licensePlate"),
        make: prop("make"),
        model: prop("model"),
        odometer: prop("odometer"),
        rearPadLife: prop("rearPadLife"),
        vin: prop("vin"),
        year: prop("year"),
        extraInfo: prop("extraInfo"),
        symptoms: prop("symptoms"),
        frontBrakeSymptoms: prop("frontBrakeSymptoms"),
        rearBrakeSymptoms: prop("rearBrakeSymptoms"),
        additionalNotes: prop("additionalNotes"),
      },
    },
  })(formData);
