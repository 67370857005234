import { keys, pathOr, pipe, pluck, propOr } from "ramda";

import { Layout } from "../../components/Layout";
import Paper from "@material-ui/core/Paper";
import {
  Mutation,
  MutationUpdateContactArgs,
  Query,
  QueryGetContactArgs,
  QueryGetFilesArgs,
} from "../../generated/nest-graphql";
import { GET_CONTACT_DETAILS } from "../../graphql/queries/getContactDetails";
import { UPDATE_CONTACT } from "../../graphql/mutations/updateContact";
import { useMutation, useQuery } from "@apollo/client";
import { cleanObject, objectDiff } from "../../lib/functions";
import React from "react";
import { ContactDetailsAppBar } from "../../components/Contacts/ContactDetailsAppBar";
import { contactDetailsSpec } from "../../components/Contacts/contactDetailsSpec";
import { ContactDetailsForm, ContactDetailsFormValues } from "../../components/Forms/ContactDetailsForm";
import { GET_FILES } from "../../graphql/queries/getFiles";
import { useEntityId } from "../../hooks/useEntityId";
import { showSuccessAlert } from "../../actions";
import { useDispatch } from "../../contexts/snackbar-context";

const ContactSummaryPage = () => {
  const contactId = useEntityId();
  const { data: getFilesData } = useQuery<Query, QueryGetFilesArgs>(GET_FILES, {
    variables: {
      contactId,
    },
    returnPartialData: true,
  });
  const dispatch = useDispatch();
  const files = propOr([], "getFiles", getFilesData);
  const { data } = useQuery<Query, QueryGetContactArgs>(GET_CONTACT_DETAILS, {
    variables: {
      id: contactId,
    },
  });
  const [updateContact] = useMutation<Mutation, MutationUpdateContactArgs>(UPDATE_CONTACT);

  if (!data || !getFilesData) return null;
  const contact = data.getContact;
  const initialValues: ContactDetailsFormValues = {
    email: propOr("", "email", contact),
    zip: pathOr("", ["lead", "zip"], contact),
    address: pathOr("", ["lead", "address"], contact),
    make: pathOr("", ["lead", "vehicleInfo", "make"], contact),
    model: pathOr("", ["lead", "vehicleInfo", "model"], contact),
    year: pathOr("", ["lead", "vehicleInfo", "year"], contact),
    licensePlate: pathOr("", ["lead", "vehicleInfo", "licensePlate"], contact),
    vin: pathOr("", ["lead", "vehicleInfo", "vin"], contact),
    odometer: pathOr("", ["lead", "vehicleInfo", "odometer"], contact),
    frontPadLife: pathOr("", ["lead", "vehicleInfo", "frontPadLife"], contact),
    rearPadLife: pathOr("", ["lead", "vehicleInfo", "rearPadLife"], contact),
    extraInfo: pathOr("", ["lead", "vehicleInfo", "extraInfo"], contact),
    symptoms: pathOr("", ["lead", "vehicleInfo", "symptoms"], contact),
    frontBrakeSymptoms: pathOr([], ["lead", "vehicleInfo", "frontBrakeSymptoms"], contact),
    rearBrakeSymptoms: pathOr([], ["lead", "vehicleInfo", "rearBrakeSymptoms"], contact),
    additionalNotes: pathOr("", ["lead", "vehicleInfo", "additionalNotes"], contact),
    customerExpectation: pathOr("", ["lead", "vehicleInfo", "customerExpectation"], contact),
  };

  const onSubmit = async (values: ContactDetailsFormValues, _formikHelkpers) => {
    const diff = objectDiff(values, initialValues);

    // @ts-ignore
    const updates = pipe(contactDetailsSpec, cleanObject)(diff);
    if (keys(updates).length) {
      await updateContact({
        variables: {
          id: contactId,
          updateContactInput: updates,
        },
      });
      showSuccessAlert(dispatch, "Success");
    }
  };

  return (
    <Layout appBar={<ContactDetailsAppBar contact={contact} />}>
      <Paper>
        <ContactDetailsForm
          contact={contact}
          contactId={contactId}
          // @ts-ignore
          fileNames={pluck("fileName", files)}
          initialValues={initialValues}
          onSubmit={onSubmit}
        />
      </Paper>
    </Layout>
  );
};

export default ContactSummaryPage;
