import { useMutation, useQuery } from "@apollo/client";
import {
  Mutation,
  MutationUpdateJobArgs,
  PartsOrderingIssuesSurvey,
  PartsStore,
  Query,
} from "../../generated/nest-graphql";
import { UPDATE_JOB } from "../../graphql/mutations/updateJob";
import React from "react";
import { pipe } from "fp-ts/lib/function";
import { showSuccessAlert } from "../../actions";
import { useDispatch } from "../../contexts/snackbar-context";
import { isEmpty, keys } from "ramda";
import { PartsOrderingSurveyForm } from "./Surveys/PartsOrderingSurveyForm";
import { updateJobDetailsSpec } from "../Jobs/jobDetailsSpec";
import { usePartsOrderingIssuesSurvey } from "../../contexts/parts-ordering-issues-survey-context";
import { cleanObject, exists } from "../../lib/functions";
import { JobDetailsFormValues } from "./JobDetailsForm";
import { TECHNICIAN_GET_ME } from "../../graphql/queries/technicianGetMe";
import { useHistory } from "react-router-dom";

export const mergePartsOrderingIssueSurveyFunc = (left, right) => {
  if (exists(left)) {
    return left;
  }
  return right;
};

export type PartsOrderingSurveyFormValues = {};

export const PartsOrderingIssuesSurveyDialog = ({
  jobId,
  existingSurveys,
  open,
  onClose,
  initialValues,
  partsStores = [],
  jobDone = false,
}: {
  jobId: string;
  existingSurveys: PartsOrderingIssuesSurvey[];
  open: boolean;
  onClose?: () => void;
  initialValues: PartsOrderingSurveyFormValues;
  partsStores: PartsStore[];
  jobDone?: boolean;
}) => {
  const { data: technicianGetMeData } = useQuery<Query>(TECHNICIAN_GET_ME);
  const history = useHistory();
  const dispatch = useDispatch();
  const { dispatch: partsOrderingDispatch } = usePartsOrderingIssuesSurvey();
  const [updateJob] = useMutation<Mutation, MutationUpdateJobArgs>(UPDATE_JOB);
  const updateOnClose = (values: PartsOrderingSurveyFormValues) => {
    partsOrderingDispatch({ type: "UPDATE_PARTS_SURVEY", payload: values });
    onClose();
  };
  const onSubmit = async (values: PartsOrderingSurveyFormValues) => {
    partsOrderingDispatch({ type: "UPDATE_PARTS_SURVEY", payload: values });
    let surveys: PartsOrderingSurveyFormValues[] = [...existingSurveys];
    const submittedBy =
      technicianGetMeData?.technicianGetMe?.firstName + " " + technicianGetMeData?.technicianGetMe?.lastName;
    if (isEmpty(existingSurveys)) {
      // if there is no survey or the last survey is completed, create a new survey
      const survey = {
        ...values,
        completed: true,
        submittedBy,
      };
      surveys = [...surveys, survey];
    } else {
      // update the last survey
      surveys.pop();
      surveys = [
        ...surveys,
        {
          ...values,
          completed: true,
          submittedBy,
        },
      ];
    }

    const updates = pipe(
      { partsOrderingIssuesSurveys: surveys as PartsOrderingSurveyFormValues[] } as JobDetailsFormValues,
      updateJobDetailsSpec,
      cleanObject
    );
    if (keys(updates).length) {
      await updateJob({
        variables: {
          id: jobId,
          updateJobInput: updates,
        },
      });
      showSuccessAlert(dispatch, "Thanks for your feedback");
      if (jobDone) {
        history.push("/");
      } else {
        onClose();
      }
    }
  };
  return (
    <PartsOrderingSurveyForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      partsStores={partsStores}
      open={open}
      onClose={onClose ? updateOnClose : null}
    />
  );
};
