import * as React from "react";
import { FullScreenDialog } from "../FullScreenDialog";
import { Paper } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { AddPaymentForm } from "../Forms/AddPaymentForm";
import { Contact } from "../../generated/nest-graphql";

export const AddPaymentsDialog: React.FC<{
  amount: string;
  balanceDue: string;
  invoiceId: string;
  contactId: string;
  laborCost: string;
  partsCost: string;
  partsTax: string;
  laborTax: string;
  payer: string;
  totalTax: string;
  subTotal: string;
  open: boolean;
  onClose: () => void;
  refetch: any;
  jobId: string;
  preJobCheckListCreated: boolean;
  contact: Contact;
  amountPaid: string;
}> = ({
  laborCost,
  partsCost,
  partsTax,
  laborTax,
  subTotal,
  totalTax,
  amount,
  balanceDue,
  payer,
  invoiceId,
  contactId,
  open,
  onClose,
  refetch,
  jobId,
  preJobCheckListCreated,
  contact,
  amountPaid,
}) => {
  return (
    <FullScreenDialog
      title={"Add Payment"}
      open={open}
      onClose={async () => {
        //refetch invoice data so that in case of partial payment the appropriate values are reflected
        await refetch({
          id: invoiceId,
        });
        onClose();
      }}
    >
      <Paper>
        <Container className={"py-4"}>
          <AddPaymentForm
            laborCost={laborCost}
            partsCost={partsCost}
            partsTax={partsTax}
            payer={payer}
            laborTax={laborTax}
            subTotal={subTotal}
            totalTax={totalTax}
            amount={amount}
            balanceDue={balanceDue}
            invoiceId={invoiceId}
            contactId={contactId}
            refetch={refetch}
            onClose={onClose}
            jobId={jobId}
            preJobCheckListCreated={preJobCheckListCreated}
            contact={contact}
            amountPaid={amountPaid}
          />
        </Container>
      </Paper>
    </FullScreenDialog>
  );
};
