import React, { useState } from "react";
import { Form, Formik } from "formik";
import { applySpec, find, map, path, pick, pipe, prop, propEq, propOr } from "ramda";
import { useMutation, useQuery } from "@apollo/client";
import {
  Contact,
  Mutation,
  MutationCreatePaymentArgs,
  MutationCreatePaymentIntentArgs,
  Query,
  QueryGetContactArgs,
} from "../../generated/nest-graphql";
import * as Yup from "yup";
import { CREATE_PAYMENT } from "../../graphql/mutations/createPayment";
import { showErrorAlert, showSuccessAlert } from "../../actions";
import { useDispatch } from "../../contexts/snackbar-context";
import { Button, Link } from "@material-ui/core";
import { useInspectionReport } from "../../contexts/inspection-context";
import { useToggle } from "../../hooks/useToggle";
import { PreJobCheckListDialog } from "../Jobs/PreJobCheckListDialog";
import { CancelButton } from "../Buttons/CancelButton";
import { SubmitButton } from "../Buttons/SubmitButton";
import { CheckboxField } from "./fields/CheckboxField";
import currency from "currency.js";
import { GET_CONTACT_DETAILS } from "../../graphql/queries/getContactDetails";
import { AddPaymentOptionsDialog } from "../Payments/AddPaymentOptionsDialog";
import { CREATE_CONFIRMED_PAYMENT_INTENT } from "../../graphql/mutations/createConfirmedPaymentIntent";
import { hashIdempotencyKey } from "../../lib/functions";
import * as Sentry from "@sentry/react";
import PaymentMethodsForm from "./PaymentMethodsForm";

type PaymentValues = {
  paymentMethod: string;
  type: string;
  receivedDate: Date;
  amount: string;
  refNumber?: string;
  memo?: string;
  payer: string;
  source: string;
  paymentIntentId?: string;
};

type PaymentMethodCollection = {
  label: string;
  value: string;
  default: boolean;
  paymentMethod: any;
};

export type AddPaymentFormValues = {
  payments: PaymentValues[];
  sendReceipt: boolean;
};

const validationSchema = Yup.object().shape({
  payments: Yup.array().of(
    Yup.object().shape({
      payer: Yup.string().required("Required").min(1),
      amount: Yup.string().required("Required").min(1).notOneOf(["0.00"], "Amount must be greater than 0"),
      paymentMethod: Yup.string().required("Required").min(1),
    })
  ),
});

export const AddPaymentForm: React.FC<{
  amount: string;
  balanceDue: string;
  invoiceId: string;
  contactId: string;
  refetch: any;
  payer: string;
  laborCost: string;
  partsCost: string;
  partsTax: string;
  laborTax: string;
  totalTax: string;
  subTotal: string;
  onClose: () => void;
  jobId: string;
  preJobCheckListCreated: boolean;
  contact: Contact;
  amountPaid: string;
}> = ({
  amount,
  balanceDue,
  invoiceId,
  contactId,
  refetch,
  onClose,
  laborCost,
  partsCost,
  payer,
  partsTax,
  laborTax,
  totalTax,
  subTotal,
  jobId,
  preJobCheckListCreated,
  contact,
  amountPaid,
}) => {
  const isPartiallyPaid = Number(amountPaid) > 0; //rather than passing a status just check if the amount paid is greater than 0
  const [successfulPayments, setSuccessfulPayments] = useState<number[]>([]);
  const { data, refetch: contactRefetch } = useQuery<Query, QueryGetContactArgs>(GET_CONTACT_DETAILS, {
    variables: {
      id: contactId,
    },
  });
  const contactData = data?.getContact ?? contact;
  const paymentMethods: PaymentMethodCollection[] = pipe(
    prop("stripePaymentMethods"),
    map(
      applySpec({
        label: ({ card }) => `${prop("brand", card)} ${prop("last4", card)}`,
        value: prop<any, string>("id"),
        default: prop<any, boolean>("default"),
        paymentMethod: (pm) => pm,
      })
    )
  )(contactData as any);
  const cardOptions = map(pick(["label", "value"]))(paymentMethods);
  const [createPayment] = useMutation<Mutation, MutationCreatePaymentArgs>(CREATE_PAYMENT);
  const dispatch = useDispatch();
  const initialValues = {
    payments: [
      {
        laborCost,
        partsCost,
        partsTax,
        laborTax,
        totalTax,
        subTotal,
        source: "",
        paymentMethod: "",
        type: "Invoice",
        amount: balanceDue,
        receivedDate: new Date(),
        payer,
      },
    ],
    sendReceipt: true,
  };
  const { state } = useInspectionReport();
  const [inspectionOpen, , toggleInspection] = useToggle();
  const [addNewCardState, setAddNewCardState] = useState({ showDialog: false, setPaymentMethod: null });
  const [createConfirmedPayment] = useMutation<Mutation, MutationCreatePaymentIntentArgs>(
    CREATE_CONFIRMED_PAYMENT_INTENT
  );

  const cleanedAmount = (val) => (val.toString().includes(".") ? val.toString() : val + ".00");
  const onSubmit = async (values: PaymentValues[], sendReceipt: boolean, setSubmitting) => {
    async function tryCardPayment(payment: PaymentValues, idx, res, rej) {
      const paymentMethodId = prop("paymentMethod", payment);
      const { amount: paymentAmount } = payment;
      const percentOfTotalAmount = Number(paymentAmount) / Number(cleanedAmount(balanceDue));
      const toPercentOfTotalAmount = (val) => cleanedAmount(currency(val).multiply(percentOfTotalAmount));
      const paymentMethod = pipe(find(propEq("value", paymentMethodId)) as any, prop("paymentMethod"))(paymentMethods);
      try {
        //hashing on the last two so that someone can use a card a second time if their other method failed
        const idempotencyKey = await hashIdempotencyKey(`onFile:${invoiceId}:${paymentMethodId}:${idx}:${balanceDue}`);
        const result = await createConfirmedPayment({
          variables: {
            createPaymentInput: {
              type: "Invoice",
              paymentMethod: "Credit Card",
              status: "processing",
              invoice: invoiceId,
              laborCost: toPercentOfTotalAmount(laborCost),
              partsCost: toPercentOfTotalAmount(partsCost),
              partsTax: toPercentOfTotalAmount(partsTax),
              laborTax: toPercentOfTotalAmount(laborTax),
              totalTax: toPercentOfTotalAmount(totalTax),
              subTotal: toPercentOfTotalAmount(subTotal),
              contact: contact.id,
              customer: path(["stripeCustomer", "id"], contact),
              stripePaymentMethod: paymentMethod["id"],
              amount: paymentAmount,
              invoicePrice: paymentAmount,
              payer: `${contact.firstName} ${contact.lastName}`,
              sendReceipt,
              source: "stored stripe payment methods",
              receivedDate: new Date(),
            },
            idempotencyKey,
          },
        });
        const status = await path(["data", "createConfirmedPaymentIntent", "status"], result);
        if (status === "succeeded") {
          return res(idx);
        } else {
          return rej(idx);
        }
      } catch (e) {
        Sentry.captureException(propOr("", "message", e));
        rej(e);
      }
    }

    const tryCashCheckPayment = async (payment: PaymentValues, idx: number, res, rej) => {
      const { amount: paymentAmount } = payment;
      const percentOfTotalAmount = Number(paymentAmount) / Number(cleanedAmount(balanceDue));
      const toPercentOfTotalAmount = (val) => cleanedAmount(currency(val).multiply(percentOfTotalAmount));
      try {
        await createPayment({
          variables: {
            createPaymentInput: {
              paymentIntentId: payment?.paymentIntentId ?? "",
              amount: cleanedAmount(paymentAmount),
              invoicePrice: cleanedAmount(paymentAmount),
              payer: payer || "testPayer",
              paymentMethod: payment?.paymentMethod,
              receivedDate: payment?.receivedDate ?? null,
              laborCost: toPercentOfTotalAmount(laborCost),
              partsCost: toPercentOfTotalAmount(partsCost),
              partsTax: toPercentOfTotalAmount(partsTax),
              laborTax: toPercentOfTotalAmount(laborTax),
              totalTax: toPercentOfTotalAmount(totalTax),
              subTotal: toPercentOfTotalAmount(subTotal),
              type: payment.type,
              status: "succeeded",
              source: payment?.source ?? "",
              invoice: invoiceId,
              contact: contactId,
              memo: payment?.memo ?? "",
              refNumber: payment?.refNumber ?? "",
              sendReceipt,
            },
          },
        });
        res(idx);
      } catch (e) {
        Sentry.captureException(propOr("", "message", e));
        return rej(idx);
      }
    };

    const onSuccessfulFinish = async () => {
      showSuccessAlert(dispatch, "Payment Charge Success");
      await refetch({
        id: invoiceId,
      });
      setSubmitting(false);
      onClose();
    };

    const failedPayments = [];
    let numSuccessfulPayments = successfulPayments.length;

    //chaining payments back to back to ensure they occur in series so that balance due/amount paid is correct in the backend
    return new Promise((res, rej) => {
      const idx = 0;
      const payment = values[idx];
      //skip over payment if it has already succeeded
      if (!successfulPayments.includes(idx)) {
        if (payment.paymentMethod === "Cash" || payment.paymentMethod === "Check") {
          tryCashCheckPayment(payment, idx, res, rej);
        } else {
          tryCardPayment(payment, idx, res, rej);
        }
      } else {
        res(null);
      }
    })
      .then(() => {
        //skip over marking payment as successful if it has already succeeded
        if (!successfulPayments.includes(0)) {
          numSuccessfulPayments++;
          setSuccessfulPayments([...successfulPayments, 0]);
        }
      })
      .catch((result) => {
        Sentry.captureException(propOr("", "message", result));
        failedPayments.push(1);
      })
      .finally(async () => {
        //if these are equal, the amount paid will add up to the amount due per logic that disables things
        if (numSuccessfulPayments === values.length) {
          await onSuccessfulFinish();
          return;
        }
        return new Promise((res, rej) => {
          const idx = 1;
          const payment = values[idx];
          if (!successfulPayments.includes(idx)) {
            if (payment.paymentMethod === "Cash" || payment.paymentMethod === "Check") {
              tryCashCheckPayment(payment, idx, res, rej);
            } else {
              tryCardPayment(payment, idx, res, rej);
            }
          } else {
            //skip over if payment has already succeeded
            res(null);
          }
        })
          .then(() => {
            if (!successfulPayments.includes(1)) {
              setSuccessfulPayments([...successfulPayments, 1]);
            }
          })
          .catch((result) => {
            Sentry.captureException(propOr("", "message", result));
            failedPayments.push(2);
          })
          .finally(async () => {
            if (failedPayments.length === 0) {
              await onSuccessfulFinish();
              return;
            } else {
              setSubmitting(false);
              showErrorAlert(dispatch, `Payment ${failedPayments.join(" & ")} Charge Failed`);
            }
          });
      });
  };
  return (
    <>
      {!preJobCheckListCreated && !contactData?.fleet && (
        <div className={"pb-8"}>
          <div className={"pb-2"}>You must finish the inspection in order to collect payment.</div>
          <Button
            size={"large"}
            variant={"contained"}
            color={"primary"}
            onClick={toggleInspection}
            className={"w-full"}
          >
            {"Complete Inspection"}
          </Button>
        </div>
      )}
      <PreJobCheckListDialog
        onClose={toggleInspection}
        jobId={jobId}
        invoiceId={invoiceId}
        open={inspectionOpen}
        initialValues={state}
      />
      <Formik<AddPaymentFormValues>
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(val, { setSubmitting }) => {
          onSubmit(val.payments, val.sendReceipt, setSubmitting);
        }}
      >
        {({ values, isSubmitting, isValid, resetForm, dirty, submitCount, handleSubmit }) => {
          const amountSum = values.payments.reduce((acc, val) => acc.add(currency(val.amount)), currency(0));
          const amountDifference = amountSum.subtract(currency(balanceDue)); //negative if overcharge, positive if undercharge
          const amountsSumToAmountOwed = amountSum?.value === currency(balanceDue)?.value; //do the amounts add up correctly to the amount owed
          const amountReadOnly = values.payments.length < 2 || (submitCount > 0 && successfulPayments.length > 0); //amount field should be read only in 1 pass 1 fail situation
          return (
            <Form>
              <div className={`flex justify-between ${!isPartiallyPaid && "pb-4"}`}>
                <div>
                  Amount due{isPartiallyPaid && " initially"}: ${amount}
                </div>
                <Link href="#" onClick={() => contactRefetch()} className="!no-underline">
                  Refresh Cards
                </Link>
              </div>
              {isPartiallyPaid && (
                <div className="pb-4">
                  <div>Amount paid: ${amountPaid}</div>
                  <div>Remaining balance due: ${balanceDue}</div>
                </div>
              )}
              <PaymentMethodsForm
                payments={values.payments}
                balanceDue={balanceDue}
                amountDifference={amountDifference}
                amountReadOnly={amountReadOnly}
                setAddNewCardState={setAddNewCardState}
                successfulPayments={successfulPayments}
                cardOptions={cardOptions}
                isSubmitting={isSubmitting}
                submitCount={submitCount}
                initialPaymentValues={initialValues.payments[0]}
              />
              <AddPaymentOptionsDialog
                contact={contactData}
                open={addNewCardState.showDialog}
                onClose={() => setAddNewCardState({ showDialog: false, setPaymentMethod: null })}
                setPaymentMethod={(paymentMethodId: string) => addNewCardState.setPaymentMethod(paymentMethodId)}
              />
              <div>
                <CheckboxField name={"sendReceipt"} label={"Send Receipt?"} />
                <div className="mb-2">
                  <CancelButton onClick={() => resetForm()} />
                </div>
                <SubmitButton
                  isSubmitting={isSubmitting}
                  isValid={
                    isValid && dirty && (preJobCheckListCreated || !!contactData?.fleet) && amountsSumToAmountOwed
                  }
                  handleSubmit={handleSubmit}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
