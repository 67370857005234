import * as React from "react";
import { keys, pathOr, pipe, pluck, propOr } from "ramda";
import { useMutation, useQuery } from "@apollo/client";
import {
  Mutation,
  MutationUpdateContactArgs,
  Query,
  QueryGetContactArgs,
  QueryGetFilesArgs,
} from "../../generated/nest-graphql";
import { GET_FILES } from "../../graphql/queries/getFiles";
import { GET_CONTACT_DETAILS } from "../../graphql/queries/getContactDetails";
import { UPDATE_CONTACT } from "../../graphql/mutations/updateContact";
import { ContactDetailsForm, ContactDetailsFormValues } from "../Forms/ContactDetailsForm";
import { cleanObject, objectDiff } from "../../lib/functions";
import { contactDetailsSpec } from "./contactDetailsSpec";
import { FullScreenDialog } from "../FullScreenDialog";
import { Paper } from "@material-ui/core";
import { showSuccessAlert } from "../../actions";
import { useDispatch } from "../../contexts/snackbar-context";

export const ContactDialog: React.FC<{
  contactId: string;
  open: boolean;
  closeHandler: any;
}> = ({ contactId, open, closeHandler }) => {
  const { data: getFilesData } = useQuery<Query, QueryGetFilesArgs>(GET_FILES, {
    variables: {
      contactId,
    },
    returnPartialData: true,
  });
  const files = propOr([], "getFiles", getFilesData);
  const { data } = useQuery<Query, QueryGetContactArgs>(GET_CONTACT_DETAILS, {
    variables: {
      id: contactId,
    },
  });
  const [updateContact] = useMutation<Mutation, MutationUpdateContactArgs>(UPDATE_CONTACT);
  const dispatch = useDispatch();
  if (!data || !getFilesData) return null;
  const contact = data.getContact;
  const initialValues: ContactDetailsFormValues = {
    email: propOr("", "email", contact),
    zip: pathOr("", ["lead", "zip"], contact),
    address: pathOr("", ["lead", "address"], contact),
    make: pathOr("", ["lead", "vehicleInfo", "make"], contact),
    model: pathOr("", ["lead", "vehicleInfo", "model"], contact),
    year: pathOr("", ["lead", "vehicleInfo", "year"], contact),
    licensePlate: pathOr("", ["lead", "vehicleInfo", "licensePlate"], contact),
    vin: pathOr("", ["lead", "vehicleInfo", "vin"], contact),
    odometer: pathOr("", ["lead", "vehicleInfo", "odometer"], contact),
    frontPadLife: pathOr("", ["lead", "vehicleInfo", "frontPadLife"], contact),
    rearPadLife: pathOr("", ["lead", "vehicleInfo", "rearPadLife"], contact),
    extraInfo: pathOr("", ["lead", "vehicleInfo", "extraInfo"], contact),
    symptoms: pathOr("", ["lead", "vehicleInfo", "symptoms"], contact),
    frontBrakeSymptoms: pathOr([], ["lead", "vehicleInfo", "frontBrakeSymptoms"], contact),
    rearBrakeSymptoms: pathOr([], ["lead", "vehicleInfo", "rearBrakeSymptoms"], contact),
    additionalNotes: pathOr("", ["lead", "vehicleInfo", "additionalNotes"], contact),
    customerExpectation: pathOr("", ["lead", "vehicleInfo", "customerExpectation"], contact),
  };

  const onSubmit = async (values: ContactDetailsFormValues, _formikHelkpers) => {
    const diff = objectDiff(values, initialValues);

    // @ts-ignore
    const updates = pipe(contactDetailsSpec, cleanObject)(diff);
    if (keys(updates).length) {
      await updateContact({
        variables: {
          id: contactId,
          updateContactInput: updates,
        },
      });
      showSuccessAlert(dispatch, "Success");
    }
  };
  const { fullName } = contact;
  return (
    <FullScreenDialog title={`Contact: ${fullName}`} open={open} onClose={closeHandler}>
      {open && (
        <Paper>
          <ContactDetailsForm
            contact={contact}
            contactId={contactId}
            // @ts-ignore
            fileNames={pluck("fileName", files)}
            initialValues={initialValues}
            onSubmit={onSubmit}
          />
        </Paper>
      )}
    </FullScreenDialog>
  );
};
