import gql from "graphql-tag";
import { ejiPriceInfoFields } from "./calculatePossibleEjiPriceInfo";
import { ejiServiceFields, preJobCheckListField } from "./getJob";

export const invoiceDetailsFields = `
id
invoiceNumber
laborCost
partsCost
partsTax
laborTax
serviceCatalogueUsed
totalTax
technician {
    id
    firstName
    lastName
}
market
job {
        id
        status
        jobNumber
        feedback {
            rating
            doneBetter
            additionalComments
        }
        partsOrderingIssuesSurveys {
            partsCorrect
            partsStores
            partsIssues
            additionalDetails
            completed
            submittedBy
        }
}
        status
        subTotal
        vehicleInfo {
            frontPadLife
            licensePlate
            make
            model
            odometer
            rearPadLife
            vin
            year
        }
        contact {
            id
            firstName
            lastName
            fullName
            email
            stripeCustomer
            stripePaymentMethods
            fleet {
                name
            }
        }
        amountPaid
        balanceDue
        createdAt
        updatedAt
        taxable
        issuedDate
        dueDate
        ${preJobCheckListField}
        items {
            product
            isInEstimate
            name
            amount
            description
            partNumber
            laborCost
            partsStore {
                id
                name
                vendor
            }
            partsCost
            createdAt
            updatedAt
        }
        serviceLocation
        privateNotes
        customerMessage
        serviceCatalogueUsed
        serviceFollowUp {
          followUpNeeded
          outreachTimeline
          outreachDate
          outreachNotes
          dateLeadCreated
          pipedriveDealId
        }
        services {
            ${ejiServiceFields}
        }
        priceInfo {
            ${ejiPriceInfoFields}
        }`;

export const GET_INVOICE = gql`query getInvoice($id: ID!) {
    getInvoice(id: $id) {
        ${invoiceDetailsFields}
    }
}`;
