import * as React from "react";
import { contains, equals } from "ramda";
import { TextOnWayButton } from "./TextOnWayButton";
import { StartJobButton } from "./StartJobButton";
import { PreJobCheckListFormValues } from "./PreJobCheckListDialog";
import { OpenInspectionButton } from "./OpenInspectionButton";
import { Contact } from "../../generated/nest-graphql";

export const JobActionButton: React.FC<{
  jobId: string;
  jobStatus: string;
  message: string;
  phoneNumber: string;
  invoiceId: string;
  preJobCheckListInitialValues: PreJobCheckListFormValues;
  contact: Contact;
}> = ({ jobId, jobStatus, message, phoneNumber, invoiceId, preJobCheckListInitialValues, contact }) => {
  if (contains(jobStatus, ["New", "New: Scheduled", "Open", "Scheduled", "Self-Scheduling Link Sent"])) {
    return <TextOnWayButton jobId={jobId} message={message} phoneNumber={phoneNumber} />;
  }
  if (equals("Open: En Route", jobStatus)) {
    return <StartJobButton jobId={jobId} />;
  }
  return (
    <OpenInspectionButton
      jobId={jobId}
      invoiceId={invoiceId}
      preJobCheckListInitialValues={preJobCheckListInitialValues}
      contact={contact}
    />
  );
};
