import * as React from "react";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

export const ReceiptViewLineItem: React.FC<{
  label: string;
  value: string;
  reasonCode?: string;
  subItem?: boolean;
}> = ({ label, value, subItem, reasonCode }) => {
  return (
    <Box className={`flex flex-row justify-between ${subItem ? "pl-4" : ""}`}>
      <Typography variant={`${subItem ? "caption" : "h6"}` as "inherit"}>{label}</Typography>
      {reasonCode && <Typography variant={`${subItem ? "caption" : "h6"}` as "inherit"}>{reasonCode}</Typography>}
      <Typography variant={`${subItem ? "caption" : "h6"}` as "inherit"}>{value}</Typography>
    </Box>
  );
};

export const DiscountLineItem: React.FC<{
  label: string;
  value: string;
  reasonCode: string;
  subItem?: boolean;
}> = ({ label, value, subItem, reasonCode }) => {
  return (
    <Box className={`flex flex-row justify-between ${subItem ? "pl-4" : ""}`}>
      <Typography variant={`${subItem ? "caption" : "h6"}` as "inherit"} className="mr-2" style={{ minWidth: "7rem" }}>
        {label}
      </Typography>
      <div className="flex flex-row justify-between flex-grow">
        <Typography variant={`${subItem ? "caption" : "h6"}` as "inherit"} style={{ marginRight: "0.25rem" }}>
          {reasonCode}
        </Typography>
        <Typography variant={`${subItem ? "caption" : "h6"}` as "inherit"}>{value}</Typography>
      </div>
    </Box>
  );
};

export const PromoLineItem: React.FC<{
  label: string;
  value: string;
  reasonCode: string;
  subItem?: boolean;
}> = ({ label, value, subItem, reasonCode }) => {
  return (
    <Box className={`flex flex-row justify-between ${subItem ? "pl-4" : ""}`}>
      <Typography variant={`${subItem ? "caption" : "h6"}` as "inherit"} className="mr-2" style={{ minWidth: "7rem" }}>
        {label}
      </Typography>
      <div className="flex flex-row justify-between flex-grow">
        <Typography variant={`${subItem ? "caption" : "h6"}` as "inherit"} style={{ marginRight: "0.25rem" }}>
          {reasonCode}
        </Typography>
        <Typography variant={`${subItem ? "caption" : "h6"}` as "inherit"}>{value}</Typography>
      </div>
    </Box>
  );
};
