import React from "react";
import TimeGrid from "react-big-calendar/lib/TimeGrid";
import { Navigate, TitleOptions } from "react-big-calendar";
import { add, startOfDay, sub } from "date-fns";
import { applySpec, map, pipe, prop, uniq } from "ramda";

export const TestHeader = () => <div>Test Header</div>;

export class TechnicianResourceView extends React.Component<any, any> {
  render() {
    const resourceMap = pipe(
      map(
        applySpec({
          resourceId: prop("technicianId"),
          resourceTitle: prop("resourceTitle"),
        })
      ),
      uniq
    )(this.props.events);
    const { date, ...rest } = this.props;
    let range = TechnicianResourceView.range(date);
    return <TimeGrid {...rest} resources={resourceMap} range={range} eventOffset={10} />;
  }

  static navigate(date: Date, action: "PREV" | "NEXT" | "TODAY" | "DATE", props: any): Date {
    switch (action) {
      // @ts-ignore
      case Navigate.PREVIOUS:
        return sub(date, { days: 1 });
      // @ts-ignore
      case Navigate.NEXT:
        return add(date, { days: 1 });
      default:
        return date;
    }
  }

  static title(date: Date, options: TitleOptions): string {
    return `${date.toLocaleDateString()}`;
  }

  static range(date: Date) {
    return [startOfDay(date)];
  }
}
