import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/react";
import { CaptureConsole } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";

const stage = `${process.env.REACT_APP_STAGE}`;

if (`${process.env.NODE_ENV}` === "production") {
  Sentry.init({
    dsn: "https://8134e707ccaa4df9b43850301b907e46@o93944.ingest.sentry.io/5226296",
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: stage,
    integrations: [
      new CaptureConsole({
        levels: ["error"],
      }),
      new Integrations.BrowserTracing(),
    ],
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    tracesSampleRate: 0.1,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
