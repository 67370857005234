import * as React from "react";
import { File } from "../../generated/nest-graphql";
import Box from "@material-ui/core/Box";

export const FileDisplayItem: React.FC<{ file: File }> = ({ file }) => {
  return (
    <Box>
      <a href={file.signedUrl} target={"_blank"} rel="noopener noreferrer">
        {file.fileName}
      </a>
    </Box>
  );
};
