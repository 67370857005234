import * as React from "react";
import { Card } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import { ReceiptViewLineItem } from "./ReceiptViewLineItem";

export const BalanceDueView: React.FC<{
  amountPaid: string;
  balanceDue: string;
}> = ({ balanceDue, amountPaid }) => {
  return (
    <Card raised={true}>
      <CardContent>
        <ReceiptViewLineItem label={"Balance Due"} value={balanceDue} />
        <ReceiptViewLineItem label={"Amount Paid"} value={amountPaid} />
      </CardContent>
    </Card>
  );
};
