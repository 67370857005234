import gql from "graphql-tag";

export const GET_CURRENT_APP_VERSION = gql`
  query getTechnicianCurrentAppVersion {
    getTechnicianCurrentAppVersion {
      version
      createdAt
      updatedAt
    }
  }
`;
