import * as React from "react";
import { useState } from "react";
import {
  any,
  append,
  cond,
  defaultTo,
  either,
  equals,
  find,
  includes,
  isEmpty,
  isNil,
  map,
  path,
  pipe,
  pluck,
  prop,
  propEq,
  propOr,
  props,
  sum,
  T,
} from "ramda";
import { Form, Formik } from "formik";
import { Contact, Item, ServiceFollowUp, Technician } from "../../generated/nest-graphql";
import { TextAreaField } from "./fields/TextAreaField";
import Container from "@material-ui/core/Container";
import List from "@material-ui/core/List";
import { ContactListItem } from "../ListItems/ContactListItem";
import Paper from "@material-ui/core/Paper";
import { handleNoDecimal, mapIndexed, toNumber } from "../../lib/functions";
import { ReceiptView } from "../Invoices/ReceiptView";
import { VehicleInfoValues } from "../../types/VehicleInfoValues";
import { BottomSubmitButtonRow } from "../Buttons/BottomSubmitButtonRow";
import { ItemsSelector } from "./fields/ItemsSelector";
import { AddNewItemDialog } from "../Items/AddNewItemDialog";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import { useToggle } from "../../hooks/useToggle";
import { ItemFormValues } from "./ItemForm";
import { useMarkets } from "../../hooks/useMarkets";
import { flow } from "fp-ts/lib/function";
import currency from "currency.js";
import { ServicesSection } from "../services/ServicesSection";
import { POTENTIAL_INVOICE, SERVICE_CATALOGUE_USED_ITEMS, SERVICE_CATALOGUE_USED_SERVICES } from "../../lib/constants";
import { EJIDiscountFormValue, EJIPriceInfoFormValue, EJIServiceFormValue } from "./JobDetailsForm";
import { showErrorAlert } from "../../actions";
import { useDispatch } from "../../contexts/snackbar-context";
import ServiceFollowUpForm from "../Invoices/ServiceFollowUp";
import { FOLLOW_UP_ALERT_MESSAGE } from "../../pages/invoices/[id]";

export type AddInvoiceDetailsFormValues = {
  contact: Contact;
  serviceLocation: string;
  status: string;
  taxable?: boolean;
  estimate?: string;
  technician: Technician;
  privateNotes?: string;
  market: string;
  items: Item[];
  customerMessage?: string;
  issuedDate: Date;
  jobNumber?: string;
  dueDate: Date;
  jobId?: string;
  services: EJIServiceFormValue[];
  discounts?: EJIDiscountFormValue[];
  priceInfo?: EJIPriceInfoFormValue;
  serviceCatalogueUsed?: string;
  serviceFollowUp?: ServiceFollowUp;
} & VehicleInfoValues;

type AddInvoiceDetailsProps = {
  initialValues: AddInvoiceDetailsFormValues;
  onSubmit: any;
};

export const AddInvoiceDetailsForm: React.FC<AddInvoiceDetailsProps> = ({ initialValues, onSubmit }) => {
  const markets = useMarkets();
  const [addDialogOpen, , toggleAddDialog] = useToggle();
  const [allItems, setAllItems] = useState<Item[]>([...initialValues.items]);
  const [selectedItems, setSelectedItems] = useState<any>(mapIndexed((item, idx) => idx, allItems));
  const dispatch = useDispatch();
  const verifyServicesSelected = (values, actions) => {
    cond([
      [
        () =>
          !equals(SERVICE_CATALOGUE_USED_ITEMS, prop("serviceCatalogueUsed", values)) &&
          !any(propEq("inInvoice", true))(prop("services", values)),
        () => {
          showErrorAlert(dispatch, "Invoice must have at least one service selected");
          actions.setSubmitting(false);
        },
      ],
      [
        () =>
          path(["serviceFollowUp", "followUpNeeded"], values) &&
          either(isNil, isEmpty)(path(["serviceFollowUp", "outreachNotes"], values)),
        () => {
          showErrorAlert(dispatch, FOLLOW_UP_ALERT_MESSAGE);
          actions.setSubmitting(false);
        },
      ],
      [
        T,
        () => {
          onSubmit(values, actions);
        },
      ],
    ])();
  };

  return (
    <Formik<AddInvoiceDetailsFormValues> initialValues={initialValues} onSubmit={verifyServicesSelected}>
      {({ values, setFieldValue, isSubmitting, isValid, initialValues, setValues }) => {
        const taxable: boolean = propOr(false, "taxable", values);
        const subtotal = pipe(pluck("amount"), map(toNumber), sum, currency)(values.items);
        const partsCost = pipe(pluck("partsCost"), map(toNumber), sum, currency)(values.items);
        const laborCost = subtotal.subtract(partsCost);
        const [partsTaxRate, laborTaxRate]: any = flow(
          defaultTo([]),
          find(propEq("name", prop("market", initialValues))),
          props(["partsTaxRate", "laborTaxRate"])
        )(markets);
        const partsTax = partsCost.multiply(partsTaxRate / 100);
        const laborTax = laborCost.multiply(laborTaxRate / 100);
        const taxAmount = taxable ? partsTax.add(laborTax) : 0;
        const total = subtotal.add(taxAmount);
        const isSelected = (idx) => {
          return includes(idx, selectedItems);
        };
        const handleToggle = (value: number) => () => {
          const currentIndex = selectedItems.indexOf(value);
          const newChecked = [...selectedItems];
          if (currentIndex === -1) {
            newChecked.push(value);
          } else {
            newChecked.splice(currentIndex, 1);
          }
          const items = map((val) => {
            return allItems[`${val}`];
          }, newChecked);

          setSelectedItems(newChecked);
          setFieldValue("items", items);
        };
        const selectableItems = mapIndexed(
          (item, idx) => ({
            item,
            handleToggle: handleToggle(idx),
            isSelected: isSelected(idx),
          }),
          allItems
        );
        const addItemSubmit = async (itemFormValues) => {
          const oldItems = values.items;
          const { product, name, description, partNumber, partsCost, laborCost, amount, isInEstimate } = itemFormValues;
          const cleanedItemFormValues: ItemFormValues = {
            amount: handleNoDecimal(amount),
            laborCost: handleNoDecimal(laborCost),
            partsCost: handleNoDecimal(partsCost),
            partNumber,
            isInEstimate,
            description,
            name,
            product,
          };
          const result = append(cleanedItemFormValues, oldItems);
          setAllItems(result);
          setSelectedItems((prev: any) => [...prev, result.length - 1] as any);
          setFieldValue("items", result, false);
          toggleAddDialog();
        };
        return (
          <Form
            // padding bottom to offset save button
            className="pb-4"
          >
            <Paper>
              <List>
                <ContactListItem contact={values.contact} />
              </List>
              <Container className={"py-4"}>
                <ServiceFollowUpForm values={values} setValues={setValues} setFieldValue={setFieldValue} />
                <div className="grid gap-4">
                  {values.serviceCatalogueUsed === SERVICE_CATALOGUE_USED_SERVICES ? (
                    <ServicesSection
                      services={values.services}
                      values={values}
                      setValues={setValues}
                      stage={POTENTIAL_INVOICE}
                    />
                  ) : (
                    <>
                      <ListItem>
                        <Button variant={"contained"} color={"primary"} fullWidth={true} onClick={toggleAddDialog}>
                          + Add Item
                        </Button>
                      </ListItem>

                      <ItemsSelector selectableItems={selectableItems as any} />
                      <ReceiptView
                        subtotal={subtotal.toString()}
                        total={total.toString()}
                        tax={taxAmount.toString()}
                        parts={partsCost.toString()}
                        labor={laborCost.toString()}
                      />
                    </>
                  )}
                  <TextAreaField name={"customerMessage"} label={"Customer Message"} />
                  <TextAreaField name={"privateNotes"} label={"Private Notes"} />
                </div>
              </Container>
            </Paper>
            <BottomSubmitButtonRow
              isSubmitting={isSubmitting}
              isValid={isValid}
              pinToBottom={true}
              label={"Create Invoice"}
            />
            <AddNewItemDialog open={addDialogOpen} onClose={toggleAddDialog} onSubmit={addItemSubmit} />
          </Form>
        );
      }}
    </Formik>
  );
};
