import * as React from "react";
import { default as CoreTextField } from "@material-ui/core/TextField";
import { defaultTo } from "ramda";
import { NumberFormatCustom } from "../fields/CurrencyInput";

type BasicTextFieldProps = {
  value: any;
  label: string;
  inputMode?: "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search";
  onChange: any;
  isRequired?: boolean;
  inputType?: "currency" | "quantity";
};

export const BasicTextField = ({
  value,
  label,
  inputMode = "text",
  onChange,
  isRequired = true,
  inputType,
}: BasicTextFieldProps) => {
  const isMissingRequiredValue = isRequired && (value === null || value === undefined || value?.length === 0);
  const warningText = inputType === "quantity" && Number(value) > 25 ? "Warning: Quantity is higher than normal" : "";
  return (
    <CoreTextField
      fullWidth
      variant={"outlined"}
      label={label}
      value={inputType === "quantity" ? defaultTo(0, Number(value)) : defaultTo("", value)}
      error={isMissingRequiredValue}
      helperText={(isMissingRequiredValue && "This is a required field.") || warningText}
      inputProps={{ inputMode: inputMode, autocomplete: "off" }}
      InputProps={
        inputType === "currency" && {
          inputComponent: NumberFormatCustom as any,
        }
      }
      required={isRequired}
      onChange={onChange}
    />
  );
};
