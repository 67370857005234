import * as React from "react";
import { useEffect, useRef } from "react";
import { PossibleEjiService } from "../../generated/nest-graphql";
import { useToggle } from "../../hooks/useToggle";
import { EJIServiceFormValue, JobDetailsFormValues } from "../Forms/JobDetailsForm";
import { UpdateServiceDetailsDialog } from "./UpdateServiceDetailsDialog";

export const UpdateServiceDetailsButton: React.FC<{
  service: EJIServiceFormValue;
  serviceIdx: any;
  parentSetFieldValue?: any;
  parentSubmitForm?: any;
  parentSubmitting?: boolean;
  servicesList: PossibleEjiService[];
}> = ({ service, serviceIdx, parentSetFieldValue, parentSubmitForm, parentSubmitting, servicesList }) => {
  const [open, , toggleDialog] = useToggle();
  const openId = useRef<number>(1);
  useEffect(() => {
    if (!open) {
      openId.current = openId.current + 1;
    }
  }, [open]);

  return (
    <>
      <div onClick={toggleDialog} className="text-primary cursor-pointer">
        Edit
      </div>
      <UpdateServiceDetailsDialog
        key={`${service.id}-${openId.current}`}
        service={service}
        serviceIdx={serviceIdx}
        parentSetFieldValue={parentSetFieldValue}
        parentSubmitForm={parentSubmitForm}
        parentSubmitting={parentSubmitting}
        open={open}
        toggleDialog={toggleDialog}
        servicesList={servicesList}
      ></UpdateServiceDetailsDialog>
    </>
  );
};
