import * as React from "react";
import { File } from "../../generated/nest-graphql";
import { FileDisplayItem } from "./FileDisplayItem";

export const ShowFiles: React.FC<{ files: File[] }> = ({ files }) => {
  return (
    <div className="grid grid-cols-2 gap-4 my-4">
      {files.map((file, index) => (
        <FileDisplayItem file={file} key={index} />
      ))}
    </div>
  );
};
