import React from "react";
import "./App.css";
import { Auth0Provider } from "@auth0/auth0-react";
import { CustomApolloProvider } from "./components/CustomApolloProvider";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core";
import { darkTheme } from "./theme";
import "./styles/styles.css";
import { SnackBarProvider } from "./contexts/snackbar-context";
import AuthWrapper from "./components/AuthWrapper";
import { Routes } from "./routes";
import { ErrorBoundary } from "@sentry/react";
import { ServiceWorkerChecker } from "./ServiceWorkerChecker";

function App() {
  return (
    <Auth0Provider
      domain={`${process.env.REACT_APP_AUTH0_DOMAIN}`}
      clientId={`${process.env.REACT_APP_AUTH0_CLIENT_ID}`}
      redirectUri={window.location.origin}
      scope={"openid profile email"}
      audience={"https://auth0.nubrakes.com"}
      useRefreshTokens={true}
    >
      <AuthWrapper>
        <CustomApolloProvider>
          <ThemeProvider theme={darkTheme}>
            <CssBaseline>
              <SnackBarProvider>
                <ErrorBoundary>
                  <ServiceWorkerChecker>
                    <Routes />
                  </ServiceWorkerChecker>
                </ErrorBoundary>
              </SnackBarProvider>
            </CssBaseline>
          </ThemeProvider>
        </CustomApolloProvider>
      </AuthWrapper>
    </Auth0Provider>
  );
}

export default App;
