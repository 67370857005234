import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "./Alert";
import React from "react";
import { useSnackBar } from "../contexts/snackbar-context";

export const GlobalSnackBar = () => {
  const {
    dispatch,
    state: { showMessage, type, message },
  } = useSnackBar();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({ type: "HIDE" });
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showMessage}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          // @ts-ignore
          onClose={handleClose}
          // @ts-ignore
          severity={type}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};
