import React from "react";
import { useHistory } from "react-router-dom";
import { useEntityId } from "../../hooks/useEntityId";
import { path, pathOr, prop, propOr, pipe, sortBy, descend } from "ramda";
import { AppBar } from "@material-ui/core";
import { JobList } from "../../components/JobsHistory/JobList";
import { Job } from "../../generated/nest-graphql";
import { Layout } from "../../components/Layout";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Typography from "@material-ui/core/Typography";
import { useQuery } from "@apollo/client";
import {
  Query,
  QueryGetContactArgs,
  QueryGetJobsArgs,
} from "../../generated/nest-graphql";
import { GET_CONTACT_DETAILS } from "../../graphql/queries/getContactDetails";
import { GET_JOBS } from "../../graphql/queries/getJobs";

const JobsHistoryAppBar: React.FC<{ customerName: string }> = ({
  customerName,
}) => {
  const { goBack } = useHistory();
  return (
    <AppBar position={"sticky"}>
      <Toolbar>
        <IconButton onClick={goBack}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant="h6">{`Job History: ${customerName}`}</Typography>
      </Toolbar>
    </AppBar>
  );
};

const JobsHistoryPage: React.FC<{}> = () => {
  const contactId = useEntityId();
  const { data } = useQuery<Query, QueryGetContactArgs>(GET_CONTACT_DETAILS, {
    variables: {
      id: contactId,
    },
  });

  const { data: dataJobs, loading } = useQuery<Query, QueryGetJobsArgs>(
    GET_JOBS,
    {
      variables: {
        filter: { contact: contactId },
      },
      fetchPolicy: "no-cache",
    }
  );
  const contact = prop("getContact", data);
  // @ts-ignore
  const jobs: Job[] = pipe(
    pathOr([], ["getJobs", "edges"]),
    // @ts-ignore
    sortBy(descend(path(["appointment", "startDate"])))
    // @ts-ignore
  )(dataJobs);
  return (
    <Layout
      appBar={
        <JobsHistoryAppBar customerName={propOr("", "fullName", contact)} />
      }
    >
      <JobList jobs={jobs} loading={loading} />
    </Layout>
  );
};

export default JobsHistoryPage;
