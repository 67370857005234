import { Button, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { CancelButton } from "../Buttons/CancelButton";
import { cond, isNil, T } from "ramda";
import currency from "currency.js";
import { EjiDiscount } from "../../generated/nest-graphql";
import { MaterialMaskedInput, NumberFormatCustom } from "../Forms/fields/CurrencyInput";
import {
  DISCOUNT_LIMIT_FLAT,
  DISCOUNT_LIMIT_PERCENT,
  DISCOUNT_REASONS,
  EJI_DISCOUNT_TYPE_FLAT,
  EJI_DISCOUNT_TYPE_PERCENT,
} from "../../lib/constants";

type DiscountModalTypes = {
  existingDiscount?: EjiDiscount;
  discountIdx?: number;
  closeModal: any;
  onSubmit: any;
};

const DiscountModal = ({
  existingDiscount = { type: null, reason: null, amount: null, total: null },
  closeModal,
  onSubmit,
}: DiscountModalTypes) => {
  const typeOptions = [
    { value: EJI_DISCOUNT_TYPE_PERCENT, label: "Percent discount" },
    { value: EJI_DISCOUNT_TYPE_FLAT, label: "Flat dollar discount" },
  ];
  const reasonOptions = DISCOUNT_REASONS.map((reasonText) => ({
    value: reasonText,
    label: reasonText,
  }));
  const [discount, setDiscount] = useState({
    ...existingDiscount,
    amount:
      existingDiscount.type === EJI_DISCOUNT_TYPE_FLAT
        ? currency(existingDiscount?.total).value
        : existingDiscount.amount,
  });
  const [submitted, setSubmitted] = useState(false);
  const invalidFlatDiscountAmount = discount.type === EJI_DISCOUNT_TYPE_FLAT && discount.amount > DISCOUNT_LIMIT_FLAT;
  const invalidPercentDiscountAmount =
    discount.type === EJI_DISCOUNT_TYPE_PERCENT && discount.amount > DISCOUNT_LIMIT_PERCENT;
  const invalidDiscountAmount = invalidFlatDiscountAmount || invalidPercentDiscountAmount;
  const invalidDiscount =
    !discount.type || isNil(discount.amount) || discount.amount < 0 || !discount.reason || invalidDiscountAmount;
  return (
    <div className="flex flex-col justify-between h-64">
      <FormControl fullWidth variant="outlined" required>
        <InputLabel>Type</InputLabel>
        <Select
          label={"Type"}
          onChange={(event) => {
            const discountType = event.target.value.toString();
            setDiscount((currentDiscount) => {
              const tempDiscount = { ...currentDiscount };
              tempDiscount.type = discountType;
              return tempDiscount;
            });
          }}
          value={discount.type}
        >
          {typeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        error={invalidDiscountAmount}
        helperText={
          invalidDiscountAmount
            ? cond([
                [() => invalidPercentDiscountAmount, () => `Discount cannot be larger than ${DISCOUNT_LIMIT_PERCENT}%`],
                [() => invalidFlatDiscountAmount, () => `Discount cannot be larger than $${DISCOUNT_LIMIT_FLAT}`],
                [T, () => ""],
              ])()
            : ""
        }
        FormHelperTextProps={{
          style: { color: "red" },
        }}
        fullWidth
        variant={"outlined"}
        label={discount.amount ? "Amount" : "Enter discount amount"}
        onChange={(event) => {
          const amount = event.target.value === "" ? null : Number(event.target.value);
          setDiscount((currentDiscount) => {
            const tempDiscount = { ...currentDiscount };
            tempDiscount.amount = amount;
            return tempDiscount;
          });
        }}
        required
        disabled={!discount.type}
        value={discount.amount}
        inputProps={{ inputMode: "numeric" }}
        InputProps={{
          inputComponent: discount.type === EJI_DISCOUNT_TYPE_FLAT ? (NumberFormatCustom as any) : MaterialMaskedInput,
          endAdornment: (
            <InputAdornment position="end" className="text-xs pr-0">
              {!!discount.type && (discount.type === EJI_DISCOUNT_TYPE_FLAT ? "$" : "%")}
            </InputAdornment>
          ),
        }}
      />
      <FormControl fullWidth variant="outlined" required>
        <InputLabel>Reason</InputLabel>
        <Select
          label={"Reason"}
          onChange={(event) => {
            const reason = event.target.value.toString();
            setDiscount((currentDiscount) => {
              const tempDiscount = { ...currentDiscount };
              tempDiscount.reason = reason;
              return tempDiscount;
            });
          }}
          value={discount.reason}
        >
          {reasonOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className="grid grid-rows-1 grid-cols-3 col-span-3 gap-4">
        <div className="justify-items-start">
          <div className="justify-self-stretch flex flex-col">
            <CancelButton onClick={closeModal}>Cancel</CancelButton>
          </div>
        </div>
        <div className="justify-items-start col-start-3">
          <div className="justify-self-stretch flex flex-col">
            <Button
              disabled={invalidDiscount || submitted}
              style={{ fontWeight: "bolder" }}
              size={"large"}
              variant={"contained"}
              color={"primary"}
              onClick={() => {
                setSubmitted(true);
                onSubmit({
                  ...discount,
                  amount:
                    discount.type === EJI_DISCOUNT_TYPE_FLAT ? currency(discount.amount).intValue : discount.amount,
                });
              }}
            >
              submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscountModal;
