import React from "react";
import { useField } from "formik";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { TextField } from "@material-ui/core";

const NumberKeyPadTextField = (props) => <TextField {...props} inputProps={{ inputMode: "numeric" }} />;

export const DateInput: React.FC<{
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
}> = ({ name, label, required, disabled = false, disablePast = false, disableFuture = false, ...props }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(name);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disablePast={disablePast}
        disableFuture={disableFuture}
        disabled={disabled}
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        emptyLabel="__/__/____"
        margin="normal"
        label={label}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        inputVariant="outlined"
        error={!!meta.error || field?.value?.toString() === "Invalid Date"}
        {...field}
        {...props}
        onChange={(date) => helpers.setValue(date)}
        autoOk
        minDateMessage="Date must be in the future"
        maxDateMessage="Date must be in the past"
        TextFieldComponent={NumberKeyPadTextField}
      />
    </MuiPickersUtilsProvider>
  );
};
