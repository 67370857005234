import gql from "graphql-tag";

export const marketFields = `
      id
      name
      timeZone
      salesTaxType
      partsTaxRate
      laborTaxRate
`;

export const GET_MARKETS = gql`
  query getMarketsV2 {
    getMarketsV2 {
      ${marketFields}
    }
  }
`;
