import React from "react";
import clsx from "clsx";
import { navigate } from "react-big-calendar/lib/utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";

export const CalendarToolbar: React.FC<Props> = ({
  localizer: { messages },
  label,
  onNavigate,
  onView,
  view,
  views,
}) => {
  const viewNamesGroup = (messages) => {
    const actual = ["day", "week"];
    if (actual.length > 1) {
      return actual.map((name) => (
        <button type="button" key={name} className={clsx({ "rbc-active": view === name })} onClick={() => onView(name)}>
          {messages[name]}
        </button>
      ));
    }
  };
  return (
    <div>
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">{viewNamesGroup(messages)}</span>
      </div>
      <div className="flex flex-row justify-between px-4">
        <div onClick={() => onNavigate(navigate.PREVIOUS)}>
          {/* @ts-ignore */}
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        <div>{label}</div>
        <div onClick={() => onNavigate(navigate.NEXT)}>
          {/* @ts-ignore */}
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </div>
    </div>
  );
};

type Props = {
  view: string;
  views: string[];
  label: React.ReactNode;
  localizer: any;
  onNavigate: any;
  onView: any;
};
