import * as React from "react";
import Typography from "@material-ui/core/Typography";
import { ListItem } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";

export const PartsInfoListItem: React.FC<{
  partsOrderNumber: string | undefined;
  partsLocation: string | undefined;
  partsNotes: string | undefined;
}> = ({ partsNotes, partsLocation, partsOrderNumber }) => {
  return (
    <ListItem divider style={{ paddingRight: 90 }}>
      <ListItemText
        primary={`Parts Order Number: ${partsOrderNumber}`}
        secondary={
          <>
            {partsLocation && <Typography>{partsLocation}</Typography>}
            {partsNotes && <Typography>{partsNotes}</Typography>}
          </>
        }
      />
    </ListItem>
  );
};
