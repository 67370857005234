import * as React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

export const BaseJobActionButton: React.FC<{
  onClick: () => void;
  icon: React.ReactNode;
  loading: boolean;
  buttonText: string;
  disabled?: boolean;
}> = ({ onClick, loading, buttonText, icon, disabled = false }) => {
  return (
    <Button className="w-1/2" variant={"contained"} color={"secondary"} disabled={disabled} onClick={onClick}>
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <div>
          {icon}
          <Typography display={"block"} variant={"caption"}>
            {buttonText}
          </Typography>
        </div>
      )}
    </Button>
  );
};
