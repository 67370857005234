import { Dispatch, SetStateAction, useState } from "react";

type result = [boolean, Dispatch<SetStateAction<boolean>>, () => void];
export const useToggle = (): result => {
  const [open, setIsOpen] = useState(false);
  const toggleDialog = () => {
    setIsOpen(!open);
  };
  return [open, setIsOpen, toggleDialog];
};
