import * as React from "react";
import { FullScreenDialog } from "../FullScreenDialog";
import { Paper } from "@material-ui/core";
import { Item } from "../../generated/nest-graphql";
import { ItemForm, ItemFormValues } from "../Forms/ItemForm";
import { handleNoDecimal } from "../../lib/functions";
import { clone } from "ramda";

export const UpdateItemDetailDialog: React.FC<{
  parentSubmit: () => Promise<void>;
  open: boolean;
  onClose: () => void;
  itemIndex: number;
  items: Item[];
  setFieldValue: any;
}> = ({ open, onClose, items, setFieldValue, itemIndex, parentSubmit }) => {
  const itemToEdit = items[itemIndex];
  return (
    <FullScreenDialog title={"Edit Item"} open={open} onClose={onClose}>
      <Paper>
        <ItemForm
          onSubmit={async (itemFormValues: ItemFormValues) => {
            const itemsClone = clone(items);
            const {
              product,
              name,
              description,
              partNumber,
              partsCost,
              laborCost,
              amount,
              isInEstimate,
            } = itemFormValues;
            itemsClone[itemIndex] = {
              amount: handleNoDecimal(amount),
              laborCost: handleNoDecimal(laborCost),
              partsCost: handleNoDecimal(partsCost),
              partNumber,
              description,
              isInEstimate,
              name,
              product,
            };
            setFieldValue("items", itemsClone, false);
            await parentSubmit();
            onClose();
          }}
          initialValues={itemToEdit}
          onCancel={onClose}
          submitLabel={"Update Item"}
        />
      </Paper>
    </FullScreenDialog>
  );
};
