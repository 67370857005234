import { Box, Chip, CircularProgress, Typography, Button } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import React, { useEffect } from "react";
import {
  Item,
  PartsStore,
  PossibleEjiService,
  Query,
  QueryCalculatePossibleEjiPriceInfoArgs,
  QueryGetAllPossibleServicesArgs,
} from "../../generated/nest-graphql";
import { CheckboxField } from "../Forms/fields/CheckboxField";
import { useLazyQuery, useQuery } from "@apollo/client";
import { CALCULATE_POSSIBLE_EJI_PRICE_INFO } from "../../graphql/queries/calculatePossibleEjiPriceInfo";
import { ServicesReceiptView } from "./ServicesReceiptView";
import { anyPass, isEmpty, isNil, mergeDeepWith, pluck, prop, reject, uniqBy, last } from "ramda";
import { EJIItemFormValue, EJIServiceFormValue, JobDetailsFormValues } from "../Forms/JobDetailsForm";
import {
  ejiDiscountsToFormValuesSpec,
  ejiPriceInfoToFormValue,
  formValuesToEJIDiscountInputs,
  formValuesToPossibleEJIServiceInputs,
  formValueToEJIPricingConfigInput,
  possibleEJIServicesToFormValuesSpec,
  ejiPromoCodesToFormValuesSpec,
  formValuesToEJIPromoCodeInputs,
} from "../../specs/ejiServicesSpec";
import { pipe } from "fp-ts/lib/function";
import cloneDeep from "lodash.clonedeep";
import { CREATED_INVOICE, JOB, POTENTIAL_INVOICE } from "../../lib/constants";
import { UpdateServiceDetailsButton } from "../Jobs/UpdateServiceDetailsButton";
import { AddServiceButton } from "./AddServiceButton";
import { GET_ALL_POSSIBLE_SERVICES } from "../../graphql/queries/getAllPossibleServices";
import { AnnouncementOutlined } from "@material-ui/icons";
import { useToggle } from "../../hooks/useToggle";
import { TECHNICIAN_GET_ME } from "../../graphql/queries/technicianGetMe";
import {
  mergePartsOrderingIssueSurveyFunc,
  PartsOrderingIssuesSurveyDialog,
} from "../Forms/PartsOrderingIssuesSurveyDialog";
import { usePartsOrderingIssuesSurvey } from "../../contexts/parts-ordering-issues-survey-context";

export const ServicesSection = ({
  services,
  values,
  setValues,
  stage,
  id,
  parentSetFieldValue,
  parentSubmitForm,
  parentSubmitting,
}: {
  services: EJIServiceFormValue[];
  values: any;
  setValues: any;
  stage: string;
  id?: any;
  parentSetFieldValue?: any;
  parentSubmitForm?: any;
  parentSubmitting?: any;
  initialValues?: JobDetailsFormValues;
}) => {
  const [open, , togglePartsSurvey] = useToggle();
  const { state } = usePartsOrderingIssuesSurvey();
  const { data: technicianGetMeData } = useQuery<Query>(TECHNICIAN_GET_ME);
  const { data: possibleServicesData, error: possibleServicesError } = useQuery<Query, QueryGetAllPossibleServicesArgs>(
    GET_ALL_POSSIBLE_SERVICES,
    {
      variables: {
        getAllPossibleServicesInput: {
          inEstimate: false,
        },
      },
    }
  );
  const [calculatePossibleEjiPriceInfo] = useLazyQuery<Query, QueryCalculatePossibleEjiPriceInfoArgs>(
    CALCULATE_POSSIBLE_EJI_PRICE_INFO,
    {
      variables: {
        calculatePossibleEJIPriceInfoInput: {
          services: formValuesToPossibleEJIServiceInputs(values.services),
          discounts: formValuesToEJIDiscountInputs(values.discounts),
          pricingConfig: formValueToEJIPricingConfigInput(values.priceInfo?.pricingConfig),
          marketName: values.market,
          taxable: values.taxable,
          promoCodes: formValuesToEJIPromoCodeInputs(values.promoCodes),
          calculateAllServices: stage === CREATED_INVOICE,
        },
      },
      onCompleted: (priceData) => {
        setValues({
          ...values,
          services:
            stage !== POTENTIAL_INVOICE
              ? pipe(priceData.calculatePossibleEJIPriceInfo.ejiServices ?? [], possibleEJIServicesToFormValuesSpec)
              : values.services,
          discounts: pipe(
            priceData.calculatePossibleEJIPriceInfo.ejiPriceInfo.discounts ?? [],
            ejiDiscountsToFormValuesSpec
          ),
          promoCodes: priceData.calculatePossibleEJIPriceInfo.ejiPriceInfo.promoCodes ?? [],
          priceInfo: pipe(priceData.calculatePossibleEJIPriceInfo.ejiPriceInfo, ejiPriceInfoToFormValue),
        });
      },
    }
  );

  const partsStores = pipe(
    pluck("partsStore", values?.items as Item[]),
    // adds the home part store in case its not there
    (stores) => {
      const homePartsStore = technicianGetMeData?.technicianGetMe?.homePartsStore;
      if (homePartsStore) {
        stores.push(homePartsStore);
      }
      return stores;
    },
    reject(anyPass([isEmpty, isNil])) as () => PartsStore[],
    uniqBy(prop("id")),
    (stores) => {
      const noPartsStoreFound: PartsStore = { vendor: "NA", name: "No parts store found", id: "", storeNumber: "" };
      return anyPass([isEmpty, isNil])(stores) ? [noPartsStoreFound] : stores;
    }
  );

  const needsRecalculation = services.some((targetService) => targetService?.needsRecalculation);

  useEffect(() => {
    if (needsRecalculation) {
      services.forEach((targetService, targetIdx) => {
        if (targetService?.needsRecalculation) {
          parentSetFieldValue(`services[${targetIdx}].needsRecalculation`, false);
        }
      });
      calculatePossibleEjiPriceInfo({
        variables: {
          calculatePossibleEJIPriceInfoInput: {
            services: formValuesToPossibleEJIServiceInputs(services),
            discounts: formValuesToEJIDiscountInputs(values.discounts),
            promoCodes: formValuesToEJIPromoCodeInputs(values.promoCodes),
            pricingConfig: formValueToEJIPricingConfigInput(values.priceInfo?.pricingConfig),
            marketName: values.market,
            taxable: values.taxable,
            calculateAllServices: stage !== JOB,
          },
        },
      });
    }
  }, [
    needsRecalculation,
    calculatePossibleEjiPriceInfo,
    services,
    parentSetFieldValue,
    values.discounts,
    values.promoCodes,
    values.priceInfo?.pricingConfig,
    values.market,
    values.taxable,
    stage,
  ]);

  if (!possibleServicesData && !possibleServicesError)
    return (
      <div className={"flex flex-row justify-center"}>
        <CircularProgress />
      </div>
    );
  const servicesList = possibleServicesData.getAllPossibleServices;

  return (
    <Container disableGutters>
      <Typography variant={"h5"}>
        <div className={`flex flex-row py-6 ${stage === JOB && "justify-around"}`}>
          <Typography variant={"h5"}>
            <Box>Services</Box>
          </Typography>
          {stage === JOB && (
            <div>
              <Button
                className="!mr-2"
                variant="outlined"
                startIcon={<AnnouncementOutlined />}
                onClick={togglePartsSurvey}
              >
                Part Issue
              </Button>
              <AddServiceButton
                parentSetFieldValue={parentSetFieldValue}
                parentSubmitForm={parentSubmitForm}
                parentSubmitting={parentSubmitting}
                servicesList={servicesList}
              />
            </div>
          )}
        </div>
      </Typography>
      {stage === POTENTIAL_INVOICE && (
        <div className="grid grid-cols-8">
          <Typography variant={"caption"} className="col-span-2">
            <Box p={1}>Add to Invoice?</Box>
          </Typography>
        </div>
      )}
      {services.map((value, serviceIdx) => {
        const InvoiceCheckBox =
          stage === POTENTIAL_INVOICE ? (
            <div className="col-span-2 ml-auto mr-auto" key={serviceIdx}>
              <CheckboxField
                label={""}
                name={`services[${serviceIdx}].inInvoice`}
                styling={{ paddingTop: ".125rem" }}
                postOnChange={(checked) => {
                  let servicesCopy = cloneDeep(values.services);
                  servicesCopy[serviceIdx].inInvoice = checked;
                  const inInvoiceServices = servicesCopy.filter((service) => service?.inInvoice);
                  calculatePossibleEjiPriceInfo({
                    variables: {
                      calculatePossibleEJIPriceInfoInput: {
                        services: formValuesToPossibleEJIServiceInputs(inInvoiceServices),
                        discounts: formValuesToEJIDiscountInputs(values.discounts),
                        promoCodes: formValuesToEJIPromoCodeInputs(values.promoCodes),
                        pricingConfig: formValueToEJIPricingConfigInput(values.priceInfo?.pricingConfig),
                        marketName: values.market,
                        taxable: values.taxable,
                        calculateAllServices: true,
                      },
                    },
                  });
                }}
              />
            </div>
          ) : (
            <></>
          );
        return (
          <ServiceListItem
            key={serviceIdx}
            service={value}
            serviceIdx={serviceIdx}
            invoiceCheckBox={stage !== JOB && InvoiceCheckBox}
            stage={stage}
            parentSetFieldValue={parentSetFieldValue}
            parentSubmitForm={parentSubmitForm}
            parentSubmitting={parentSubmitting}
            servicesList={servicesList}
          />
        );
      })}
      <ServicesReceiptView
        stage={stage}
        receiptValues={values?.priceInfo ?? {}}
        calculatePossibleEjiPriceInfo={calculatePossibleEjiPriceInfo}
      />
      {stage === JOB && (
        <PartsOrderingIssuesSurveyDialog
          initialValues={mergeDeepWith(
            mergePartsOrderingIssueSurveyFunc,
            state,
            last(values?.partsOrderingIssuesSurveys) ?? {}
          )}
          onClose={togglePartsSurvey}
          jobId={id ?? values.jobId}
          existingSurveys={values?.partsOrderingIssuesSurveys ?? []}
          open={open}
          partsStores={partsStores}
        />
      )}
    </Container>
  );
};

const ServiceListItem = ({
  service,
  serviceIdx,
  invoiceCheckBox,
  stage,
  parentSetFieldValue,
  parentSubmitForm,
  parentSubmitting,
  servicesList,
}: {
  service: EJIServiceFormValue;
  serviceIdx: any;
  invoiceCheckBox?: any;
  stage: string;
  parentSetFieldValue?: any;
  parentSubmitForm?: any;
  parentSubmitting?: any;
  servicesList: PossibleEjiService[];
}) => {
  const invoiceCreated = stage === "CREATED_INVOICE";
  return (
    <div className={`grid mb-4 ${invoiceCreated ? "grid-cols-6" : "grid-cols-8"}`}>
      {invoiceCheckBox || (
        <div className="col-span-2">
          <div className="flex flex-col items-center">
            <Chip
              color={service.inEstimate ? "primary" : "default"}
              label={service.inEstimate ? "Quote" : "Add-on"}
              size="small"
            />
            {!invoiceCreated && stage === "JOB" && (
              <UpdateServiceDetailsButton
                service={service}
                serviceIdx={serviceIdx}
                parentSetFieldValue={parentSetFieldValue}
                parentSubmitForm={parentSubmitForm}
                parentSubmitting={parentSubmitting}
                servicesList={servicesList}
              />
            )}
          </div>
        </div>
      )}
      <div className={`${invoiceCreated ? "col-span-4" : "col-span-3"}`}>
        <Typography>
          <Box fontWeight="fontWeightBold">{service.name}</Box>
        </Typography>
      </div>
      <Typography className={`${invoiceCreated ? "col-span-2" : "col-span-3"}`}>
        <Box fontWeight="fontWeightBold">${service.customerPrice}</Box>
      </Typography>
      {service.items.map(
        (item, idx) =>
          item?.category === "Part" && <ProductItemListItem item={item} key={idx} invoiceCreated={invoiceCreated} />
      )}
    </div>
  );
};

export const ProductItemListItem = ({ item, invoiceCreated }: { item: EJIItemFormValue; invoiceCreated: any }) => {
  const notHomePartsStore = true; // TODO: this needs to be added to the backend see https://nubrakes.atlassian.net/browse/EN-1107?filter=10022
  const partsStoreText =
    item.orderItem?.partsStore?.vendor && item.orderItem?.partsStore?.name
      ? `${item.orderItem?.partsStore?.vendor} - ${item.orderItem?.partsStore?.name}`
      : "No Parts Store";
  return (
    <>
      {!invoiceCreated && <div className={"col-span-2"}></div>}
      <div className={`${invoiceCreated ? "col-span-4" : "col-span-3"} pr-1`}>
        <Typography>
          {item.units}X {item.productSelection.name}
        </Typography>
      </div>
      <Typography className={`${invoiceCreated ? "col-span-2" : "col-span-3"}`}>{item.orderItem.partNumber}</Typography>
      {notHomePartsStore && (
        <>
          {!invoiceCreated && <div className="col-span-2"></div>}
          <div className={`${invoiceCreated ? "col-span-4" : "col-span-3"} text-yellow-500`}>{partsStoreText}</div>
          <div className={`${invoiceCreated ? "col-span-2" : "col-span-3"}`}></div>
        </>
      )}
    </>
  );
};
