import { Typography } from "@material-ui/core";
import React, { FC, useCallback, useEffect } from "react";
import { CheckboxField } from "../Forms/fields/CheckboxField";
import { Select } from "../Forms/fields/Select";
import { TextAreaField } from "../Forms/fields/TextAreaField";
import { DateTime } from "luxon";
import { isNil } from "ramda";

type ServiceFollowUpProps = { values: any; setValues: any; setFieldValue: any };

// Used for luxon datetime add
export const outReachTimelineOptions = {
  Tomorrow: { days: 1 },
  "1 week": { weeks: 1 },
  "2 weeks": { weeks: 2 },
  "1 month": { months: 1 },
  "2 months": { months: 2 },
  "3 months": { months: 3 },
  "4 months": { months: 4 },
  "6 months": { months: 6 },
};

const ServiceFollowUpForm: FC<ServiceFollowUpProps> = ({ values, setValues, setFieldValue }) => {
  const { followUpNeeded, outreachTimeline, outreachDate } = values.serviceFollowUp;

  useEffect(() => {
    if (isNil(outreachTimeline) && followUpNeeded) {
      setValues((values) => {
        const { serviceFollowUp } = values;
        serviceFollowUp.outreachTimeline = "Tomorrow";
        serviceFollowUp.outreachDate = DateTime.utc().plus(outReachTimelineOptions["Tomorrow"]).startOf("day").toISO();
        return { ...values, serviceFollowUp };
      });
    }
  }, [followUpNeeded, outreachTimeline, setValues]);

  const onChange = useCallback(
    (timeline) => {
      const outreachTimeline = timeline;
      const outreachDate = DateTime.utc().plus(outReachTimelineOptions[timeline]).startOf("day").toISO();

      setFieldValue("serviceFollowUp.outreachTimeline", outreachTimeline);
      setFieldValue("serviceFollowUp.outreachDate", outreachDate);
    },
    [setFieldValue]
  );

  return (
    <div className="grid gap-4">
      <CheckboxField name={"serviceFollowUp.followUpNeeded"} label={"Alert sales about future service needs"} />

      {followUpNeeded && outreachTimeline && (
        <>
          <div className="grid gap-1">
            <Select
              label={"Outreach Timeline"}
              options={Object.keys(outReachTimelineOptions)}
              name={"serviceFollowUp.outreachTimeline"}
              fullWidth={true}
              postOnChange={onChange}
            />
            <Typography>
              Follow up on {DateTime.fromISO(outreachDate, { zone: "utc" }).toLocaleString(DateTime.DATE_MED)}
            </Typography>
          </div>

          <TextAreaField name={"serviceFollowUp.outreachNotes"} label={"Notes to sales team"} />
        </>
      )}
    </div>
  );
};

export default ServiceFollowUpForm;
