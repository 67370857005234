import * as React from "react";
import { useField } from "formik";
import { ListItem } from "@material-ui/core";
import { TextField } from "../Forms/fields/TextField";
import { includes } from "ramda";
import { ThumbUp, ThumbDown, ThumbsUpDown, Error, ErrorOutline } from "@material-ui/icons";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { red, green, yellow, grey } from "@material-ui/core/colors";
import { partConditionOptions, dashboardLightsOptions } from "../Forms/fields/PartConditionOptions";

const useStyles = makeStyles({
  root: {
    marginBottom: 15,
    border: "2px solid rgb(212, 212, 212)",
    borderRadius: 20,
  },
  up: {
    backgroundColor: green[500],
    "&:focus": {
      backgroundColor: green[500],
    },
    "&:hover": {
      backgroundColor: green[500],
    },
  },
  down: {
    backgroundColor: red[500],
    "&:focus": {
      backgroundColor: red[500],
    },
    "&:hover": {
      backgroundColor: red[500],
    },
  },
  upDown: {
    backgroundColor: yellow[500],
    "&:focus": {
      backgroundColor: yellow[500],
    },
    "&:hover": {
      backgroundColor: yellow[500],
    },
  },
  na: {
    backgroundColor: grey[500],
    "&:focus": {
      backgroundColor: grey[500],
    },
    "&:hover": {
      backgroundColor: grey[500],
    },
  },
  addititionalInfo: {
    paddingTop: 1,
  },
});

const needInfoConditions = [
  "Yellow (Has wear, not in need of immediate repair)",
  "Red (Needs immediate repair)",
  "Warning lights on",
];

export const PartConditionListItem: React.FC<{
  name: string;
  label: string;
  setOptionName: (name: string) => void;
}> = ({ name, label, setOptionName }) => {
  const classes = useStyles();
  const [field, ,] = useField(name);
  const { partCondition } = field.value;
  const setColor = (condition) => {
    switch (condition) {
      case partConditionOptions[0]:
        return classes.up;
      case partConditionOptions[1]:
        return classes.upDown;
      case partConditionOptions[2]:
        return classes.down;
      case partConditionOptions[3]:
        return classes.na;
      case dashboardLightsOptions[0]:
        return classes.up;
      case dashboardLightsOptions[1]:
        return classes.down;
      default:
        return "";
    }
  };
  return (
    <>
      <ListItem
        className={`${classes.root} ${setColor(partCondition)}`}
        divider={false}
        button
        onClick={() => setOptionName(name)}
      >
        <ListItemText primary={label} />
        <ListItemSecondaryAction>
          <SecondaryAction partCondition={partCondition} />
        </ListItemSecondaryAction>
      </ListItem>
      {includes(partCondition, needInfoConditions) && (
        <ListItem className={classes.addititionalInfo}>
          <TextField label={"Additional Notes"} name={`${name}.additionalInfo`} />
        </ListItem>
      )}
    </>
  );
};

const SecondaryAction = ({ partCondition }) => {
  switch (partCondition) {
    case partConditionOptions[0]:
      return <ThumbUp />;
    case partConditionOptions[1]:
      return <ThumbsUpDown />;
    case partConditionOptions[2]:
      return <ThumbDown />;
    case partConditionOptions[3]:
      return <b>N/A</b>;
    case dashboardLightsOptions[0]:
      return <ErrorOutline />;
    case dashboardLightsOptions[1]:
      return <Error />;
    default:
      return null;
  }
};
