import gql from "graphql-tag";

export const jobListFields = `
            id
            jobName
            market
            type
            serviceLocation
            status
            jobNumber
            createdAt
            appointment {
                technician {
                    firstName
                    lastName
                    id
                }
                startDate
                endDate
            }
`;

export const GET_JOBS = gql`query getJobs($cursor: String, $limit: Int, $filter: JSON) {
    getJobs(cursor: $cursor, limit: $limit, filter: $filter) {
        edges {
            ${jobListFields}
        }
        pageInfo {
            prevCursor
            hasNextPage
            numRecords
        }
    }
}`;
