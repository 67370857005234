import * as React from "react";
import { Button } from "@material-ui/core";
import { DeleteOutlined } from "@material-ui/icons";
import { EJIItemFormValue, EJIServiceFormValue } from "../JobDetailsForm";

type DeleteItemButtonProps = {
  editedService: EJIServiceFormValue;
  setEditedServiceAndRecalculatePrice: any;
  itemToDelete: EJIItemFormValue;
};

export const DeleteItemButton = ({
  editedService,
  setEditedServiceAndRecalculatePrice,
  itemToDelete,
}: DeleteItemButtonProps) => {
  return (
    <Button
      variant="outlined"
      startIcon={<DeleteOutlined />}
      onClick={() => {
        setEditedServiceAndRecalculatePrice({
          ...editedService,
          // Compare references instead of IDs since newly-added products don't have IDs yet.
          items: editedService?.items ? [...editedService.items.filter((item) => item !== itemToDelete)] : [],
        });
      }}
    >
      Delete
    </Button>
  );
};
