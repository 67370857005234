import * as React from "react";
import { Job } from "../../generated/nest-graphql";
import { LinkListItem } from "../ListItems/LinkListItem";
import { pathOr } from "ramda";
import { format } from "date-fns";
import { DATE_FORMAT } from "../../lib/constants";

export const JobHistoryListItem: React.FC<{ job: Job; divider: boolean }> = ({ job, divider }) => {
  const { id, jobName, jobNumber, type } = job;
  const startDate = pathOr("", ["appointment", "startDate"], job);
  const dateToDisplay = startDate ? format(new Date(startDate), DATE_FORMAT) : "";

  return (
    <LinkListItem
      to={`/jobs/${id}`}
      divider={divider}
      title={`${dateToDisplay} ${jobNumber}: ${jobName}`}
      secondary={type}
    />
  );
};
