import * as React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { AppBar } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Layout } from "../components/Layout";
import { useMutation, useQuery } from "@apollo/client";
import { TECHNICIAN_GET_ME } from "../graphql/queries/technicianGetMe";
import {
  Mutation,
  MutationUpdateTechnicianArgs,
  Query,
} from "../generated/nest-graphql";
import { keys } from "ramda";
import {
  PreferencesForm,
  PreferencesFormValues,
} from "../components/Forms/PreferencesForm";
import { Lens } from "monocle-ts";
import { UPDATE_TECHNICIAN } from "../graphql/mutations/updateTechnician";
import { cleanObject, objectDiff } from "../lib/functions";
import { useDispatch } from "../contexts/snackbar-context";
import { showSuccessAlert } from "../actions";

const PreferencesAppBar = () => {
  const { goBack } = useHistory();
  return (
    <AppBar position={"sticky"}>
      <Toolbar>
        <IconButton onClick={goBack}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant="h6">Edit Your Preferences</Typography>
        <Box />
      </Toolbar>
    </AppBar>
  );
};
const PreferencesPage: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const [updateTechnician] = useMutation<
    Mutation,
    MutationUpdateTechnicianArgs
  >(UPDATE_TECHNICIAN);
  const { data } = useQuery<Query>(TECHNICIAN_GET_ME);
  if (!data) return null;
  const technicianId = Lens.fromPath<Query>()(["technicianGetMe", "id"]).get(
    data
  );
  const initialValues = Lens.fromPath<Query>()([
    "technicianGetMe",
    "preferences",
  ]).get(data);
  const onSubmit = async (values: PreferencesFormValues) => {
    const diff = objectDiff(values, initialValues);
    const preferencesSpec = {
      preferences: diff,
    };
    const updates = cleanObject(preferencesSpec);
    if (keys(updates).length) {
      await updateTechnician({
        variables: {
          id: technicianId,
          updateTechnicianInput: updates,
        },
      });
    }
    showSuccessAlert(dispatch, "Success");
  };

  return (
    <Layout appBar={<PreferencesAppBar />}>
      <PreferencesForm initialValues={initialValues} onSubmit={onSubmit} />
    </Layout>
  );
};

export default PreferencesPage;
