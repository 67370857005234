import * as React from "react";
import { ListItemWithSubList } from "./ListItemWithSubList";
import { FilesDialog } from "../Files/FilesDialog";
import { useToggle } from "../../hooks/useToggle";

export const FilesListItem: React.FC<{
  contactId: string;
  numFiles: number;
  divider?: boolean;
}> = ({ numFiles, contactId, divider = true }) => {
  const [isOpen, , toggleDialog] = useToggle();
  return (
    <>
      <ListItemWithSubList
        divider={divider}
        title={"Files"}
        secondaries={[`Number of Files Uploaded: ${numFiles}`]}
        onClick={toggleDialog}
        button={true}
      />
      <FilesDialog title={"Files"} open={isOpen} onClose={toggleDialog} contactId={contactId} />
    </>
  );
};
