import * as React from "react";
import Box from "@material-ui/core/Box/Box";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { BottomNav } from "../BottomNav";

const useStyles = makeStyles({
  root: {
    height: "100vh",
  },
});

export const CalendarViewLayout: React.FC<{}> = ({ children }) => {
  const classes = useStyles();
  return (
    <Box className={classNames(classes.root, "flex flex-1 flex-col")}>
      <div className="flex-grow">{children}</div>
      <div className="sticky bottom-0 z-10">
        <BottomNav />
      </div>
    </Box>
  );
};
