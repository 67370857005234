import * as React from "react";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import { StyledHR } from "./StyledHR";
import { BasicTextField } from "./BasicTextField";
import { EJIServiceFormValue } from "../JobDetailsForm";

export const FeeItemSection: React.FC<{
  editedService: EJIServiceFormValue;
  setEditedServiceAndRecalculatePrice: any;
  itemIdx: number;
}> = ({ editedService, setEditedServiceAndRecalculatePrice, itemIdx }) => {
  return (
    <>
      <div className={"flex flex-row justify-between"}>
        <div className="text-lg font-bold">Fee</div>
      </div>
      {editedService?.items[itemIdx]?.type !== null && (
        <div>
          <InputLabel shrink className="mb-1">
            Part Type
          </InputLabel>
          <Typography>{editedService?.items[itemIdx]?.type}</Typography>
        </div>
      )}
      <BasicTextField
        value={editedService.items?.[itemIdx].customerPrice}
        label={"Fee Amount"}
        inputMode="decimal"
        inputType="currency"
        onChange={(e) => {
          const tempItems = [...editedService.items];
          tempItems[itemIdx].customerPrice = e.target.value;
          setEditedServiceAndRecalculatePrice({
            ...editedService,
            items: [...tempItems],
          });
        }}
      />
      {editedService?.items[itemIdx]?.notes !== null && editedService?.items[itemIdx]?.notes?.length > 0 && (
        <div>
          <InputLabel shrink className="mb-1">
            Private Notes
          </InputLabel>
          <Typography>{editedService?.items[itemIdx]?.notes}</Typography>
        </div>
      )}
      <StyledHR />
    </>
  );
};
